/* eslint-disable no-unused-vars */
import axios from 'axios';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { errorHandler } from 'common/utils/response.handler';
import { addNotification, clearValidationError } from 'common/redux/common.actions';

import {
  CLONER_GET_LIST_API,
  CLONER_CREATE_JOB_API,
  CLONER_DELETE_JOB_API,
  CLONER_GET_ACTION_LIST_API,
  CLONER_ACTIVATE_JOB_API,
  CLONER_DEACTIVATE_JOB_API,
  CLONER_TRIGGER_JOB_API,
  CLONER_GET_EXECUTION_LIST_API,
  CLONER_JOB_DETAIL_API,
} from 'state/actions/apiFunctions';

export const CLONER_ACTIONS = {
  GET_JOB_INDEX_REQUEST: 'GET_JOB_INDEX_REQUEST',
  CREATE_JOB_REQUEST: 'CREATE_JOB_REQUEST',
  DELETE_JOB_REQUEST: 'DELETE_JOB_REQUEST',
  GET_ACTION_LIST_REQUEST: 'GET_ACTION_LIST_REQUEST',
  GET_JOB_EXECUTION_LIST_REQUEST: 'GET_JOB_EXECUTION_LIST_REQUEST',
  GET_JOB_DETAIL_REQUEST: 'GET_JOB_DETAIL_REQUEST',
  SET_JOB_CREATE_ERROR_DATA: 'SET_JOB_CREATE_ERROR_DATA',
};

export const getIndexSuccess = (data) => ({
  type: CLONER_ACTIONS.GET_JOB_INDEX_REQUEST,
  payload: data,
});

export const getJobListRequest = (data) => (dispatch) => (
  axios.get(CLONER_GET_LIST_API(), {
    params: {
      page: data.page,
      per_page: data.page && data.per_page,
      q: data.q,
    },
  })
    .then((response) => {
      dispatch(getIndexSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const getExecutionListSuccess = (data) => ({
  type: CLONER_ACTIONS.GET_JOB_EXECUTION_LIST_REQUEST,
  payload: data,
});

export const getExecutionListRequest = (data) => (dispatch) => (
  axios.get(CLONER_GET_EXECUTION_LIST_API(data.jobId), {
    params: {
      page: data.page,
      per_page: data.page && data.per_page,
    },
  })
    .then((response) => {
      dispatch(getExecutionListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const getJobDetailSuccess = (data) => ({
  type: CLONER_ACTIONS.GET_JOB_DETAIL_REQUEST,
  payload: data,
});

export const getJobDetailRequest = (jobId) => (dispatch) => (
  axios.get(CLONER_JOB_DETAIL_API(jobId))
    .then((response) => {
      dispatch(getJobDetailSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const createJobSuccess = (data) => ({
  type: CLONER_ACTIONS.CREATE_JOB_REQUEST,
  payload: data,
});

export const setJobFromErrorData = (data) => ({
  type: CLONER_ACTIONS.SET_JOB_CREATE_ERROR_DATA,
  payload: data,
});
export const createJobRequest = (data) => (dispatch) => {
  dispatch(setJobFromErrorData({}));
  return (
    axios.post(CLONER_CREATE_JOB_API(), data)
      .then((response) => {
        dispatch(addNotification(null, 'cloner-job-create-succes'));
        dispatch(createJobSuccess(getResponseData(response)));
      })
      .catch((error) => {
        const errorInputs = error.response.data.errors?.inputs;
        const source = errorInputs?.source?.[0];
        const destination = errorInputs?.destination?.[0];

        if (source || destination) {
          const errors = { source, destination };
          dispatch(setJobFromErrorData(errors));
          dispatch(clearValidationError());
        } else {
          errorHandler(error, dispatch, '', true);
        }
        throw (error);
      })
  );
};

export const deleteJobSuccess = (data) => ({
  type: CLONER_ACTIONS.DELETE_JOB_REQUEST,
  payload: data,
});

export const getActionSuccess = (data) => ({
  type: CLONER_ACTIONS.GET_ACTION_LIST_REQUEST,
  payload: data,
});

export const getActionListRequest = () => (dispatch) => (
  axios.get(CLONER_GET_ACTION_LIST_API())
    .then((response) => {
      dispatch(getActionSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const deleteJobRequest = (jobId) => (dispatch) => (
  axios.delete(CLONER_DELETE_JOB_API(jobId))
    .then(() => {
      dispatch(addNotification(null, 'cloner-job-delete-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'cloner-job-delete-error'));
      throw (error);
    })
);

export const activateJobRequest = (jobId) => (dispatch) => (
  axios.post(CLONER_ACTIVATE_JOB_API(jobId), null)
    .then((response) => {
      dispatch(addNotification(null, 'cloner-job-activate-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'cloner-job-activate-error'));
      throw (error);
    })
);

export const deactivateJobRequest = (jobId) => (dispatch) => (
  axios.post(CLONER_DEACTIVATE_JOB_API(jobId), null)
    .then((response) => {
      dispatch(addNotification(null, 'cloner-job-deactivate-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'cloner-job-deactivate-error'));
      throw (error);
    })
);

export const triggerJobRequest = (jobId) => (dispatch) => (
  axios.post(CLONER_TRIGGER_JOB_API(jobId), null)
    .then((response) => {
      dispatch(addNotification(null, 'cloner-job-trigger-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'cloner-job-trigger-error'));
      throw (error);
    })
);
