import axios from 'axios';
import { getErrorDetails, getResponseData } from 'state/actions/apiHelpers';
import {
  getTrafficSummaryStatsAPI,
  getTrafficStatsAPI,
  getRequestsSummaryStatsAPI,
  getRequestsStatsAPI,
  getBandwidthSummaryStatsAPI,
  getBandwidthStatsAPI,
  getHitMissSummaryStatsAPI,
  getHitMissStatsAPI,

  getVisitorsBrowserSummaryAPI,
  getVisitorsBrowserAPI,
  getVisitorsCountrySummaryAPI,
  getVisitorsCountryAPI,
  getVisitorsDeviceSummaryAPI,
  getVisitorsDeviceAPI,
  getVisitorsOSSummaryAPI,
  getVisitorsOSAPI,
} from 'state/actions/apiFunctions';
import { formatDate4 } from 'common/helpers';
import { addNotification } from 'common/redux/common.actions';

export const STATS_ACTIONS = {
  GET_BANDWIDTH_SUMMARY: 'S_GET_BANDWIDTH_SUMMARY',
  GET_BANDWIDTH_TOTAL: 'S_GET_BANDWIDTH_TOTAL',
  GET_HIT_MISS_SUMMARY: 'S_GET_HIT_MISS_SUMMARY',
  GET_HIT_MISS_TOTAL: 'S_GET_HIT_MISS_TOTAL',
  GET_TRAFFIC_SUMMARY: 'S_GET_TRAFFIC_SUMMARY',
  GET_TRAFFIC_TOTAL: 'S_GET_TRAFFIC_TOTAL',
  GET_REQUEST_SUMMARY: 'S_GET_REQUEST_SUMMARY',
  GET_REQUEST_TOTAL: 'S_GET_REQUEST_TOTAL',

  GET_BROWSER_SUMMARY: 'V_GET_BROWSER_SUMMARY',
  GET_BROWSER_TOTAL: 'V_GET_BROWSER_TOTAL',
  GET_COUNTRY_SUMMARY: 'V_GET_COUNTRY_SUMMARY',
  GET_COUNTRY_TOTAL: 'V_GET_COUNTRY_TOTAL',
  GET_DEVICE_SUMMARY: 'V_GET_DEVICE_SUMMARY',
  GET_DEVICE_TOTAL: 'V_GET_DEVICE_TOTAL',
  GET_OS_SUMMARY: 'V_GET_OS_SUMMARY',
  GET_OS_TOTAL: 'V_GET_OS_TOTAL',
};

/* Bandwidth Tab Requests */

const getBandwidthSummaryStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_BANDWIDTH_SUMMARY,
  payload: data,
});

export const getBandwidthSummaryStats = (data) => (dispatch) => (
  axios.get(getBandwidthSummaryStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getBandwidthSummaryStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getBandwidthStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_BANDWIDTH_TOTAL,
  payload: data,
});

export const getBandwidthStats = (data) => (dispatch) => (
  axios.get(getBandwidthStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getBandwidthStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Hit/Miss Tab Requests */

const getHitMissSummaryStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_HIT_MISS_SUMMARY,
  payload: data,
});

export const getHitMissSummaryStats = (data) => (dispatch) => (
  axios.get(getHitMissSummaryStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getHitMissSummaryStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getHitMissStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_HIT_MISS_TOTAL,
  payload: data,
});

export const getHitMissStats = (data) => (dispatch) => (
  axios.get(getHitMissStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getHitMissStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Requests Tab Requests */

const getRequestsSummaryStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_REQUEST_SUMMARY,
  payload: data,
});

export const getRequestsSummaryStats = (data) => (dispatch) => (
  axios.get(getRequestsSummaryStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getRequestsSummaryStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getRequestsStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_REQUEST_TOTAL,
  payload: data,
});

export const getRequestsStats = (data) => (dispatch) => (
  axios.get(getRequestsStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getRequestsStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Traffic Tab Requests */

const getTrafficSummaryStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_TRAFFIC_SUMMARY,
  payload: data,
});

export const getTrafficSummaryStats = (data) => (dispatch) => (
  axios.get(getTrafficSummaryStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getTrafficSummaryStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getTrafficStatsSuccess = (data) => ({
  type: STATS_ACTIONS.GET_TRAFFIC_TOTAL,
  payload: data,
});

export const getTrafficStats = (data) => (dispatch) => (
  axios.get(getTrafficStatsAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => {
      dispatch(getTrafficStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getVisitorsBrowserSummarySuccess = (data) => ({
  type: STATS_ACTIONS.GET_BROWSER_SUMMARY,
  payload: data,
});

export const getVisitorsBrowserSummary = (data) => (dispatch) => (
  axios.get(getVisitorsBrowserSummaryAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsBrowserSummarySuccess(getResponseData(response))))
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getVisitorsBrowserSuccess = (data) => ({
  type: STATS_ACTIONS.GET_BROWSER_TOTAL,
  payload: data,
});

export const getVisitorsBrowser = (data) => (dispatch) => (
  axios.get(getVisitorsBrowserAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsBrowserSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Countries Tab Requests

const getVisitorsCountrySummarySuccess = (data) => ({
  type: STATS_ACTIONS.GET_COUNTRY_SUMMARY,
  payload: data,
});

export const getVisitorsCountrySummary = (data) => (dispatch) => (
  axios.get(getVisitorsCountrySummaryAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsCountrySummarySuccess(getResponseData(response))))
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getVisitorsCountrySuccess = (data) => ({
  type: STATS_ACTIONS.GET_COUNTRY_TOTAL,
  payload: data,
});

export const getVisitorsCountry = (data) => (dispatch) => (
  axios.get(getVisitorsCountryAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsCountrySuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Devices Tab Requests

const getVisitorsDeviceSummarySuccess = (data) => ({
  type: STATS_ACTIONS.GET_DEVICE_SUMMARY,
  payload: data,
});

export const getVisitorsDeviceSummary = (data) => (dispatch) => (
  axios.get(getVisitorsDeviceSummaryAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsDeviceSummarySuccess(getResponseData(response))))
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getVisitorsDeviceSuccess = (data) => ({
  type: STATS_ACTIONS.GET_DEVICE_TOTAL,
  payload: data,
});

export const getVisitorsDevice = (data) => (dispatch) => (
  axios.get(getVisitorsDeviceAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsDeviceSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Operating Systems Tab Requests

const getVisitorsOSSummarySuccess = (data) => ({
  type: STATS_ACTIONS.GET_OS_SUMMARY,
  payload: data,
});

export const getVisitorsOSSummary = (data) => (dispatch) => (
  axios.get(getVisitorsOSSummaryAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsOSSummarySuccess(getResponseData(response))))
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);

const getVisitorsOSSuccess = (data) => ({
  type: STATS_ACTIONS.GET_OS_TOTAL,
  payload: data,
});

export const getVisitorsOS = (data) => (dispatch) => (
  axios.get(getVisitorsOSAPI(), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      distribution_id: (data && data.distribution !== 'all') ? data.distribution : null,
      zone_id: (data && data.zone !== 'all') ? data.zone : null,
    },
  })
    .then((response) => dispatch(getVisitorsOSSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);
