import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeNotification } from 'common/redux/common.actions';
import VerifyMailSuccess from './VerifyMailSuccess.component';

const mapStateToProps = (state) => ({
  credentials: state?.auth?.credentials,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    removeNotification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMailSuccess);
