import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RouteController from './RouteController.component';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(RouteController);
