import PropTypes from 'prop-types';
import { Toast } from '@blueprintjs/core';

const ToastNotification = ({ className, type, ...props }) => {
  const classes = ['m-notifications-toast-base', className].join(' ');

  const typeProps = {};
  switch (type) {
    case 'success':
      typeProps.intent = 'success';
      typeProps.icon = 'tick';
      break;
    case 'error':
      typeProps.intent = 'danger';
      typeProps.icon = 'warning-sign';
      break;
    case 'info':
      typeProps.intent = 'primary';
      typeProps.icon = 'info-sign';
      break;
    default:
      break;
  }

  return (
    <Toast
      action={props.action}
      className={classes}
      icon={typeProps && typeProps.icon}
      intent={typeProps && typeProps.intent}
      message={props.message}
      onDismiss={props.onDismiss}
    />
  );
};

ToastNotification.propTypes = {
  // Functions
  onDismiss: PropTypes.func,
  // Objects
  action: PropTypes.shape({
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
  className: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
};

ToastNotification.defaultProps = {
  action: undefined,
  className: '',
  message: 'An error has occurred while processing your request',
  onDismiss: undefined,
  type: 'none',
};

export default ToastNotification;
