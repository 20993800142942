import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'crispy-ui';
import Notification from 'components/notifications/base/Notification';
import NotificationContent from 'components/notifications/base/NotificationContent';
import NotificationHead from 'components/notifications/base/NotificationHead';
import NotificationIcon from 'components/notifications/base/NotificationIcon';
import NotificationButton from 'components/notifications/base/NotificationButton';

class RegisterSuccess extends Component {
  state = {
    isDialogOpen: true,
  }

  redirectToLoginPage = () => {
    this.props.removeNotification(this.props.id);
    this.props.history.push('/login');
  }

  render() {
    return (
      <Dialog
        className="m-register-success-dialog"
        isOpen={this.state.isDialogOpen}
      >
        <Notification>
          <NotificationIcon icon="check_circle" intent="success" />
          <NotificationHead>Verify link is sent!</NotificationHead>
          <NotificationContent>
            A link for verifying your account is sent to your email address. If it doesn’t appear within a few minutes, contact support.
          </NotificationContent>
          <NotificationButton>
            <Button className="btn--success m-notification-button" onClick={this.redirectToLoginPage} text="Return to sign in" />
          </NotificationButton>
        </Notification>
      </Dialog>
    );
  }
}

RegisterSuccess.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
};

RegisterSuccess.defaultProps = {
};

export default withRouter(RegisterSuccess);
