/* eslint-disable no-useless-escape */
import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';

const crypto = require('crypto');
const { parseString } = require('xml2js');

export const getObjectName = (key, isDir = false) => {
  if (isDir) {
    const name = key.split('/');
    return `${name[name.length - 2]}/`;
  }

  return key.split('/').pop();
};

export const getFileID = (file) => file && file.id;

export const getFilePrefix = (file, folderLevel) => file.Key[0].split('/').splice(0, folderLevel + 1).join('/');

export const getFileName = (file) => {
  if (file) {
    return file.name;
    // const paths = file.Key[0].split(/[/-]/g);
    // return paths[paths.length - 1];
  }

  return 'Unknow';
};

export const getObjectKey = (file) => file && file.Key[0];

export const getFileType = (file) => (file.type === 'DIRECTORY' ? 'folder' : 'file');

export const getFileSize = (file) => file && file;

export const getLastModified = (file) => file && file[0];

export const getStorageClass = (file) => file && file[0];

export const xmlToJson = (xmlString) => {
  const bucketJSON = {};
  parseString(xmlString, (err, result) => {
    _.assignIn(bucketJSON, result);
  });

  return bucketJSON;
};

/**
 *
 * @param {*} file
 * @returns
 */
export const readFileAsync = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    resolve(new Uint8Array(reader.result));
  };
  reader.onerror = reject;
});

/**
 *
 * @param {*} bucket
 * @param {*} key
 * @returns
 */
export const getDirectoryPrefixFromResult = (detail, key) => `${detail.currentPrefix}${key}`;

/**
 *
 * @param {*} content
 * @returns
 */
export const hashContent = (content) => crypto.createHash('sha256').update(content).digest('hex');

/**
 *
 * @param {*} locations
 * @returns
 */
export const getMainLocationInfo = (bucketInfo) => bucketInfo.locations[0];

/**
 *
 * @param {*} bucketInfo
 * @returns
 */
export const getServiceAPI = (bucketInfo) => `${bucketInfo.endpoint}${bucketInfo.name}`;

/**
 *
 * @param {*} location
 * @returns
 */
export const getPrefixFromQuery = (location) => new URLSearchParams(location.search).get('prefix') ?? '';

/**
 *
 * @param {*} prefix
 * @returns
 */
export const clearUnwiseCharacter = (prefix) => prefix.replace(/^\//, '').replace(/\/$|$/, '/').replace(/[/]+/g, '/');

/**
 *
 * @param {*} prefix
 * @returns
 */
export const getTableColumn = (item, value) => (item.type === 'DIRECTORY' ? '-' : value);

/**
 *
 * @returns
 */
export const generateUniqueID = () => uuidv4();

export const VISIBILITY_PRIVATE = 'private';
export const VISIBILITY_PUBLIC = 'public-read';

export const getVisiblityLabel = (visibility) => (visibility === 'private' ? 'Private' : 'Public');

export const VISIBILITY_OPTIONS = [{
  value: 'public-read',
  label: 'Public',
},
{
  value: 'private',
  label: 'Private',
}];

export const getOneMonthAgoDate = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
};

export const encodeFileName = (file) => encodeURIComponent(file)
  .replace(/\-/g, '%2D')
  .replace(/\_/g, '%5F')
  // .replace(/\./g, '%2E')
  .replace(/\!/g, '%21')
  .replace(/\~/g, '%7E')
  .replace(/\*/g, '%2A')
  .replace(/\'/g, '%27')
  .replace(/\(/g, '%28')
  .replace(/\)/g, '%29');

export const escapeAttribute = (value) => value.replace(/&/g, '&amp;')
  .replace(/'/g, '&apos;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;');

export const escapeElement = (value) => value.replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/\r/g, '&#x0D;')
  .replace(/\n/g, '&#x0A;')
  .replace(/\u0085/g, '&#x85;')
  .replace(/\u2028/, '&#x2028;');

export const policyGenerator = (sId, policies, caseStatement) => {
  let Statement = [];
  let newpolicies = [];

  if (policies && Array.isArray(policies.Statement)) {
    newpolicies = policies.Statement.filter((item) => item.Sid !== sId);
    if (caseStatement) {
      newpolicies.push(caseStatement);
    }
  } else if (caseStatement) {
    newpolicies = [caseStatement];
  }

  Statement = newpolicies;

  if (Statement.length === 0) {
    return null;
  }

  return {
    Version: '2012-10-17',
    Statement,
  };
};

const encodings = {
  '\+': '%2B',
  '\!': '%21',
  '\"': '%22',
  '\#': '%23',
  $: '%24',
  '\&': '%26',
  '\'': '%27',
  '\(': '%28',
  '\)': '%29',
  '\*': '%2A',
  '\,': '%2C',
  '\:': '%3A',
  '\;': '%3B',
  '\=': '%3D',
  '\?': '%3F',
  '\@': '%40',
};

export function encodeS3URI(filename) {
  return encodeURI(filename) // Do the standard url encoding
    .replace(
      /(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/img,
      (match) => encodings[match],
    );
}

/**
 *
 * @param {*} str
 * @returns
 */
export function extendedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}

/**
 *
 * @param {*} path
 * @returns
 */
export const generateURI = (path) => path.split('/')
  ?.map((segment) => extendedEncodeURIComponent(segment))
  ?.join('/');

/**
 *
 * @param {*} bucketInfo
 * @returns
 */
export const prepareServiceUrl = (bucketInfo, location, key = '', type) => {
  const encodedKey = encodeS3URI(key);
  const ecodedLocation = encodeS3URI(getPrefixFromQuery(location));

  if (type === 'sourcePath') {
    return `${bucketInfo.name}/${ecodedLocation}${encodedKey}`;
  }

  return `${bucketInfo.endpoint}${bucketInfo.name}/${ecodedLocation}${encodedKey}`;
};
