import { CLONER_ACTIONS } from './Cloner.actions';

export default (state = null, action) => {
  switch (action.type) {
    case CLONER_ACTIONS.GET_JOB_INDEX_REQUEST:
      return {
        ...state,
        jobList: action.payload,
      };
    case CLONER_ACTIONS.GET_JOB_EXECUTION_LIST_REQUEST: {
      return {
        ...state,
        executionList: action.payload,
      };
    }
    case CLONER_ACTIONS.GET_JOB_DETAIL_REQUEST: {
      return {
        ...state,
        jobDetailData: action.payload.data,
      };
    }
    case CLONER_ACTIONS.CREATE_JOB_REQUEST:
      return {
        ...state,
        createtedJobData: action.payload.data,
      };
    case CLONER_ACTIONS.DELETE_JOB_REQUEST:
      return {
        ...state,
        deletedJob: action.payload.data,
      };
    case CLONER_ACTIONS.GET_ACTION_LIST_REQUEST:
      return {
        ...state,
        actionListData: action.payload.data,
      };
    case CLONER_ACTIONS.SET_JOB_CREATE_ERROR_DATA: {
      return {
        ...state,
        formCommonErrors: action.payload,
      };
    }
    default:
      return state;
  }
};
