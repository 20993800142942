import { DISTRIBUTIONS_ACTIONS } from './Distributions.actions';

export default (state = null, action) => {
  switch (action.type) {
    case DISTRIBUTIONS_ACTIONS.CLEAR_PAGE_RULE_DETAILS:
      return {
        ...state,
        pageRuleDetails: null,
      };
    case DISTRIBUTIONS_ACTIONS.CLEAR_REDUX:
      return null;
    case DISTRIBUTIONS_ACTIONS.GET_CACHE_INVALIDATION_LIST:
      return {
        ...state,
        cacheInvalidationList: action.payload,
      };
    case DISTRIBUTIONS_ACTIONS.GET_CACHE_PREFETCHING_LIST:
      return {
        ...state,
        cachePrefetchingList: action.payload,
      };
    case DISTRIBUTIONS_ACTIONS.GET_PREFETCH_HEADERS_LIST:
      return {
        ...state,
        prefetchHeadersList: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_CNAME_LIST:
      return {
        ...state,
        cNAMEList: action.payload.data,
        totalPageNumberForCNAMEList: action.payload.meta && action.payload.meta.last_page,
      };
    case DISTRIBUTIONS_ACTIONS.CLEAR_CONFIGURATION:
      return {
        ...state,
        configuration: null,
      };
    case DISTRIBUTIONS_ACTIONS.GET_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_DETAILS:
      return {
        ...state,
        distributionDetails: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_LIST:
      return {
        ...state,
        distributionList: action.payload.data,
        totalPageNumberForDistributionList: action.payload.meta && action.payload.meta.last_page,
      };
    case DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_TYPE_DETAILS:
      return {
        ...state,
        distributionTypeDetails: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_TYPES:
      return {
        ...state,
        distributionTypes: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_AVAILABLE_ERROR_CODE_LIST:
      return {
        ...state,
        availableErrorCodeList: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_ERROR_CODE_LIST:
      return {
        ...state,
        errorCodeList: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_GENERAL_SETTINGS:
      return {
        ...state,
        distributionDetails: {
          ...state.distributionDetails,
          ...action.payload.data,
        },
      };
    case DISTRIBUTIONS_ACTIONS.GET_ORIGIN_DETAILS:
      return {
        ...state,
        originDetails: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_ORIGIN_LIST:
      return {
        ...state,
        originList: action.payload.data,
        totalPageNumberForOriginList: action.payload.meta && action.payload.meta.last_page,
      };
    case DISTRIBUTIONS_ACTIONS.GET_ORIGIN_SERVERS:
      return {
        ...state,
        originDetails: {
          ...state.originDetails,
          servers: [...action.payload.data],
        },
      };
    case DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_DETAILS:
      return {
        ...state,
        pageRuleDetails: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_OPERATORS:
      return {
        ...state,
        pageRuleOperators: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_LIST:
      return {
        ...state,
        pageRuleList: action.payload.data,
        totalPageNumberForPageRuleList: action.payload.meta && action.payload.meta.last_page,
        totalPageRulesForPageRuleList: action.payload.meta && action.payload.meta.total,
      };
    case DISTRIBUTIONS_ACTIONS.GET_PRICING_ZONES:
      return {
        ...state,
        pricingZones: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_RESTRICTIONS:
      return {
        ...state,
        restrictions: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_WAF_POLICY:
      return {
        ...state,
        wafPolicy: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.UPDATE_WAF_POLICY:
      return {
        ...state,
        wafPolicy: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_LIST:
      return {
        ...state,
        redirectionList: action.payload.data,
        redirectionTotalPage: action.payload?.meta.last_page,
        redirectionTotalItem: action.payload?.meta.total,
      };
    case DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_FLAG_LIST:
      return {
        ...state,
        redirectionFlagList: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_DETAILS:
      return {
        ...state,
        redirectionDetails: action.payload.data,
      };
    case DISTRIBUTIONS_ACTIONS.UPDATE_REDIRECTION:
      return {
        ...state,
        redirectionDetails: action.payload.data,
      };
    default:
      return state;
  }
};
