import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import Notification from 'components/notifications/base/Notification';
import NotificationIcon from 'components/notifications/base/NotificationIcon';
import NotificationErrorDetail from 'components/notifications/base/NotificationErrorDetail';
import ToastNotification from 'components/notifications/baseToast/ToastNotification.component';

class ErrorNotification extends Component {
  state = {
    isDialogOpen: true,
  }

  removeNotification = () => {
    this.props.removeNotification(this.props.notification.id);
  }

  render() {
    const { ...props } = this.props;
    if (props.forceDialog
      || (props.notification
        && props.notification.data
        && props.notification.data.inputs
        && Object.values(props.notification.data.inputs).flat().length > 1
      )) {
      return (
        <Dialog
          className="m-error-notification-dialog"
          isOpen={this.state.isDialogOpen}
          onClose={this.removeNotification}
        >
          <Notification>
            <NotificationIcon icon="error" intent="error" />
            <div className="m-error-notification-dialog-title">An error has occurred!</div>
            {
              props.notification && props.notification.data
                ? (
                  <>
                    <div className="m-error-notification-dialog-text">An error has occurred while processing your request. details are as following:</div>
                    <NotificationErrorDetail errors={props.notification && props.notification.data} />
                  </>
                )
                : null
            }
          </Notification>
        </Dialog>
      );
    }
    return (
      <ToastNotification
        type="error"
        message={
          props.notification
          && props.notification.data
          && (
            (props.notification.data.inputs && Object.values(props.notification.data.inputs).flat()[0])
            || props.notification.data.message
            || 'An error occurred with your request!'
          )
        }
        onDismiss={this.removeNotification}
      />
    );
  }
}

ErrorNotification.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  forceDialog: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notification: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

ErrorNotification.defaultProps = {
  forceDialog: false,
};

export default withRouter(ErrorNotification);
