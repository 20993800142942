import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'crispy-ui';
import Notification from 'components/notifications/base/Notification';
import NotificationContent from 'components/notifications/base/NotificationContent';
import NotificationHead from 'components/notifications/base/NotificationHead';
import NotificationIcon from 'components/notifications/base/NotificationIcon';
import NotificationButton from 'components/notifications/base/NotificationButton';
import NotificationErrorDetail from 'components/notifications/base/NotificationErrorDetail';

class RegisterError extends Component {
  state = {
    isDialogOpen: true,
  }

  redirectToLoginPage = () => {
    this.props.removeNotification(this.props.notification.id);
    this.props.history.push('/login');
  }

  tryAgain = () => {
    this.props.removeNotification(this.props.notification.id);
  }

  render() {
    const { ...props } = this.props;
    return (
      <Dialog
        className="m-register-error-dialog"
        isOpen={this.state.isDialogOpen}
      >
        <Notification>
          <NotificationIcon icon="error" intent="error" />
          <NotificationHead>An error has occurred!</NotificationHead>
          <NotificationContent>An error has occurred while processing your request. details are as following:</NotificationContent>
          <NotificationErrorDetail errors={props.notification && props.notification.data} />
          <NotificationButton>
            <Button className="m-notification-button-2x" onClick={this.redirectToLoginPage} text="Return to sign in" />
            <Button className="btn--primary m-notification-button-2x" onClick={this.tryAgain} text="Try again" />
          </NotificationButton>
        </Notification>
      </Dialog>
    );
  }
}

RegisterError.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notification: PropTypes.shape({
    id: PropTypes.number,
  }),
};

RegisterError.defaultProps = {
  notification: null,
};

export default withRouter(RegisterError);
