import { addNotification, addValidationError } from 'common/redux/common.actions';
import { getErrorDetails } from 'state/actions/apiHelpers';

const VALIDATION_ERROR_CODE = 422;

const formatValidationError = (errors, isGroupError) => Object.keys(errors).reduce((prevData, field) => {
  let splittedKey = field.split('.');
  let newItem;

  if (!isGroupError) {
    const isOne = splittedKey.length === 1;
    const isCondition = splittedKey[0] === 'conditions';

    // eslint-disable-next-line no-nested-ternary
    splittedKey = isOne ? splittedKey[0] : isCondition ? field : splittedKey[1];
    newItem = { [splittedKey]: errors[field][0] };
  } else if (splittedKey.length !== 1) {
    const errorMessage = errors?.[field][0];
    const newErrorItem = { [splittedKey[1]]: errorMessage };
    const mergedErrorItems = { ...prevData[splittedKey[0]], ...newErrorItem };
    newItem = { [splittedKey[0]]: mergedErrorItems };
  } else {
    newItem = { [splittedKey]: errors[field][0] };
  }
  return { ...prevData, ...newItem };
}, {});

/**
 *
 * @param {*} error
 * @param {*} dispatch
 * @param {*} notificationKey
 * @returns
 */
const errorHandler = (error, dispatch, notificationKey = '', isGroupError = false) => {
  const { response } = error;
  if (response.status === VALIDATION_ERROR_CODE && response.data.errors.inputs) {
    dispatch(addValidationError(formatValidationError(response.data.errors.inputs, isGroupError)));
    return response.status;
  }

  dispatch(addNotification(getErrorDetails(error), notificationKey));
  return response.status;
};

const handleError = () => {

};

const successHandler = (response) => response.status;

export { errorHandler, successHandler, handleError };
