import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import * as md from 'react-icons-kit/md';
import { appendClasses } from 'common/helpers';

const NotificationIcon = (props) => (
  <div className={appendClasses('m-notification-icon-component', props.classes)}>
    <Icon className={props.intent ? `--${props.intent}` : ''} icon={md[`ic_${props.icon}`]} size={48} />
  </div>
);

NotificationIcon.propTypes = {
  classes: PropTypes.string,
  icon: PropTypes.string,
  intent: PropTypes.string,
};

NotificationIcon.defaultProps = {
  classes: null,
  icon: null,
  intent: null,
};

export default NotificationIcon;
