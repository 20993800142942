import axios from 'axios';
import {
  getWafListsAPI, deleteWafListAPI, getWafListDetailsAPI, updateWafListAPI,
  createWafListAPI, getWafTypesAPI, getWafListContentAPI, deleteWafListContentAPI,
  updateWafListContentAPI, getWAFPolicyDetailsAPI, createWAFPolicyAPI, updateWAFPolicyAPI,
  getWAFPolicyListAPI, deleteWAFPolicyAPI, getWAFPolicyDistributionsAPI, getManagedRulesAPI,
  updateManagedRulesAPI, getCustomRulesAPI, getCustomRuleDetailsAPI, createCustomRuleAPI,
  updateCustomRuleAPI, deleteCustomRuleAPI, getResourcesAPI, toggleCustomRuleAPI,
  reorderCustomRuleAPI, dragCustomRuleAPI, duplicateWAFPolicyAPI,
} from 'state/actions/apiFunctions';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';
import { errorHandler } from 'common/utils/response.handler';

export const WAF_ACTIONS = {
  CLEAR_POLICY_DETAILS: 'WAF_CLEAR_POLICY_DETAILS',
  CLEAR_CUSTOM_RULE_DETAILS: 'WAF_CLEAR_CUSTOM_RULE_DETAILS',
  CREATE_POLICY: 'WAF_CREATE_POLICY',
  GET_LISTS: 'WAF_GET_LISTS',
  GET_LIST_TYPES: 'WAF_GET_LIST_TYPES',
  GET_LIST_DETAILS: 'WAF_GET_LIST_DETAILS',
  GET_LIST_CONTENTS: 'WAF_GET_LIST_CONTENTS',
  GET_POLICY_DETAILS: 'WAF_GET_POLICY_DETAILS',
  GET_POLICY_DISTRIBUTIONS: 'WAF_GET_POLICY_DISTRIBUTIONS',
  GET_POLICY_LIST: 'WAF_GET_POLICY_LIST',
  GET_MANAGED_RULES: 'WAF_GET_MANAGED_RULES',
  GET_CUSTOM_RULES: 'WAF_GET_CUSTOM_RULES',
  GET_CUSTOM_RULE_DETAILS: 'WAF_GET_CUSTOM_RULE_DETAILS',
  GET_CUSTOM_RULE_RESOURCES: 'WAF_GET_CUSTOM_RULE_RESOURCES',
};

const getWafListsSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_LISTS,
  payload,
});

export const getWafLists = (data) => (dispatch) => (
  axios.get(getWafListsAPI(), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
      term: data.query,
    },

  })
    .then((response) => dispatch(getWafListsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const deleteWafList = (data) => (dispatch) => (
  axios.delete(deleteWafListAPI(data.listID), {

  })
    .then(() => dispatch(addNotification(null, 'delete-waf-list-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-waf-list-error'));
      throw (error);
    })
);

const getWafListDetailsSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_LIST_DETAILS,
  payload,
});

export const getWafListDetails = (data) => (dispatch) => (
  axios.get(getWafListDetailsAPI(data.id), {

  })
    .then((response) => dispatch(getWafListDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const createWafList = (data) => (dispatch) => (
  axios.post(createWafListAPI(), {
    name: data.name,
    type_id: data.type,
  })
    .then((response) => {
      dispatch(addNotification(null, 'create-waf-list-success'));
      return getResponseData(response)?.data?.id;
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'create-waf-list-error');
      throw (error);
    })
);

export const updateWafList = (data) => (dispatch) => (
  axios.patch(updateWafListAPI(data.listID), {
    name: data.name,
    type_id: data.type,
  })
    .then(() => dispatch(addNotification(null, 'update-waf-list-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-waf-list-error'));
      throw (error);
    })
);

const getWafTypesSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_LIST_TYPES,
  payload,
});

export const getWafTypes = () => (dispatch) => (
  axios.get(getWafTypesAPI())
    .then((response) => dispatch(getWafTypesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getWafListContentSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_LIST_CONTENTS,
  payload,
});

export const getWafListContent = (data) => (dispatch) => (
  axios.get(getWafListContentAPI(data.listID), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
      term: data.query,
    },
  })
    .then((response) => dispatch(getWafListContentSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const deleteWafListContent = (data) => (dispatch) => (
  axios.delete(deleteWafListContentAPI(data.listID, data.contentID), {
  })
    .then(() => dispatch(addNotification(null, 'delete-waf-list-content-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-waf-list-content-error'));
      throw (error);
    })
);

export const updateWafListContent = (data) => (dispatch) => (
  axios.post(updateWafListContentAPI(data.listID), {
    contents: data.contents,
  })
    .then((response) => {
      const count = getResponseData(response)?.data?.total_created_data;
      dispatch(addNotification({ count }, 'update-waf-list-content-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-waf-list-content-error'));
      throw (error);
    })
);

const getWafPolicyListSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_POLICY_LIST,
  payload,
});

export const getWafPolicyList = (data) => (dispatch) => (
  axios.get(getWAFPolicyListAPI(), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
      term: data.query,
    },
  })
    .then((response) => dispatch(getWafPolicyListSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const createWafPolicy = (data) => (dispatch) => (
  axios.post(createWAFPolicyAPI(), {
    name: data.name,
  })
    .then((response) => {
      dispatch(addNotification(null, 'create-waf-policy-success'));
      return getResponseData(response)?.data?.id;
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'create-waf-policy-error');
      throw (error);
    })
);

export const clearWafPolicyDetails = () => ({
  type: WAF_ACTIONS.CLEAR_POLICY_DETAILS,
});

const getWafPolicyDetailsSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_POLICY_DETAILS,
  payload,
});

export const getWafPolicyDetails = (data) => (dispatch) => (
  axios.get(getWAFPolicyDetailsAPI(data.id))
    .then((response) => dispatch(getWafPolicyDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const updateWafPolicy = (data) => (dispatch) => (
  axios.patch(updateWAFPolicyAPI(data.id), {
    name: data.name,
  })
    .then((response) => dispatch(getWafPolicyDetailsSuccess(getResponseData(response))))
    .then(() => dispatch(addNotification(null, 'update-waf-policy-success')))
    .catch((error) => {
      errorHandler(error, dispatch, 'update-waf-policy-error');
      throw (error);
    })
);

export const duplicateWafPolicy = (data) => (dispatch) => (
  axios.post(duplicateWAFPolicyAPI(data.id), null)
    .then(() => dispatch(addNotification(null, 'duplicate-waf-policy-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'duplicate-waf-policy-error'));
      throw (error);
    })
);

const getWafPolicyDistributionsSuccess = (payload) => ({
  type: WAF_ACTIONS.GET_POLICY_DISTRIBUTIONS,
  payload,
});

export const getWafPolicyDistributions = (data) => (dispatch) => (
  axios.get(getWAFPolicyDistributionsAPI(data.id), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getWafPolicyDistributionsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const deleteWafPolicy = (data) => (dispatch) => (
  axios.delete(deleteWAFPolicyAPI(data.id), {
  })
    .then(() => dispatch(addNotification(null, 'delete-waf-policy-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-waf-policy-error'));
      throw (error);
    })
);

/* Get Managed Rules */

const getManagedRulesSuccess = (data) => ({
  type: WAF_ACTIONS.GET_MANAGED_RULES,
  payload: data,
});

export const getManagedRules = (data) => (dispatch) => (
  axios.get(getManagedRulesAPI(data.policyID), {
  })
    .then((response) => {
      dispatch(getManagedRulesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Update Managed Rules */

export const updateManagedRules = (data) => (dispatch) => (
  axios.patch(updateManagedRulesAPI(data.policyID, data.ruleID), {
  }, {

  })
    .then(() => dispatch(addNotification(null, 'update-waf-managed-rule-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-waf-managed-rule-error'));
      throw (error);
    })
);

/* Get Custom Rules */

const getCustomRulesSuccess = (data) => ({
  type: WAF_ACTIONS.GET_CUSTOM_RULES,
  payload: data,
});

export const getCustomRules = (data) => (dispatch) => (
  axios.get(getCustomRulesAPI(data.policyID), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
      term: data.query,
    },

  })
    .then((response) => {
      dispatch(getCustomRulesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const clearCustomRuleDetails = () => ({
  type: WAF_ACTIONS.CLEAR_CUSTOM_RULE_DETAILS,
});

/* Get Custom Rule Details */

const getCustomRuleDetailsSuccess = (data) => ({
  type: WAF_ACTIONS.GET_CUSTOM_RULE_DETAILS,
  payload: data,
});

export const getCustomRuleDetails = (data) => (dispatch) => (
  axios.get(getCustomRuleDetailsAPI(data.policyID, data.ruleID), {

  })
    .then((response) => {
      dispatch(getCustomRuleDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Custom Rule Resources */

const getResourcesSuccess = (data) => ({
  type: WAF_ACTIONS.GET_CUSTOM_RULE_RESOURCES,
  payload: data,
});

export const getResources = (data) => (dispatch) => (
  axios.get(getResourcesAPI(data.policyID), {

  })
    .then((response) => {
      dispatch(getResourcesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Update Custom Rules */

export const updateCustomRule = (data) => (dispatch) => (
  axios.patch(updateCustomRuleAPI(data.policyID, data.ruleID), {
    name: data.name,
    action: data.action,
    conditions: data.conditions,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'update-waf-custom-rule-success')))
    .catch((error) => {
      errorHandler(error, dispatch, 'update-waf-custom-rule-error');
      throw (error);
    })
);

/* Delete Custom Rules */

export const deleteCustomRule = (data) => (dispatch) => (
  axios.delete(deleteCustomRuleAPI(data.policyID, data.ruleID), {

  })
    .then(() => dispatch(addNotification(null, 'delete-waf-custom-rule-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-waf-custom-rule-error'));
      throw (error);
    })
);

/* Create Custom Rules */

export const createCustomRule = (data) => (dispatch) => (
  axios.post(createCustomRuleAPI(data.policyID), {
    name: data.name,
    action: data.action,
    is_active: true,
    conditions: data.conditions,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'create-waf-custom-rule-success')))
    .catch((error) => {
      errorHandler(error, dispatch, 'create-waf-custom-rule-error');
      throw (error);
    })
);

/* Toggle Custom Rules */

export const toggleCustomRule = (data) => (dispatch) => (
  axios.patch(toggleCustomRuleAPI(data.policyID, data.ruleID), {
  }, {

  })
    .then(() => dispatch(addNotification(null, 'toggle-waf-custom-rule-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'toggle-waf-custom-rule-error'));
      throw (error);
    })
);

/* Reorder Custom Rules */

export const reorderCustomRule = (data) => (dispatch) => (
  axios.post(reorderCustomRuleAPI(data.policyID, data.ruleID), {
    action: data.action,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'reorder-waf-custom-rule-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'reorder-waf-custom-rule-error'));
      throw (error);
    })
);

export const dragCustomRule = (data) => (dispatch) => (
  axios.post(dragCustomRuleAPI(data.policyID, data.ruleID), {
    index: data.order,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'reorder-waf-custom-rule-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'reorder-waf-custom-rule-error'));
      throw (error);
    })
);
