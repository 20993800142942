import { WAF_ACTIONS } from './Waf.actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case WAF_ACTIONS.GET_LISTS:
      return { ...state, wafLists: action.payload.data, totalListsPages: action.payload.meta?.last_page };
    case WAF_ACTIONS.GET_LIST_CONTENTS:
      return { ...state, wafContents: action.payload.data, totalListContentPages: action.payload.meta?.last_page };
    case WAF_ACTIONS.GET_LIST_TYPES:
      return { ...state, types: action.payload.data };
    case WAF_ACTIONS.GET_LIST_DETAILS:
      return { ...state, listDetails: action.payload.data };
    case WAF_ACTIONS.GET_POLICY_LIST:
      return { ...state, policies: action.payload.data, totalPoliciesPages: action.payload.meta?.last_page };
    case WAF_ACTIONS.GET_POLICY_DETAILS:
      return { ...state, policyDetails: action.payload.data };
    case WAF_ACTIONS.GET_POLICY_DISTRIBUTIONS:
      return { ...state, policyDistributions: action.payload.data };
    case WAF_ACTIONS.CLEAR_POLICY_DETAILS:
      return { ...state, policyDetails: null };
    case WAF_ACTIONS.GET_MANAGED_RULES:
      return { ...state, managedRules: action.payload.data };
    case WAF_ACTIONS.GET_CUSTOM_RULES:
      return {
        ...state,
        customRules: action.payload.data,
        totalCustomRulesPages: action.payload.meta?.last_page,
        totalCustomRules: action.payload.meta?.total,
      };
    case WAF_ACTIONS.GET_CUSTOM_RULE_DETAILS:
      return { ...state, customRuleDetails: action.payload.data };
    case WAF_ACTIONS.CLEAR_CUSTOM_RULE_DETAILS:
      return { ...state, customRuleDetails: null };
    case WAF_ACTIONS.GET_CUSTOM_RULE_RESOURCES:
      return { ...state, customRuleResources: action.payload.data };
    default:
      return state;
  }
};
