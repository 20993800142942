import PropTypes from 'prop-types';
import { appendClasses } from 'common/helpers';

const NotificationButton = (props) => (
  <div className={appendClasses('m-notification-button-component', props.classes)}>
    {props.children}
  </div>
);

NotificationButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

NotificationButton.defaultProps = {
  children: null,
  classes: null,
};

export default NotificationButton;
