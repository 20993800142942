// Polyfill imports
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import ReduxStore from 'state/store/ReduxStore';
import Routes from 'routing/Routes';
import localeEN from 'common/i18n/en.json';
import httpService from 'common/api/httpService';
import './index.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new CaptureConsole({ levels: ['error'] })],
  normalizeDepth: 10,
  release: `MerlinCDN React Client@${process.env.REACT_APP_VERSION}`,
});

const history = createBrowserHistory({
  basename: '',
});

const { store } = ReduxStore;

httpService.setupInterceptors(store, history);

const locale = 'en';

const languages = {
  en: localeEN,
};

const formats = {
  time: {
    '24hour': { hour12: false, hour: 'numeric', minute: 'numeric' },
  },
};

window.addEventListener('error', (e) => {
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});

ReactDOM.render(
  <IntlProvider formats={formats} locale={locale} messages={languages[locale]}>
    <Provider store={store}>
      <Router history={history}>
        <Route component={Routes} />
      </Router>
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);
