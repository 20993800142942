import axios from 'axios';
import { formatDate4 } from 'common/helpers';
import { addNotification } from 'common/redux/common.actions';
import { getProfileActivitiesAPI, getReferralLinkAPI, postReferralLinkAPI } from 'state/actions/apiFunctions';
import { getResponseData } from 'state/actions/apiHelpers';
import { errorHandler } from 'common/utils/response.handler';

export const PROFILE_ACTIONS = {
  GET_ACTIVITIES: 'P_GET_ACTIVITIES',
  GET_REFERRAL_LINK: 'P_GET_REFERRAL_LINK',
  RESET_ACTIVITIES: 'P_RESET_ACTIVITIES',
};

export const clearProfileActivities = () => ({ type: PROFILE_ACTIONS.RESET_ACTIVITIES });

const getProfileActivitiesSuccess = (payload) => ({
  type: PROFILE_ACTIONS.GET_ACTIVITIES,
  payload,
});

export const getProfileActivities = (data) => (dispatch, getState) => (
  axios.get(getProfileActivitiesAPI(), {
    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      next: data.currentPageNumber > 1 ? getState().profile.nextActivity : null,
      workspace_id: data && data.wsID !== 'all' ? data.wsID : null,
    },
  })
    .then((response) => dispatch(getProfileActivitiesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getReferralLinkSuccess = (payload) => ({
  type: PROFILE_ACTIONS.GET_REFERRAL_LINK,
  payload,
});

export const getReferralLink = () => (dispatch) => (
  axios.get(getReferralLinkAPI(), {

  })
    .then((response) => dispatch(getReferralLinkSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const sendReferralLink = (data) => (dispatch) => (
  axios.post(postReferralLinkAPI(), {
    email: data.email,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'send-referral-link-success')))
    .catch((error) => {
      errorHandler(error, dispatch, 'send-referral-link-error');
      throw (error);
    })
);
