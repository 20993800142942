import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  removeNotification,
} from 'common/redux/common.actions';
import ResetPasswordError from './ResetPasswordError.component';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    removeNotification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordError);
