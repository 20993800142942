const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api-staging.merlincdn.com';

/* Auth APIs */

export const loginAPI = () => `${API_BASE_URL}/client/auth/login`;

export const logoutAPI = () => `${API_BASE_URL}/client/auth/logout`;

export const getUserDataAPI = () => `${API_BASE_URL}/client/user/profile`;

export const getRegisterAPI = () => `${API_BASE_URL}/client/auth/register`;

export const getPasswordResetAPI = () => `${API_BASE_URL}/client/auth/reset-password`;

export const getPasswordResetLinkAPI = () => `${API_BASE_URL}/client/auth/forgot-password`;

export const getResendAPI = () => `${API_BASE_URL}/client/auth/resend`;

export const getUpdateEmailAPI = () => `${API_BASE_URL}/client/user/profile`;

export const getVerifyMethodsAPI = () => `${API_BASE_URL}/client/user/phone-number/methods`;

export const getUpdatePhoneNoAPI = () => `${API_BASE_URL}/client/user/phone-number/verification/init`;

export const getVerifyPhoneNoAPI = () => `${API_BASE_URL}/client/user/phone-number/verification/complete`;

export const getUpdateMeAPI = () => `${API_BASE_URL}/client/user/profile`;

export const getUpdatePasswordAPI = () => `${API_BASE_URL}/client/user/profile`;

export const getVerifyMailAPI = () => `${API_BASE_URL}/client/auth/verify`;

export const updateUserAPIAPI = () => `${API_BASE_URL}/client/user/api/`;

export const getPersonalAccessTokenAPI = (id) => `${API_BASE_URL}/client/user/personal-access-tokens/${id ?? ''}`;

export const enableTOTPAPI = () => `${API_BASE_URL}/client/user/security-settings/enable-totp`;

export const disableTOTPAPI = () => `${API_BASE_URL}/client/user/security-settings/disable-totp`;

export const activateTOTPAPI = () => `${API_BASE_URL}/client/user/security-settings/activate-totp`;

export const verifyCodeAPI = () => `${API_BASE_URL}/client/user/security-settings/verify-totp`;

export const getNotificationSettingsAPI = () => `${API_BASE_URL}/client/user/notification-settings`;

export const getUpdateNotificationSettingsAPI = () => `${API_BASE_URL}/client/user/notification-settings`;

/* Distributions APIs */

export const createDistributionAPI = () => `${API_BASE_URL}/client/distributions`;

export const getDistributionListAPI = () => `${API_BASE_URL}/client/distributions`;

export const getDistributionDetailsAPI = () => `${API_BASE_URL}/client/distributions`;

export const deleteDistributionAPI = () => `${API_BASE_URL}/client/distributions`;

export const getTypeListAPI = () => `${API_BASE_URL}/client/types`;

export const getTypeDetailsAPI = () => `${API_BASE_URL}/client/types`;

export const getPricingZoneListAPI = () => `${API_BASE_URL}/client/zones`;

export const getPageRuleListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules`;

export const deletePageRuleAPI = (distID, pageRuleID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules/${pageRuleID}`;

export const updatePageRuleAPI = (distID, pageRuleID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules/${pageRuleID}`;

export const reorderPageRuleAPI = (distID, pageRuleID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules/${pageRuleID}/re-order`;

export const getOriginDetailsAPI = (distID, originID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}`;

export const getOriginListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/origins`;

export const getOriginServersAPI = (distID, originID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}/servers`;

export const addOriginServerAPI = (distID, originID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}/servers`;

export const deleteOriginServerAPI = (distID, originID, serverID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}/servers/${serverID}`;

export const updateOriginServerAPI = (distID, originID, serverID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}/servers/${serverID}`;

export const deleteOriginAPI = (distID, originID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}`;

export const addOriginAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/origins`;

export const updateOriginAPI = (distID, originID) => `${API_BASE_URL}/client/distributions/${distID}/origins/${originID}`;

export const addPageRuleAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules`;

export const getPageRuleOperatorsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules/operators`;

export const getPageRuleDetailsAPI = (distID, pageRuleID) => `${API_BASE_URL}/client/distributions/${distID}/page-rules/${pageRuleID}`;

export const getConfigurationAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/configuration`;

export const updateConfigurationAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/configuration`;

export const getGeneralSettingsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/general-settings`;

export const updateGeneralSettingsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/general-settings`;

export const getRestrictionsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/restrictions`;

export const updateRestrictionsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/restrictions`;

export const addCNAMEAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/canonical-names`;

export const deleteCNAMEAPI = (distID, cNAMEID) => `${API_BASE_URL}/client/distributions/${distID}/canonical-names/${cNAMEID}`;

export const getCNAMEListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/canonical-names`;

export const updateCNAMEAPI = (distID, cNAMEID) => `${API_BASE_URL}/client/distributions/${distID}/canonical-names/${cNAMEID}`;

export const getCountryListAPI = () => `${API_BASE_URL}/client/countries`;

export const getAvailableErrorCodeListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/error-codes/available`;

export const getErrorCodeListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/error-codes`;

export const addErrorCodeAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/error-codes`;

export const deleteErrorCodeAPI = (distID, errorCodeID) => `${API_BASE_URL}/client/distributions/${distID}/error-codes/${errorCodeID}`;

export const updateErrorCodeAPI = (distID, errorCodeID) => `${API_BASE_URL}/client/distributions/${distID}/error-codes/${errorCodeID}`;

export const createCacheInvalidationAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/cache-invalidations`;

export const getCacheInvalidationListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/cache-invalidations/filter`;

export const createCachePrefetchingAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/cache-prefetch`;

export const getCachePrefetchingListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/cache-prefetch/filter`;

export const getPrefetchHeadersListAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/cache-prefetch/headers`;

export const disableDistributionAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/status/disable`;

export const enableDistributionAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/status/enable`;

export const disableDistributionRawLogsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/raw-logs/disable`;

export const enableDistributionRawLogsAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/raw-logs/enable`;

export const moveDistributionAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/move`;

export const getWafPolicyAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/waf`;

export const updateWafPolicyAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/waf`;

/* Certificates APIs */

export const createCertificateAPI = () => `${API_BASE_URL}/client/certificates`;

export const getFreeSSLCertificateAPI = (distID, cNAMEID) => `${API_BASE_URL}/client/distributions/${distID}/canonical-names/${cNAMEID}/request-certificate`;

export const getCertificateCNAMEsAPI = (certID) => `${API_BASE_URL}/client/certificates/${certID}/canonical-names`;

export const getCertificateDetailsAPI = (certID) => `${API_BASE_URL}/client/certificates/${certID}`;

export const copyCertificateAPI = (certID) => `${API_BASE_URL}/client/certificates/${certID}/copy`;

export const getCertificateListAPI = () => `${API_BASE_URL}/client/certificates`;

export const updateCertificateAPI = () => `${API_BASE_URL}/client/certificates`;

/* Billing APIs */

export const addBalanceAPI = () => `${API_BASE_URL}/client/billing/transactions`;

export const addCreditCardAPI = () => `${API_BASE_URL}/client/billing/credit-cards`;

export const deleteCreditCardAPI = (creditCardID) => `${API_BASE_URL}/client/billing/credit-cards/${creditCardID}`;

export const getBillingHistoryAPI = () => `${API_BASE_URL}/client/billing/transactions`;

export const getCreditCardListAPI = () => `${API_BASE_URL}/client/billing/credit-cards`;

export const getCreditCardDetailsAPI = (creditCardID) => `${API_BASE_URL}/client/billing/credit-cards/${creditCardID}`;

export const getTransactionDetailsAPI = (transactionID) => `${API_BASE_URL}/client/billing/transactions/${transactionID}`;

export const updateCreditCardAPI = (creditCardID) => `${API_BASE_URL}/client/billing/credit-cards/${creditCardID}/set-default`;

export const getTaxInformationAPI = () => `${API_BASE_URL}/client/billing/transactions/prepare-payment`;

export const getBalanceInformationAPI = () => `${API_BASE_URL}/client/billing/ticker`;

export const getBillingSettingsAPI = () => `${API_BASE_URL}/client/billing/invoice-settings`;

export const updateBillingSettingsAPI = () => `${API_BASE_URL}/client/billing/invoice-settings`;

export const getAutoPaySettingsAPI = () => `${API_BASE_URL}/client/billing/settings/order`;

export const updateAutoPaySettingsAPI = () => `${API_BASE_URL}/client/billing/settings/order`;

export const enableAutoPaymentAPI = () => `${API_BASE_URL}/client/billing/settings/enable-order`;

export const disableAutoPaymentAPI = () => `${API_BASE_URL}/client/billing/settings/disable-order`;

export const getBillingServicesAPI = () => `${API_BASE_URL}/client/billing/services`;

export const getBillingPlansAPI = (serviceID) => `${API_BASE_URL}/client/billing/services/${serviceID}/plans`;

export const purchasePlanAPI = (action, serviceId, planId) => `${API_BASE_URL}/client/billing/services/${serviceId}/plans/${planId}/${action}`;

export const getSubscribedPlansAPI = () => `${API_BASE_URL}/client/billing/services/all/plans/subscribed`;

export const getCostExplorerAPI = () => `${API_BASE_URL}/client/billing/cost-explorer`;

export const activateLicenseAPI = (serviceId, planId) => `${API_BASE_URL}/client/billing/services/${serviceId}/plans/${planId}/licence/activate`;

export const revokeLicenseAPI = (serviceId, planId) => `${API_BASE_URL}/client/billing/services/${serviceId}/plans/${planId}/licence/revoke`;

/* Home APIs */

export const getBandwidthDataAPI = () => `${API_BASE_URL}/`;

export const getHitMissDataAPI = () => `${API_BASE_URL}/`;

export const getHTTPResponseDataAPI = () => `${API_BASE_URL}/`;

export const getRequestDataAPI = () => `${API_BASE_URL}/`;

export const getLineChartDataAPI = () => `${API_BASE_URL}/client/overview/network-statistics-by-date`;

export const getStatsAPI = () => `${API_BASE_URL}/client/overview/network-summary`;

export const getHTTPRequestSummaryAPI = () => `${API_BASE_URL}/client/overview/http-request-summary`;

/* Logs APIs */

export const getLogListAPI = () => `${API_BASE_URL}/client/logs/filter`;

export const getRawLogListAPI = () => `${API_BASE_URL}/client/logs/raw`;

export const getRawLogDetailsAPI = (id) => `${API_BASE_URL}/client/logs/raw/${id}`;

/* Stats APIs */

export const getBandwidthSummaryStatsAPI = () => `${API_BASE_URL}/client/statistics/bandwidth/summary`;

export const getBandwidthStatsAPI = () => `${API_BASE_URL}/client/statistics/bandwidth/`;

export const getHitMissSummaryStatsAPI = () => `${API_BASE_URL}/client/statistics/hit-miss/summary`;

export const getHitMissStatsAPI = () => `${API_BASE_URL}/client/statistics/hit-miss/`;

export const getRequestsSummaryStatsAPI = () => `${API_BASE_URL}/client/statistics/requests/summary`;

export const getRequestsStatsAPI = () => `${API_BASE_URL}/client/statistics/requests/`;

export const getTrafficSummaryStatsAPI = () => `${API_BASE_URL}/client/statistics/traffic/summary`;

export const getTrafficStatsAPI = () => `${API_BASE_URL}/client/statistics/traffic/`;

/* Visitors APIs */

export const getVisitorsBrowserSummaryAPI = () => `${API_BASE_URL}/client/statistics/browsers/summary`;

export const getVisitorsBrowserAPI = () => `${API_BASE_URL}/client/statistics/browsers/`;

export const getVisitorsCountrySummaryAPI = () => `${API_BASE_URL}/client/statistics/countries/summary`;

export const getVisitorsCountryAPI = () => `${API_BASE_URL}/client/statistics/countries/`;

export const getVisitorsDeviceSummaryAPI = () => `${API_BASE_URL}/client/statistics/devices/summary`;

export const getVisitorsDeviceAPI = () => `${API_BASE_URL}/client/statistics/devices/`;

export const getVisitorsOSSummaryAPI = () => `${API_BASE_URL}/client/statistics/operating-systems/summary`;

export const getVisitorsOSAPI = () => `${API_BASE_URL}/client/statistics/operating-systems/`;

export const getProfileActivitiesAPI = () => `${API_BASE_URL}/client/user/activities`;

export const getReferralLinkAPI = () => `${API_BASE_URL}/client/organization/referrals`;

export const postReferralLinkAPI = () => `${API_BASE_URL}/client/organization/referrals`;

// Workspaces API

export const getOrganizationSettingsAPI = () => `${API_BASE_URL}/client/organization/settings`;

export const updateOrganizationSettingsAPI = () => `${API_BASE_URL}/client/organization/settings`;

export const getAssignedWorkspacesAPI = () => `${API_BASE_URL}/client/organization/assigned-workspaces`;

export const getAssignedWorkspaceDetailsAPI = (invID) => `${API_BASE_URL}/client/organization/assigned-workspaces/${invID}`;

export const getOwnedWorkspacesAPI = () => `${API_BASE_URL}/client/organization/workspaces`;

export const createWorkspaceAPI = () => `${API_BASE_URL}/client/organization/workspaces`;

export const updateWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}`;

export const deleteWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}`;

export const leaveWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/assigned-workspaces/${wsID}/leave`;

export const acceptWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/assigned-workspaces/${wsID}/accept`;

export const rejectWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/assigned-workspaces/${wsID}/reject`;

export const getPredefinedRolesAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles/pre-defined`;

export const getWorkspaceRolesAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles`;

export const inviteUserAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users`;

export const getWorkspaceUsersAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users`;

export const getWorkspaceUserActivityAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/activities`;

export const getWorkspaceUserDetailsAPI = (wsID, userID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users/${userID}`;

export const editWorkspaceUserDetailsAPI = (wsID, userID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users/${userID}`;

export const removeWorkspaceUserAPI = (wsID, userID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users/${userID}`;

export const getPermissionsListAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles/permissions`;

export const createWorkspaceRoleAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles`;

export const getWorkspaceRoleDetailsAPI = (wsID, roleID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles/${roleID}`;

export const editWorkspaceRoleAPI = (wsID, roleID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles/${roleID}`;

export const removeWorkspaceRoleAPI = (wsID, roleID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/roles/${roleID}`;

export const transferWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/transfer`;

export const cancelTransferWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/transfer/cancel`;

export const acceptTransferWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/transfer/accept`;

export const rejectTransferWorkspaceAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/transfer/reject`;

export const getSupportOrganizationAPI = (query) => `${API_BASE_URL}/client/support/organizations?term=${query}`;

export const getSupportWorkspaceAPI = (orgID, query) => `${API_BASE_URL}/client/support/organizations/${orgID}/workspaces?term=${query}`;

export const getWorkspaceAlertNotificationsAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts`;

export const getWorkspaceAlertNotificationsListAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts`;

export const createWorkspaceAlertNotificationAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts`;

export const getWorkspaceAlertNotificationAPI = (wsID, alertID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts/${alertID}`;

export const editWorkspaceAlertNotificationAPI = (wsID, alertID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts/${alertID}`;

export const removeWorkspaceAlertNotificationAPI = (wsID, alertID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts/${alertID}`;

export const getWorkspaceNotifiableEventsAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/alerts/notifiable-events`;

export const getActiveWorkspaceUsersAPI = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/users/active`;

// WAF API

export const getWafListsAPI = () => `${API_BASE_URL}/client/waf/text-lists`;

export const deleteWafListAPI = (listID) => `${API_BASE_URL}/client/waf/text-lists/${listID}`;

export const getWafListDetailsAPI = (listID) => `${API_BASE_URL}/client/waf/text-lists/${listID}`;

export const updateWafListAPI = (listID) => `${API_BASE_URL}/client/waf/text-lists/${listID}`;

export const createWafListAPI = () => `${API_BASE_URL}/client/waf/text-lists`;

export const getWafTypesAPI = () => `${API_BASE_URL}/client/waf/text-lists/types`;

export const getWafListContentAPI = (listID) => `${API_BASE_URL}/client/waf/text-lists/${listID}/contents`;

export const deleteWafListContentAPI = (listID, contentID) => `${API_BASE_URL}/client/waf/text-lists/${listID}/contents/${contentID}`;

export const updateWafListContentAPI = (listID) => `${API_BASE_URL}/client/waf/text-lists/${listID}/contents/batch`;

export const getWAFPolicyListAPI = () => `${API_BASE_URL}/client/waf/policies`;

export const createWAFPolicyAPI = () => `${API_BASE_URL}/client/waf/policies`;

export const getWAFPolicyDetailsAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}`;

export const updateWAFPolicyAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}`;

export const duplicateWAFPolicyAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/duplicate`;

export const getWAFPolicyDistributionsAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/distributions`;

export const deleteWAFPolicyAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}`;

export const getManagedRulesAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/managed-rules`;

export const updateManagedRulesAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/managed-rules/${ruleID}/toggle`;

export const getCustomRulesAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules`;

export const getCustomRuleDetailsAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}`;

export const createCustomRuleAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules`;

export const updateCustomRuleAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}`;

export const deleteCustomRuleAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}`;

export const getResourcesAPI = (policyID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/resources`;

export const toggleCustomRuleAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}/toggle`;

export const reorderCustomRuleAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}/re-order`;

export const dragCustomRuleAPI = (policyID, ruleID) => `${API_BASE_URL}/client/waf/policies/${policyID}/custom-rules/${ruleID}/order`;

// Object Storage  - Credentials  APIs

export const getKeyListAPI = () => `${API_BASE_URL}/client/cloud-storage/keys`;

export const createKeyAPI = () => `${API_BASE_URL}/client/cloud-storage/keys`;

export const editKeyAPI = () => `${API_BASE_URL}/client/cloud-storage/keys`;

export const deleteKeyAPI = () => `${API_BASE_URL}/client/cloud-storage/keys`;

export const getStorageStatusAPI = () => `${API_BASE_URL}/client/cloud-storage/status`;

export const activateStorageAPI = () => `${API_BASE_URL}/client/cloud-storage/status/activate`;

export const getBucketListAPI = () => `${API_BASE_URL}/client/cloud-storage/buckets`;

export const getUsageMetricsAPI = () => `${API_BASE_URL}/client/cloud-storage/usage`;

export const getRegionsAPI = () => `${API_BASE_URL}/client/cloud-storage/regions`;

export const signRequestAPI = () => `${API_BASE_URL}/client/cloud-storage/sign`;

export const getBucketInfoAPI = (bucketName) => `${API_BASE_URL}/client/cloud-storage/buckets/${bucketName}`;

export const getRedirectionIndexAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites`;

export const getRedirectionDetailAPI = (distID, rewriteID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites/${rewriteID}`;

export const getRedirectionFlagAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites/flags`;

export const addRedirectionAPI = (distID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites`;

export const updateRedirectionAPI = (distID, rewriteID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites/${rewriteID}`;

export const deleteRedirectionAPI = (distID, rewriteID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites/${rewriteID}`;

export const reorderReorderAPI = (distID, rewriteID) => `${API_BASE_URL}/client/distributions/${distID}/rewrites/${rewriteID}/re-order`;

export const getBucketStorageInfoAPI = (bucketName) => `${API_BASE_URL}/client/cloud-storage/buckets/${bucketName}/storage-by-date`;

export const getBucketTrafficInfoAPI = (bucketName) => `${API_BASE_URL}/client/cloud-storage/buckets/${bucketName}/traffic-by-date`;

export const presignRequestAPI = () => `${API_BASE_URL}/client/cloud-storage/pre-sign`;

export const getNetworkSummaryAPI = () => `${API_BASE_URL}/client/cloud-storage/monitoring/network-summary`;

export const getNetworkByDateAPI = () => `${API_BASE_URL}/client/cloud-storage/monitoring/network-by-date`;

export const GET_DIST_SUMMARY_API = () => `${API_BASE_URL}/client/widget`;

export const LIST_WORKSPACE_RECENT_REPORT_API = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/reports`;

export const LIST_WORKSPACE_RECENT_ACTIVITY_API = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}/activities`;

export const GET_WORKSPACE_DETAIL_API = (wsID) => `${API_BASE_URL}/client/organization/workspaces/${wsID}`;

export const GET_CLOUD_STORAGE_SUMMARY_API = () => `${API_BASE_URL}/client/cloud-storage/widget`;

// Cloner
export const CLONER_GET_LIST_API = () => `${API_BASE_URL}/client/cloner/jobs`;
export const CLONER_GET_EXECUTION_LIST_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}/executions`;
export const CLONER_JOB_DETAIL_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}`;
export const CLONER_CREATE_JOB_API = () => `${API_BASE_URL}/client/cloner/jobs`;
export const CLONER_DELETE_JOB_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}`;
export const CLONER_GET_ACTION_LIST_API = () => `${API_BASE_URL}/client/cloner/application/options`;
export const CLONER_ACTIVATE_JOB_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}/activate`;
export const CLONER_DEACTIVATE_JOB_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}/deactivate`;
export const CLONER_TRIGGER_JOB_API = (jobId) => `${API_BASE_URL}/client/cloner/jobs/${jobId}/trigger`;
export const CLONER_WIDGET_API = () => `${API_BASE_URL}/client/cloner/statistics/widget`;

// Cloner Monitor
export const GET_JOB_SUMMARY_API = () => `${API_BASE_URL}/client/cloner/statistics/summary`;
export const GET_JOB_DATE_HISTOGRAM_API = () => `${API_BASE_URL}/client/cloner/statistics/date-histogram`;
