import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import SpinnerPage from 'components/spinnerPage/SpinnerPage.component';
import RouteControllerContainer from './controller/RouteController.container';
import NotificationsContainer from './notifications/Notifications.container';

const AppContainer = lazy(() => import('modules/app/App.container'));
const EnsureLoggedInContainer = lazy(() => import('./middleware/EnsureLoggedIn.container'));
const UnauthenticatedContainer = lazy(() => import('./middleware/Unauthenticated.container'));

const IframeContainer = lazy(() => import('./iframe/Iframe.container'));
const LoginContainer = lazy(() => import('modules/auth/login/Login.container'));
const RegisterContainer = lazy(() => import('modules/auth/register/Register.container'));
const ForgotPasswordContainer = lazy(() => import('modules/auth/forgotPassword/ForgotPassword.container'));
const ReleaseNotes = lazy(() => import('modules/releaseNotes/ReleaseNotes.component'));
const ResetPasswordContainer = lazy(() => import('modules/auth/resetPassword/ResetPassword.container'));
const VerifyMailContainer = lazy(() => import('modules/auth/verifyMail/VerifyMail.container'));
const LogOutContainer = lazy(() => import('modules/auth/logOut/logOut.container'));

const Routes = () => (
  <RouteControllerContainer>
    <NotificationsContainer>
      <Suspense fallback={<SpinnerPage />}>
        <Switch>
          <Route exact path="/login">
            <UnauthenticatedContainer>
              <LoginContainer />
            </UnauthenticatedContainer>
          </Route>
          <Route exact path="/register">
            <UnauthenticatedContainer>
              <RegisterContainer />
            </UnauthenticatedContainer>
          </Route>
          <Route exact path="/forgot-password">
            <UnauthenticatedContainer>
              <ForgotPasswordContainer />
            </UnauthenticatedContainer>
          </Route>
          <Route exact path="/release-notes">
            <ReleaseNotes />
          </Route>
          <Route exact path="/reset-password">
            <UnauthenticatedContainer>
              <ResetPasswordContainer />
            </UnauthenticatedContainer>
          </Route>
          <Route exact path="/verify-email">
            <UnauthenticatedContainer>
              <VerifyMailContainer />
            </UnauthenticatedContainer>
          </Route>
          <Route exact path="/ws-switcher">
            <IframeContainer />
          </Route>
          <Route exact path="/logout">
            <LogOutContainer />
          </Route>
          <Route path={['/:orgID/:wsID/', '/']}>
            <EnsureLoggedInContainer>
              <AppContainer />
            </EnsureLoggedInContainer>
          </Route>
        </Switch>
      </Suspense>
    </NotificationsContainer>
  </RouteControllerContainer>
);

export default Routes;
