import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import Notification from 'components/notifications/base/Notification';
import NotificationIcon from 'components/notifications/base/NotificationIcon';
import ToastNotification from '../baseToast/ToastNotification.component';

class SuccessNotification extends Component {
  state = {
    isDialogOpen: true,
  }

  removeNotification = () => {
    this.props.removeNotification(this.props.notification.id);
  }

  render() {
    const { props } = this;
    if (props.forceDialog) {
      return (
        <Dialog
          className="m-success-notification-dialog"
          isOpen={this.state.isDialogOpen}
          onClose={this.removeNotification}
        >
          <Notification>
            <NotificationIcon icon="check_circle" intent="success" />
            <div className="m-success-notification-dialog-title">{props.notification.title || 'Your action has succeeded.'}</div>
            <div className="m-success-notification-dialog-text">{props.notification.text}</div>
          </Notification>
        </Dialog>
      );
    }
    return <ToastNotification action={props.notification.action} message={props.notification.text} onDismiss={this.removeNotification} type="success" />;
  }
}

SuccessNotification.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  forceDialog: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  notification: PropTypes.shape({
    action: PropTypes.shape({}),
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
  }).isRequired,
};

SuccessNotification.defaultProps = {
  forceDialog: false,
};

export default withRouter(SuccessNotification);
