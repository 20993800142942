import { combineReducers } from 'redux';
import AuthReducer from 'modules/auth/Auth.reducer';
import CertificatesReducer from 'modules/certificates/Certificates.reducer';
import CommonReducer from 'common/redux/common.reducer';
import DistributionsReducer from 'modules/distributions/Distributions.reducer';
import BillingReducer from 'modules/billing/Billing.reducer';
import HomeReducer from 'modules/home/Home.reducer';
import LogsReducer from 'modules/logs/Logs.reducer';
import ProfileReducer from 'modules/profile/Profile.reducer';
import StatsReducer from 'modules/stats/Stats.reducer';
import WorkspacesReducer from 'modules/workspaces/Workspaces.reducer';
import WafReducer from 'modules/waf/Waf.reducer';
import BucketsReducer from 'modules/buckets/Buckets.reducer';
import BucketDashbaordReducer from 'modules/bucketDashboard/BucketDashboard.reducer';
import WsDashbaordReducer from 'modules/wsDashboard/WsDashboard.reducer';
import CredentialsReducer from 'modules/credentials/Credentials.reducer';
import ClonerReducer from 'modules/cloner/Cloner.reducer';
import ClonerMonitorReducer from 'modules/clonerMonitor/ClonerMonitor.reducer';
import AdminReducer from 'modules/admin/Admin.reducer';

const appReducer = combineReducers({
  auth: AuthReducer,
  billing: BillingReducer,
  certificates: CertificatesReducer,
  common: CommonReducer,
  distributions: DistributionsReducer,
  home: HomeReducer,
  logs: LogsReducer,
  profile: ProfileReducer,
  stats: StatsReducer,
  workspaces: WorkspacesReducer,
  waf: WafReducer,
  bucket: BucketsReducer,
  bucketDashbaord: BucketDashbaordReducer,
  wsDashbaord: WsDashbaordReducer,
  keys: CredentialsReducer,
  cloner: ClonerReducer,
  clonerMonitor: ClonerMonitorReducer,
  admin: AdminReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'MERLIN_CLEAR_USER':
      return appReducer(undefined, action);
    case 'MERLIN_CLEAR_WORKSPACE':
      return appReducer({
        auth: {
          credentials: state.auth?.credentials,
        },
      }, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
