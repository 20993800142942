import { PROFILE_ACTIONS } from './Profile.actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_ACTIONS.GET_ACTIVITIES:
      return {
        ...state,
        activities: state.activities ? [...state.activities, action.payload.data] : [action.payload.data],
        nextActivity: action.payload.meta.next,
      };
    case PROFILE_ACTIONS.GET_REFERRAL_LINK:
      return {
        ...state,
        referral: action.payload.data,
      };
    case PROFILE_ACTIONS.RESET_ACTIVITIES:
      return {
        ...state,
        activities: [],
        nextActivity: undefined,
      };
    default:
      return state;
  }
};
