import { Component } from 'react';
import PropTypes from 'prop-types';
import { appendClasses } from 'common/helpers';

class NotificationErrorDetail extends Component {
  renderUnformattedError = (error) => {
    if (typeof error === 'string') {
      return <li className="m-notification-error-list-element">{error}</li>;
    }
    if (typeof error === 'object' && error.errorMessage) {
      return <li className="m-notification-error-list-element">{error.errorMessage}</li>;
    }
    return null;
  }

  renderErrors = (errors) => {
    if (errors && errors.inputs) {
      const { inputs } = errors;
      return Object.keys(inputs).map((errorCategory) => (
        <div className="m-notification-error" key={`m-notification-error-${errorCategory}`}>
          <div className="m-notification-error-title">{errorCategory}</div>
          <ul className="m-notification-error-list">
            {
              Array.isArray(inputs[errorCategory])
                ? inputs[errorCategory].map((error, index) => (
                  typeof error === 'string'
                    // NOTE These messages are returned by the backend without any ids. We have to use indexes
                    // And it's not a huge problem since these messages are completely static; never changed or removed
                    // eslint-disable-next-line react/no-array-index-key
                    ? <li className="m-notification-error-list-element" key={`m-notification-error-list-element-${index}`}>{error}</li>
                    : null
                ))
                : this.renderUnformattedError(inputs[errorCategory])
            }
          </ul>
        </div>
      ));
    }
    if (errors && errors.message && typeof errors.message === 'string') {
      return (
        <div className="m-notification-error">
          <ul className="m-notification-error-list">
            <li className="m-notification-error-list-element">
              {errors.message}
            </li>
          </ul>
        </div>
      );
    }
    return null;
  }

  render() {
    const { props } = this;
    return (
      <div className={appendClasses('m-notification-error-detail-component', props.classes)}>
        {this.renderErrors(props.errors)}
      </div>
    );
  }
}

NotificationErrorDetail.propTypes = {
  classes: PropTypes.string,
  errors: PropTypes.shape({}),
};

NotificationErrorDetail.defaultProps = {
  classes: null,
  errors: null,
};

export default NotificationErrorDetail;
