import axios from 'axios';
import { getResponseData } from 'state/actions/apiHelpers';
import {
  GET_JOB_SUMMARY_API,
  GET_JOB_DATE_HISTOGRAM_API,
} from 'state/actions/apiFunctions';

export const JOB_MONITOR_ACTIONS = {
  JOB_SUMMARY_REQUEST: 'JOB_SUMMARY_REQUEST',
  JOB_DATE_HISTOGRAM_REQUEST: 'JOB_DATE_HISTOGRAM_REQUEST',
};

export const jobSummarySuccess = (data) => ({
  type: JOB_MONITOR_ACTIONS.JOB_SUMMARY_REQUEST,
  payload: data,
});

export const getJobSummary = (data) => (dispatch) => (
  axios.get(GET_JOB_SUMMARY_API(), {
    params: {
      'date.gte': data.date.gte,
      'date.lte': data.date.lte,
    },
  })
    .then((response) => {
      dispatch(jobSummarySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const jobDateHistrogramSuccess = (data) => ({
  type: JOB_MONITOR_ACTIONS.JOB_DATE_HISTOGRAM_REQUEST,
  payload: data,
});

export const getJobDateHistogram = (data) => (dispatch) => (
  axios.get(GET_JOB_DATE_HISTOGRAM_API(), {
    params: {
      'date.gte': data.date.gte,
      'date.lte': data.date.lte,
    },
  })
    .then((response) => {
      dispatch(jobDateHistrogramSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);
