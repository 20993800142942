import axios from 'axios';
import {
  getKeyListAPI,
  createKeyAPI,
  editKeyAPI,
  deleteKeyAPI,
  getStorageStatusAPI,
  activateStorageAPI,
} from 'state/actions/apiFunctions';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';

export const KEYS_ACTIONS = {
  GET_KEY_LIST: 'K_GET_KEY_LIST',
  CREATE_KEY: 'K_CREATE_KEY',
  DELETE_KEY: 'K_DELETE_KEY',
  EDIT_KEY: 'K_EDIT_KEY',
  GET_STORAGE_STATUS: 'GET_STORAGE_STATUS',
  ACTIVATE_STORAGE: 'ACTIVATE_STORAGE',
};

const getStorageStatusSuccess = (data) => ({
  type: KEYS_ACTIONS.GET_STORAGE_STATUS,
  payload: data,
});

export const getStorageStatus = () => (dispatch) => (
  axios.get(getStorageStatusAPI())
    .then((response) => {
      dispatch(getStorageStatusSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const activateStorageSuccess = (data) => ({
  type: KEYS_ACTIONS.ACTIVATE_STORAGE,
  payload: data,
});

export const activateStorage = () => (dispatch) => (
  axios.post(activateStorageAPI(), {})
    .then((response) => {
      dispatch(activateStorageSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'activate-storage-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'activate-storage-error'));
      throw (error);
    })
);

/**
 * getKeyList(): returns the list of keys
 */

export const getKeyListSuccess = (data) => ({
  type: KEYS_ACTIONS.GET_KEY_LIST,
  payload: data,
});

export const getKeyList = (page) => (dispatch) => (
  axios.get(getKeyListAPI(), {
    params: {
      page: page && page.currentPageNumber,
      per_page: page && page.pageSize,
      'sort_by[]': page && page.sortBy,
      'sort_desc[]': page && page.sortOrder,
    },
  })
    .then((response) => {
      dispatch(getKeyListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* deleteKey(data): delete the key with given data.id */

const deleteKeySuccess = (data) => ({
  type: KEYS_ACTIONS.DELETE_KEY,
  payload: data,
});

export const deleteKey = (accessKey) => (dispatch) => (
  axios.delete(`${deleteKeyAPI()}/${accessKey}`, {
    data: {},
  })
    .then((response) => {
      dispatch(addNotification(null, 'delete-key-success'));
      dispatch(deleteKeySuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-key-error'));
      throw (error);
    })
);

export const editKey = (data, accessKey) => (dispatch) => (
  axios.patch(`${editKeyAPI()}/${accessKey}`, {
    description: data.description,
  })
    .then(() => {
      dispatch(addNotification(null, 'edit-key-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'edit-key-error'));
      throw (error);
    })
);

const createKeySuccess = (data) => ({
  type: KEYS_ACTIONS.CREATE_KEY,
  payload: data,
});

export const createKey = (data) => (dispatch) => (
  axios.post(createKeyAPI(), {
    description: data.description,
  })
    .then((response) => {
      const responseData = getResponseData(response);
      dispatch(addNotification(null, 'create-key-success'));
      dispatch(createKeySuccess(responseData));
      return response && response.data;
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-key-error'));
      throw (error);
    })
);
