import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeNotification } from 'common/redux/common.actions';
import { logout } from 'modules/auth/Auth.actions';
import UpdatePasswordSuccess from './UpdatePasswordSuccess.component';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    logout,
    removeNotification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordSuccess);
