import axios from 'axios';
import { getErrorDetails, getResponseData } from 'state/actions/apiHelpers';
import {
  getBandwidthDataAPI,
  getHitMissDataAPI,
  getHTTPResponseDataAPI,
  getRequestDataAPI,
  getStatsAPI,
  getLineChartDataAPI,
  getHTTPRequestSummaryAPI,
} from 'state/actions/apiFunctions';
import { addNotification } from 'common/redux/common.actions';

export const HOME_ACTIONS = {
  GET_BANDWIDTH_DATA: 'H_GET_BANDWIDTH_DATA',
  GET_HIT_MISS_DATA: 'H_GET_HIT_MISS_DATA',
  GET_HTTP_REQUEST_SUMMARY: 'H_GET_HTTP_REQUEST_SUMMARY',
  GET_HTTP_RESPONSE_DATA: 'H_GET_HTTP_RESPONSE_DATA',
  GET_LINE_CHART_DATA: 'H_GET_LINE_CHART_DATA',
  GET_REQUEST_DATA: 'H_GET_REQUEST_DATA',
  GET_STATS: 'H_GET_STATS',
};

/* Bandwidth Data */

export const getBandwidthDataSuccess = (data) => ({
  type: HOME_ACTIONS.GET_BANDWIDTH_DATA,
  payload: data,
});

export const getBandwidthData = () => (dispatch) => (
  axios.get(getBandwidthDataAPI())
    .then((response) => {
      dispatch(getBandwidthDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Hit Miss Data */

export const getHitMissDataSuccess = (data) => ({
  type: HOME_ACTIONS.GET_HIT_MISS_DATA,
  payload: data,
});

export const getHitMissData = () => (dispatch) => (
  axios.get(getHitMissDataAPI())
    .then((response) => {
      dispatch(getHitMissDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* HTTP Response Data */

export const getHTTPResponseDataSuccess = (data) => ({
  type: HOME_ACTIONS.GET_HTTP_RESPONSE_DATA,
  payload: data,
});

export const getHTTPResponseData = () => (dispatch) => (
  axios.get(getHTTPResponseDataAPI())
    .then((response) => {
      dispatch(getHTTPResponseDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Request Data */

export const getRequestDataSuccess = (data) => ({
  type: HOME_ACTIONS.GET_REQUEST_DATA,
  payload: data,
});

export const getRequestData = (data) => (dispatch) => (
  axios.get(getRequestDataAPI(), {

    params: {
      distribution_id: data && data.distributionID,
      interval: data && data.interval,
      zone_id: data && data.zoneID,
    },
  })
    .then((response) => {
      dispatch(getRequestDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Stats */

export const getLineChartDataSuccess = (data) => ({
  type: HOME_ACTIONS.GET_LINE_CHART_DATA,
  payload: data,
});

export const getLineChartData = (data) => (dispatch) => (
  axios.get(getLineChartDataAPI(), {

    params: {
      distribution_id: data && data.distributionID !== 'all' ? data.distributionID : null,
      interval: data && data.interval !== 'default' ? data.interval : null,
      zone_id: data && data.zoneID !== 'all' ? data.zoneID : null,
    },
  })
    .then((response) => {
      dispatch(getLineChartDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Stats */

export const getStatsSuccess = (data) => ({
  type: HOME_ACTIONS.GET_STATS,
  payload: data,
});

export const getStats = (data) => (dispatch) => (
  axios.get(getStatsAPI(), {

    params: {
      distribution_id: data && data.distributionID !== 'all' ? data.distributionID : null,
      interval: data && data.interval !== 'default' ? data.interval : null,
      zone_id: data && data.zoneID !== 'all' ? data.zoneID : null,
    },
  })
    .then((response) => {
      dispatch(getStatsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const getHTTPRequestSummarySuccess = (data) => ({
  type: HOME_ACTIONS.GET_HTTP_REQUEST_SUMMARY,
  payload: data,
});

export const getHTTPRequestSummary = (data) => (dispatch) => (
  axios.get(getHTTPRequestSummaryAPI(), {

    params: {
      distribution_id: data && data.distributionID !== 'all' ? data.distributionID : null,
      interval: data && data.interval !== 'default' ? data.interval : null,
      zone_id: data && data.zoneID !== 'all' ? data.zoneID : null,
    },
  })
    .then((response) => {
      dispatch(getHTTPRequestSummarySuccess(getResponseData(response)));
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(addNotification(getErrorDetails(error), 'summary-endpoint-error'));
      }
      throw (error);
    })
);
