import axios from 'axios';
import { getResponseData } from 'state/actions/apiHelpers';
import {
  GET_DIST_SUMMARY_API,
  LIST_WORKSPACE_RECENT_REPORT_API,
  LIST_WORKSPACE_RECENT_ACTIVITY_API,
  GET_CLOUD_STORAGE_SUMMARY_API,
  CLONER_WIDGET_API,
} from 'state/actions/apiFunctions';

export const WORKSPACE_DASHBOARD_ACTIONS = {
  DIST_SUMMARY_REQUEST: 'DIST_SUMMARY_REQUEST',
  WORKSPACE_RECENT_REPORT_REQUEST: 'WORKSPACE_RECENT_REPORT_REQUEST',
  WORKSPACE_RECENT_ACTIVITY_REQUEST: 'WORKSPACE_RECENT_ACTIVITY_REQUEST',
  CLOUD_STORAGE_SUMMARY_REQUEST: 'CLOUD_STORAGE_SUMMARY_REQUEST',
  WORKSPACE_RECENT_ACTIVITY_CLEAR: 'WORKSPACE_RECENT_ACTIVITY_CLEAR',
  CLONER_WIDGET_REQUEST: 'CLONER_WIDGET_REQUEST',
};

export const distSummarySuccess = (data) => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.DIST_SUMMARY_REQUEST,
  payload: data,
});

export const getDistSummary = () => (dispatch) => (
  axios.get(GET_DIST_SUMMARY_API(), {
  })
    .then((response) => {
      dispatch(distSummarySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const cloudStorageSummarySuccess = (data) => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.CLOUD_STORAGE_SUMMARY_REQUEST,
  payload: data,
});

export const getCloudStorageSummary = () => (dispatch) => (
  axios.get(GET_CLOUD_STORAGE_SUMMARY_API(), {
  })
    .then((response) => {
      dispatch(cloudStorageSummarySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const workspaceRecentReportSuccess = (data) => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_REPORT_REQUEST,
  payload: data,
});

export const getWorkspaceRecentReport = (data) => (dispatch) => (
  axios.get(LIST_WORKSPACE_RECENT_REPORT_API(data.wsID), {
  })
    .then((response) => {
      dispatch(workspaceRecentReportSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const workspaceRecentActivitySuccess = (data) => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_ACTIVITY_REQUEST,
  payload: data,
});

export const getWorkspaceRecentActivity = (data) => (dispatch) => (
  axios.get(LIST_WORKSPACE_RECENT_ACTIVITY_API(data.wsID), {
    params: {
      // page: data.page && data.page.currentPageNumber,
      // per_page: data.page_size ?? 10,
      date: data.interval,
      next: data.currentPageNumber > 1 ? data.nextStamp : null,
    },
  })
    .then((response) => {
      dispatch(workspaceRecentActivitySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const clearRecentActivity = () => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_ACTIVITY_CLEAR,
});

export const clonerWidgetSuccess = (data) => ({
  type: WORKSPACE_DASHBOARD_ACTIONS.CLONER_WIDGET_REQUEST,
  payload: data,
});

export const getClonerWidget = () => (dispatch) => (
  axios.get(CLONER_WIDGET_API(), {
  })
    .then((response) => {
      dispatch(clonerWidgetSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);
