import { COMMON_ACTIONS } from './common.actions';

const defaultState = {
  clientVersion: null,
  notifications: [],
  formValidationErrors: {},
  filters: {},
  localPreferences: {
    showBalanceSummary: localStorage.getItem('showBalanceSummary') ? localStorage.getItem('showBalanceSummary') === 'true' : true,
  },
  cancelTokens: [],
  pagePageRestrictions: {},
  pendingRequests: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.ADD_NOTIFICATION: {
      const notifications = [...state.notifications];
      const id = (notifications && notifications.length > 0) ? notifications[notifications.length - 1].id + 1 : 0;
      notifications.push({ id, data: action.payload, type: action.notificationType });
      return { ...state, notifications };
    }
    case COMMON_ACTIONS.REMOVE_NOTIFICATION: {
      const notifications = [...state.notifications].filter((notification) => notification.id !== action.payload);
      return { ...state, notifications };
    }
    case COMMON_ACTIONS.CLEAR_ALL_NOTIFICATION: {
      return { ...state, notifications: [] };
    }
    case COMMON_ACTIONS.ADD_VALIDATION_ERROR: {
      return { ...state, formValidationErrors: action.payload };
    }
    case COMMON_ACTIONS.CLEAR_VALIDATION_ERROR: {
      return { ...state, formValidationErrors: {} };
    }
    case COMMON_ACTIONS.UPDATE_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case COMMON_ACTIONS.UPDATE_FILTER_PARAMS:
      return { ...state, filters: { [action.payload.name]: action.payload.params } };
    case COMMON_ACTIONS.UPDATE_LOCAL_PREFERENCES: {
      localStorage.setItem(action.payload.name, action.payload.data);
      return { ...state, localPreferences: { ...(state.localPreferences), [action.payload.name]: action.payload.data } };
    }
    case COMMON_ACTIONS.UPDATE_PREVIOUS_PAGE:
      return { ...state, previousPage: action.payload };
    case COMMON_ACTIONS.UPDATE_SELECTED_MENU_ID:
      return { ...state, [`${action.menuName}`]: action.id };
    case COMMON_ACTIONS.PREVENT_CLOSE:
      return { ...state, preventClose: action.payload };
    case COMMON_ACTIONS.SET_IS_PHONE_INVALID:
      return { ...state, isPhoneInvalid: action.payload };
    case COMMON_ACTIONS.SET_CLIENT_VERSION:
      return { ...state, clientVersion: action.payload };
    case COMMON_ACTIONS.OPEN_VERIFY_CODE_DIALOG:
      return { ...state, verifyCodeDialogOpen: action.payload };
    case COMMON_ACTIONS.SET_PAGE_RESTRICTIONS:
      return { ...state, pagePageRestrictions: action.payload };
    case COMMON_ACTIONS.ADD_REQUEST:
      return { ...state, pendingRequests: [...state.pendingRequests, action.payload] };
    case COMMON_ACTIONS.REMOVE_ALL_REQUESTS:
      return { ...state, pendingRequests: [] };
    case COMMON_ACTIONS.REMOVE_REQUEST: {
      return { ...state, pendingRequests: state.pendingRequests?.filter((req) => req?.url !== action.payload?.url) };
    }
    case COMMON_ACTIONS.CANCEL_GROUP_REQUESTS: {
      const requestsToCancel = state.pendingRequests?.filter((req) => req?.pathname === action.payload);
      requestsToCancel?.forEach((request) => {
        if (request?.controller) {
          request.controller.abort();
        }
      });
    }
      return {
        ...state,
        pendingRequests: state.pendingRequests.filter((req) => req.pathname !== action.payload),
      };
    default:
      return state;
  }
};
