export const COMMON_ACTIONS = {
  ADD_NOTIFICATION: 'COMMON_ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'COMMON_REMOVE_NOTIFICATION',
  CLEAR_ALL_NOTIFICATION: 'CLEAR_ALL_NOTIFICATION',
  ADD_VALIDATION_ERROR: 'COMMON_ADD_VALIDATION_ERROR',
  CLEAR_VALIDATION_ERROR: 'COMMON_CLEAR_VALIDATION_ERROR',
  UPDATE_CURRENT_PAGE: 'COMMON_UPDATE_CURRENT_PAGE',
  UPDATE_FILTER_PARAMS: 'COMMON_UPDATE_FILTER_PARAMS',
  UPDATE_LOCAL_PREFERENCES: 'COMMON_UPDATE_LOCAL_PREFERENCES',
  UPDATE_PREVIOUS_PAGE: 'COMMON_UPDATE_PREVIOUS_PAGE',
  UPDATE_SELECTED_MENU_ID: 'COMMON_UPDATE_SELECTED_MENU_ID',
  UPDATE_SELECTED_WORKSPACE: 'COMMON_UPDATE_SELECTED_WORKSPACE',
  PREVENT_CLOSE: 'COMMON_PREVENT_CLOSE',
  SET_IS_PHONE_INVALID: 'SET_IS_PHONE_INVALID',
  OPEN_VERIFY_CODE_DIALOG: 'OPEN_VERIFY_CODE_DIALOG',
  SET_CLIENT_VERSION: 'SET_CLIENT_VERSION',
  SET_PAGE_RESTRICTIONS: 'SET_PAGE_RESTRICTIONS',
  ADD_REQUEST: 'ADD_REQUEST',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_ALL_REQUESTS: 'REMOVE_ALL_REQUESTS',
  CANCEL_GROUP_REQUESTS: 'CANCEL_GROUP_REQUESTS',
};

export const updatePreviousPage = (data) => ({
  type: COMMON_ACTIONS.UPDATE_PREVIOUS_PAGE,
  payload: data,
});

export const updateCurrentPage = (data) => ({
  type: COMMON_ACTIONS.UPDATE_CURRENT_PAGE,
  payload: data,
});

export const updateFilterParams = (data) => ({
  type: COMMON_ACTIONS.UPDATE_FILTER_PARAMS,
  payload: data,
});

export const updateSelectedMenuID = (data) => ({
  type: COMMON_ACTIONS.UPDATE_SELECTED_MENU_ID,
  id: data.id,
  menuName: data.menuName,
});

/* Notification Actions */

export const addNotification = (data, notificationType) => ({
  type: COMMON_ACTIONS.ADD_NOTIFICATION,
  payload: data,
  notificationType,
});

export const removeNotification = (data) => ({
  type: COMMON_ACTIONS.REMOVE_NOTIFICATION,
  payload: data,
});

export const clearAllNotification = () => ({
  type: COMMON_ACTIONS.CLEAR_ALL_NOTIFICATION,
});

// Validation Error
export const addValidationError = (data) => ({
  type: COMMON_ACTIONS.ADD_VALIDATION_ERROR,
  payload: data,
});

export const clearValidationError = (data) => ({
  type: COMMON_ACTIONS.CLEAR_VALIDATION_ERROR,
  payload: data,
});

// Local Preferences
export const updateLocalPreferences = (data) => ({
  type: COMMON_ACTIONS.UPDATE_LOCAL_PREFERENCES,
  payload: data,
});

export const preventClose = (data) => ({
  type: COMMON_ACTIONS.PREVENT_CLOSE,
  payload: data,
});

export const setIsPhoneInvalid = (data) => ({
  type: COMMON_ACTIONS.SET_IS_PHONE_INVALID,
  payload: data,
});

export const openVerifyCodeDialog = (data) => ({
  type: COMMON_ACTIONS.OPEN_VERIFY_CODE_DIALOG,
  payload: data,
});

export const setClientVersion = (data) => ({
  type: COMMON_ACTIONS.SET_CLIENT_VERSION,
  payload: data,
});

export const setPagePageRestrictions = (data) => ({
  type: COMMON_ACTIONS.SET_PAGE_RESTRICTIONS,
  payload: data,
});

export const addRequest = (config) => ({
  type: COMMON_ACTIONS.ADD_REQUEST,
  payload: config,
});

export const removeRequest = (config) => ({
  type: COMMON_ACTIONS.REMOVE_REQUEST,
  payload: config,
});

export const removeAllRequests = () => ({
  type: COMMON_ACTIONS.REMOVE_ALL_REQUESTS,
});

export const cancelGroupRequests = (pathname) => ({
  type: COMMON_ACTIONS.CANCEL_GROUP_REQUESTS,
  payload: pathname,
});
