import axios from 'axios';
import {
  getOrganizationSettingsAPI, updateOrganizationSettingsAPI,
  getOwnedWorkspacesAPI, getAssignedWorkspacesAPI,
  createWorkspaceAPI, deleteWorkspaceAPI,
  updateWorkspaceAPI, leaveWorkspaceAPI,
  acceptWorkspaceAPI, rejectWorkspaceAPI,
  getPredefinedRolesAPI, getWorkspaceRolesAPI,
  inviteUserAPI, getWorkspaceUsersAPI,
  removeWorkspaceUserAPI, getWorkspaceUserDetailsAPI,
  getWorkspaceUserActivityAPI, editWorkspaceUserDetailsAPI,
  getPermissionsListAPI, createWorkspaceRoleAPI,
  getWorkspaceRoleDetailsAPI, editWorkspaceRoleAPI,
  removeWorkspaceRoleAPI,
  transferWorkspaceAPI, cancelTransferWorkspaceAPI,
  acceptTransferWorkspaceAPI, rejectTransferWorkspaceAPI,
  getAssignedWorkspaceDetailsAPI,
  getWorkspaceAlertNotificationsListAPI,
  getWorkspaceNotifiableEventsAPI,
  getActiveWorkspaceUsersAPI,
  getWorkspaceAlertNotificationAPI,
  removeWorkspaceAlertNotificationAPI,
  editWorkspaceAlertNotificationAPI,
  createWorkspaceAlertNotificationAPI,
  GET_WORKSPACE_DETAIL_API,
} from 'state/actions/apiFunctions';
import { formatDate4 } from 'common/helpers';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';
import { updateActiveSpace } from 'modules/auth/Auth.actions';

export const WORKSPACE_ACTIONS = {
  CREATE_WORKSPACE_ROLE: 'W_CREATE_WORKSPACE_ROLE',
  CREATE_WORKSPACE_ALERT_NOTIFICATION: 'W_CREATE_ALERT_NOTIFICATION',
  GET_ASSIGNED_WORKSPACE_DETAILS: 'W_GET_ASSIGNED_WORKSPACE_DETAILS',
  GET_ASSIGNED_WORKSPACES: 'W_GET_ASSIGNED_WORKSPACES',
  GET_ORGANIZATION_SETTINGS: 'W_GET_ORGANIZATION_SETTINGS',
  GET_OWNED_WORKSPACES: 'W_GET_OWNED_WORKSPACES',
  GET_PERMISSIONS_LIST: 'W_GET_PERMISSIONS_LIST',
  GET_PREDEFINED_ROLES: 'W_GET_PREDEFINED_ROLES',
  GET_WORKSPACE_ROLES: 'W_GET_WORKSPACE_ROLES',
  GET_WORKSPACE_USERS: 'W_GET_WORKSPACE_USERS',
  GET_WORKSPACE_USER_ACTIVITY: 'W_GET_WORKSPACE_USER_ACTIVITY',
  GET_WORKSPACE_USER_DETAILS: 'W_GET_WORKSPACE_USER_DETAILS',
  GET_WORKSPACE_ROLE_DETAILS: 'W_GET_WORKSPACE_ROLE_DETAILS',
  GET_WORKSPACE_ALERT_NOTIFICATIONS_LIST: 'W_GET_ALERT_NOTIFICATIONS_LIST',
  GET_WORKSPACE_ALERT_NOTIFICATION: 'W_GET_ALERT_NOTIFICATION',
  GET_WORKSPACE_NOTIFIABLE_EVENTS: 'W_GET_WORKSPACE_NOTIFIABLE_EVENTS',
  GET_ACTIVE_WORKSPACE_USERS: 'W_GET_ACTIVE_WORKSPACE_USERS',
  UPDATE_WORKSPACE: 'W_UPDATE_WORKSPACE',
  UPDATE_WORKSPACE_ALERT_NOTIFICATION: 'W_UPDATE_ALERT_NOTIFICATION',
  RESET_ASSIGNED_WORKSPACE_DETAILS: 'W_RESET_ASSIGNED_WORKSPACE_DETAILS',
  RESET_WORKSPACE_USER_ACTIVITY: 'W_RESET_WORKSPACE_USER_ACTIVITY',
  RESET_WORKSPACE_USER_DETAILS: 'W_RESET_WORKSPACE_USER_DETAILS',
  RESET_WORKSPACE_ROLE_DETAILS: 'W_RESET_WORKSPACE_ROLE_DETAILS',
  RESET_CURRENT_WORKSPACE_ROLE: 'W_RESET_CURRENT_WORKSPACE_ROLE',
  CLEAR_WORKSPACE_ALERT_NOTIFICATION: 'W_CLEAR_ALERT_NOTIFICATION',
  GET_WORKSPACE_DETAIL: 'GET_WORKSPACE_DETAIL',
};

const getOrganizationSettingsSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_ORGANIZATION_SETTINGS,
  payload,
});

export const getOrganizationSettings = () => (dispatch) => (
  axios.get(getOrganizationSettingsAPI())
    .then((response) => dispatch(getOrganizationSettingsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const updateOrganizationSettings = (data) => (dispatch) => (
  axios.patch(updateOrganizationSettingsAPI(), {
    name: data.name,
    phone_number: data.phone,
    support_access: data.support,
    icon: data.icon,
  })
    .then((response) => dispatch(getOrganizationSettingsSuccess(getResponseData(response))))
    .then(() => dispatch(addNotification(null, 'update-organization-settings-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-organization-settings-error'));
      throw (error);
    })
);

const getOwnedWorkspacesSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_OWNED_WORKSPACES,
  payload,
});

export const getOwnedWorkspaces = (data) => (dispatch) => (
  axios.get(getOwnedWorkspacesAPI(), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getOwnedWorkspacesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getAssignedWorkspacesSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_ASSIGNED_WORKSPACES,
  payload,
});

export const getAssignedWorkspaces = (data) => (dispatch) => (
  axios.get(getAssignedWorkspacesAPI(), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getAssignedWorkspacesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const resetAssignedWorkspaceDetails = (payload) => ({
  type: WORKSPACE_ACTIONS.RESET_ASSIGNED_WORKSPACE_DETAILS,
  payload,
});

const getAssignedWorkspaceDetailsSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_ASSIGNED_WORKSPACE_DETAILS,
  payload,
});

export const getAssignedWorkspaceDetails = (data) => (dispatch) => (
  axios.get(getAssignedWorkspaceDetailsAPI(data.id))
    .then((response) => dispatch(getAssignedWorkspaceDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const createWorkspace = (data) => (dispatch) => (
  axios.post(createWorkspaceAPI(), {
    icon: data.icon,
    import_existing_resources: data.import,
    name: data.name,
  })
    .then(() => dispatch(addNotification(null, 'create-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-workspace-error'));
      throw (error);
    })
);

const updateWorkspaceSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.UPDATE_WORKSPACE,
  payload,
});

export const updateWorkspace = (data) => (dispatch) => (
  axios.patch(updateWorkspaceAPI(data.id), {
    icon: data.icon,
    name: data.name,
  })
    .then((response) => dispatch(updateWorkspaceSuccess(getResponseData(response))))
    .then(() => dispatch(addNotification(null, 'update-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-workspace-error'));
      throw (error);
    })
);

export const deleteWorkspace = (values) => (dispatch) => (
  axios.delete(deleteWorkspaceAPI(values.id), {
    data: { code: values.code },
  })
    .then(() => dispatch(addNotification(null, 'delete-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-workspace-error'));
      throw (error);
    })
);

export const leaveWorkspace = (data) => (dispatch) => (
  axios.delete(leaveWorkspaceAPI(data.id))
    .then(() => dispatch(addNotification(null, 'leave-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'leave-workspace-error'));
      throw (error);
    })
);

export const acceptInvitation = (data) => (dispatch) => (
  axios.patch(acceptWorkspaceAPI(data.id), {})
    .then(() => dispatch(addNotification(null, 'accept-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'accept-workspace-error'));
      throw (error);
    })
);

export const rejectInvitation = (data) => (dispatch) => (
  axios.delete(rejectWorkspaceAPI(data.id))
    .then(() => dispatch(addNotification(null, 'reject-workspace-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'reject-workspace-error'));
      throw (error);
    })
);

const getPredefinedRolesSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_PREDEFINED_ROLES,
  payload,
});

export const getPredefinedRoles = (data) => (dispatch) => (
  axios.get(getPredefinedRolesAPI(data.id))
    .then((response) => dispatch(getPredefinedRolesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getWorkspaceRolesSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_ROLES,
  payload,
});

export const getWorkspaceRoles = (data) => (dispatch) => (
  axios.get(getWorkspaceRolesAPI(data.id))
    .then((response) => dispatch(getWorkspaceRolesSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const inviteUser = (data) => (dispatch) => (
  axios.post(inviteUserAPI(data.wsID), {
    email: data.email,
    role_id: data.role_id,
  })
    .then(() => dispatch(addNotification(null, 'invite-user-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'invite-user-error'));
      throw (error);
    })
);

const getWorkspaceUsersSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_USERS,
  payload,
});

export const getWorkspaceUsers = (data) => (dispatch) => (
  axios.get(getWorkspaceUsersAPI(data.id), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getWorkspaceUsersSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getWorkspaceUserActivitySuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_USER_ACTIVITY,
  payload,
});

export const getWorkspaceUserActivity = (data) => (dispatch, getState) => (
  axios.get(getWorkspaceUserActivityAPI(data.id), {
    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      user_id: data && data.userID !== 'all' ? data.userID : null,
      next: data.currentNumber > 1 ? getState().workspaces.nextActivity : null,
    },
  })
    .then((response) => dispatch(getWorkspaceUserActivitySuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const clearWorkspaceUserActivity = () => ({ type: WORKSPACE_ACTIONS.RESET_WORKSPACE_USER_ACTIVITY });

const getWorkspaceUserDetailsSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_USER_DETAILS,
  payload,
});

export const getWorkspaceUserDetails = (data) => (dispatch) => (
  axios.get(getWorkspaceUserDetailsAPI(data.wsID, data.userID))
    .then((response) => dispatch(getWorkspaceUserDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const resetWorkspaceUserDetails = () => ({
  type: WORKSPACE_ACTIONS.RESET_WORKSPACE_USER_DETAILS,
});

export const editWorkspaceUserDetails = (data) => (dispatch) => (
  axios.patch(editWorkspaceUserDetailsAPI(data.wsID, data.userID), {
    role_id: data.role,
  })
    .then(() => dispatch(addNotification(null, 'update-workspace-user-success')))
    .then(() => dispatch(resetWorkspaceUserDetails()))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-workspace-user-error'));
      throw (error);
    })
);

export const removeWorkspaceUser = (data) => (dispatch) => (
  axios.delete(removeWorkspaceUserAPI(data.wsID, data.userID))
    .then(() => dispatch(addNotification(null, 'delete-user-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-user-error'));
      throw (error);
    })
);

export const getPermissionsListSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_PERMISSIONS_LIST,
  payload,
});

export const getPermissionsList = (data) => (dispatch) => (
  axios.get(getPermissionsListAPI(data.id))
    .then((response) => dispatch(getPermissionsListSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const resetCurrentWorkspaceRole = () => ({
  type: WORKSPACE_ACTIONS.RESET_CURRENT_WORKSPACE_ROLE,
});

const createWorkspaceRoleSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.CREATE_WORKSPACE_ROLE,
  payload,
});

export const createWorkspaceRole = (data) => (dispatch) => axios.post(createWorkspaceRoleAPI(data.wsId), {
  name: data.name,
  permissions: data.permissions,
})
  .then((response) => {
    dispatch(createWorkspaceRoleSuccess(getResponseData(response)));
    dispatch(addNotification(null, 'create-role-success'));
  }).catch((error) => {
    dispatch(addNotification(getErrorDetails(error), 'create-role-error'));
    throw (error);
  });

export const getWorkspaceRoleDetailsSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_ROLE_DETAILS,
  payload,
});

export const getWorkspaceRoleDetails = (data) => (dispatch) => (
  axios.get(getWorkspaceRoleDetailsAPI(data.wsID, data.roleID))
    .then((response) => dispatch(getWorkspaceRoleDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const clearRoleDetails = () => (dispatch) => (
  dispatch(getWorkspaceRoleDetailsSuccess([]))
);

export const resetWorkspaceRoleDetails = () => ({
  type: WORKSPACE_ACTIONS.RESET_WORKSPACE_ROLE_DETAILS,
});

export const editWorkspaceRole = (data) => (dispatch) => (
  axios.patch(editWorkspaceRoleAPI(data.wsId, data.roleID), {
    name: data.name,
    permissions: data.permissions,
  })
    .then(() => dispatch(addNotification(null, 'update-role-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-role-error'));
      throw (error);
    })
);

export const removeWorkspaceRole = (data) => (dispatch) => (
  axios.delete(removeWorkspaceRoleAPI(data.wsID, data.roleID))
    .then(() => dispatch(addNotification(null, 'delete-role-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-role-error'));
      throw (error);
    })
);

const getWorkspaceDetailSuccess = (data) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_DETAIL,
  payload: data,
});

export const getWorkspaceDetail = (data, shouldSetActiveWorkspace = false) => (dispatch) => (
  axios.get(GET_WORKSPACE_DETAIL_API(data.wsID), {
    params: {
      organization_id: data.orgID,
      workspace_id: data.wsID,
    },
  })
    .then((response) => {
      dispatch(getWorkspaceDetailSuccess(getResponseData(response)));

      if (shouldSetActiveWorkspace) {
        const { data: workspace } = response.data || {};
        dispatch(updateActiveSpace({
          organization: workspace?.organization,
          workspace,
        }));
      }
    })
    .catch((error) => {
      throw (error);
    })
);

export const transferWorkspace = (data) => (dispatch) => (
  axios.post(transferWorkspaceAPI(data.wsID), {
    email: data.email,
    transfer_with_users: data.withUsers,
  })
    .then(() => dispatch(addNotification(null, 'start-workspace-transfer-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'start-workspace-transfer-error'));
      throw (error);
    })
);

export const cancelTransferWorkspace = (data) => (dispatch) => (
  axios.post(cancelTransferWorkspaceAPI(data.wsID), null)
    .then(() => dispatch(addNotification(null, 'cancel-workspace-transfer-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'cancel-workspace-transfer-error'));
      throw (error);
    })
);

export const acceptTransferWorkspace = (data) => (dispatch) => (
  axios.post(acceptTransferWorkspaceAPI(data.wsID), null)
    .then(() => dispatch(addNotification(null, 'accept-workspace-transfer-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'accept-workspace-transfer-error'));
      throw (error);
    })
);

export const rejectTransferWorkspace = (data) => (dispatch) => (
  axios.post(rejectTransferWorkspaceAPI(data.wsID), null)
    .then(() => dispatch(addNotification(null, 'reject-workspace-transfer-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'reject-workspace-transfer-error'));
      throw (error);
    })
);

const getWorkspaceAlertNotificationsListSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_ALERT_NOTIFICATIONS_LIST,
  payload,
});

export const getWorkspaceAlertNotificationsList = (data) => (dispatch) => (
  axios.get(getWorkspaceAlertNotificationsListAPI(data.wsID), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      'sort_by[]': data.sortBy,
      'desc[]': data.sortOrder !== 2,
      user_id: data.userId,
      notifiable_event_id: data.notifiableEventId,
    },
  })
    .then((response) => dispatch(getWorkspaceAlertNotificationsListSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const clearWorkspaceAlertNotification = () => ({
  type: WORKSPACE_ACTIONS.CLEAR_WORKSPACE_ALERT_NOTIFICATION,
});

const getWorkspaceAlertNotificationSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_ALERT_NOTIFICATION,
  payload,
});

export const getWorkspaceAlertNotification = (data) => (dispatch) => (
  axios.get(getWorkspaceAlertNotificationAPI(data.wsID, data.alertID))
    .then((response) => dispatch(getWorkspaceAlertNotificationSuccess(getResponseData(response))))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'get-workspace-alert-error'));
      throw (error);
    })
);

export const createWorkspaceAlertNotification = (data) => (dispatch) => (
  axios.post(createWorkspaceAlertNotificationAPI(data.wsID), data.body)
    .then(() => dispatch(addNotification(null, 'create-workspace-alert-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-workspace-alert-error'));
      throw (error);
    })
);

export const updateWorkspaceAlertNotification = (data) => (dispatch) => (
  axios.patch(editWorkspaceAlertNotificationAPI(data.wsID, data.alertID), data.body)
    .then(() => dispatch(addNotification(null, 'update-workspace-alert-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-workspace-alert-error'));
      throw (error);
    })
);

export const deleteWorkspaceAlertNotification = (data) => (dispatch) => (
  axios.delete(removeWorkspaceAlertNotificationAPI(data.wsID, data.alertID))
    .then(() => dispatch(addNotification(null, 'delete-workspace-alert-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-workspace-alert-error'));
      throw (error);
    })
);

const getWorkspaceNotifiableEventsSuccess = (payload) => ({
  type: WORKSPACE_ACTIONS.GET_WORKSPACE_NOTIFIABLE_EVENTS,
  payload,
});

export const getWorkspaceNotifiableEvents = (data) => (dispatch) => (
  axios.get(getWorkspaceNotifiableEventsAPI(data.wsID))
    .then((response) => dispatch(getWorkspaceNotifiableEventsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

export const getActiveWorkspaceUsers = (data) => () => (
  axios.get(getActiveWorkspaceUsersAPI(data.wsID), {
    params: {
      term: data.term,
    },
  })
    .catch((error) => {
      throw (error);
    })
);
