import { JOB_MONITOR_ACTIONS } from './ClonerMonitor.actions';

export default (state = null, action) => {
  switch (action.type) {
    case JOB_MONITOR_ACTIONS.JOB_SUMMARY_REQUEST:
      return {
        ...state,
        jobSummaryData: action.payload.data,
      };
    case JOB_MONITOR_ACTIONS.JOB_DATE_HISTOGRAM_REQUEST: {
      const { data } = action.payload;
      const DumyData = { request_count: [{ value: '0', date: Date.now() }], trasfer_size: [{ value: '0', date: Date.now() }] };

      return {
        ...state,
        jobDateHistogramData: data.request_count.length === 0 ? DumyData : data,
      };
    }
    default:
      return state;
  }
};
