import axios from 'axios';

import {
  addCreditCardAPI, deleteCreditCardAPI,
  getCreditCardListAPI, updateCreditCardAPI,
  addBalanceAPI, getBillingHistoryAPI,
  getTaxInformationAPI, getTransactionDetailsAPI,
  getBillingSettingsAPI, updateBillingSettingsAPI,
  getBalanceInformationAPI, getAutoPaySettingsAPI,
  updateAutoPaySettingsAPI, enableAutoPaymentAPI,
  disableAutoPaymentAPI, getBillingPlansAPI,
  purchasePlanAPI, getCostExplorerAPI,
  getSubscribedPlansAPI, activateLicenseAPI,
  revokeLicenseAPI, getBillingServicesAPI,
} from 'state/actions/apiFunctions';
import { addNotification } from 'common/redux/common.actions';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { errorHandler } from 'common/utils/response.handler';
import { SUBSCRIPTION_ACTIONS } from './pages/main/Billing.main.config';

export const BILLING_ACTIONS = {
  ADD_BALANCE: 'B_ADD_BALANCE',
  ADD_CREDIT_CARD: 'B_ADD_CREDIT_CARD',
  DELETE_CREDIT_CARD: 'B_DELETE_CREDIT_CARD',
  GET_BALANCE_INFORMATION: 'B_GET_BALANCE_INFORMATION',
  GET_BILLING_HISTORY: 'B_GET_BILLING_HISTORY',
  GET_BILLING_SETTINGS: 'B_GET_BILLING_SETTINGS',
  GET_CREDIT_CARD_DETAILS: 'B_GET_CREDIT_CARD_DETAILS',
  GET_CREDIT_CARD_LIST: 'B_GET_CREDIT_CARD_LIST',
  GET_TAX_INFORMATION: 'B_GET_TAX_INFORMATION',
  GET_TRANSACTION_DETAILS: 'B_GET_TRANSACTION_DETAILS',
  LICENSE_ACTIVATE: 'B_ACTIVATE_LICENSE_KEY',
  LICENSE_CLEAR: 'B_CLEAR_LICENSE_KEY',
  LICENSE_REVOKE: 'B_REVOKE_LICENSE_KEY',
  UPDATE_BILLING_SETTINGS: 'B_UPDATE_BILLING_SETTINGS',
  UPDATE_CREDIT_CARD: 'B_UPDATE_CREDIT_CARD',
  UPDATE_TAX_INFORMATION: 'B_UPDATE_TAX_INFORMATION',
  GET_AUTO_PAY_SETTINGS: 'GET_AUTO_PAY_SETTINGS',
  UPDATE_AUTO_PAY_SETTINGS: 'UPDATE_AUTO_PAY_SETTINGS',
  ENABLE_AUTO_PAY: 'ENABLE_AUTO_PAY',
  DISABLE_AUTO_PAYMENT: 'DISABLE_AUTO_PAYMENT',
  GET_BILLING_SERVICES: 'GET_BILLING_SERVICES',
  /* Plans */
  GET_BILLING_PLANS: 'B_GET_BILLING_PLANS',
  GET_COST_EXPLORER: 'B_GET_COST_EXPLORER',
  GET_SUBSCRIBED_PLANS: 'B_GET_SUBSCRIBED_PLANS',
  PURCHASE_PLAN: 'B_PURCHASE_PLAN',

  SUBSCRIBE_CART_DETAIL: 'SUBSCRIBE_CART_DETAIL',
  SUBSCRIBE_PLAN: 'SUBSCRIBE_PLAN',
  SET_SELECTED_PLAN: 'SET_SELECTED_PLAN',
  CREATE_FUTURE_ACTIONS: 'CREATE_FUTURE_ACTIONS',
  FUTURE_ACTION_LIST: 'FUTURE_ACTION_LIST',
  SUBSCRIPTION_LIST: 'SUBSCRIPTION_LIST',
  FETCH_INVOICE_LIST: 'FETCH_INVOICE_LIST',
};

/* Add Balance */

const addBalanceSuccess = (data) => ({
  type: BILLING_ACTIONS.ADD_BALANCE,
  payload: data,
});

export const addBalance = (data) => (dispatch) => (
  axios.post(addBalanceAPI(), {
    amount: data.amount,
    transaction_token: data.transaction_token,
    ...(
      data.creditCard
        ? {
          credit_card: {
            holder_name: data.creditCard.holderName,
            card_number: data.creditCard.cardNumber,
            expire_month: data.creditCard.expireMonth,
            expire_year: data.creditCard.expireYear,
            cvv: data.creditCard.cvv,
            store: data.creditCard.storeOption,
          },
        } : {
          credit_card_id: data.creditCardID,
        }
    ),
  })
    .then((response) => {
      dispatch(addNotification(null, 'add-balance-success'));
      dispatch(addBalanceSuccess(getResponseData(response)));
      return true;
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'add-balance-error'));
      throw (error);
    })
);

/* Add Credit Card */

const addCreditCardSuccess = (data) => ({
  type: BILLING_ACTIONS.ADD_CREDIT_CARD,
  payload: data,
});

export const addCreditCard = (data) => (dispatch) => (
  axios.post(addCreditCardAPI(), {
    card_number: data.card_number,
    expire_month: data.expire_month,
    expire_year: data.expire_year,
    holder_name: data.holder_name,
    is_default: data.is_default || false,
  })
    .then((response) => {
      dispatch(addNotification(null, 'add-credit-card-success'));
      dispatch(addCreditCardSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'add-credit-card-error');
      throw (error);
    })
);

/* Delete Credit Card */

const deleteCreditCardSuccess = (data) => ({
  type: BILLING_ACTIONS.DELETE_CREDIT_CARD,
  payload: data,
});

export const deleteCreditCard = (cardID) => (dispatch) => (
  axios.delete(`${deleteCreditCardAPI(cardID)}`)
    .then((response) => {
      dispatch(addNotification(null, 'delete-credit-card-success'));
      dispatch(deleteCreditCardSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-credit-card-error'));
    })
);

/* Get Billing History */

const getBillingHistorySuccess = (data) => ({
  type: BILLING_ACTIONS.GET_BILLING_HISTORY,
  payload: data,
});

export const getBillingHistory = (page) => (dispatch) => (
  axios.get(`${getBillingHistoryAPI()}`, {
    ...(
      page
        ? {
          params: {
            page: page.currentPageNumber,
            per_page: page.pageSize,
            'sort_by[]': page.sortBy || 'updated_at',
            'sort_desc[]': page.sortOrder !== 2,
          },
        } : null
    ),
  })
    .then((response) => {
      dispatch(getBillingHistorySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Transaction Details */

export const getTransactionDetailsSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_TRANSACTION_DETAILS,
  payload: data,
});

export const getTransactionDetails = (data) => (dispatch) => (
  axios.get(getTransactionDetailsAPI(data.transactionID), {

  })
    .then((response) => dispatch(getTransactionDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

/* Get Credit Card List */

const getCreditCardListSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_CREDIT_CARD_LIST,
  payload: data,
});

export const getCreditCardList = () => (dispatch) => (
  axios.get(`${getCreditCardListAPI()}`, {

  })
    .then((response) => {
      dispatch(getCreditCardListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Update Credit Card */

const updateCreditCardSuccess = (data) => ({
  type: BILLING_ACTIONS.UPDATE_CREDIT_CARD,
  payload: data,
});

export const updateCreditCard = (data) => (dispatch) => (
  axios.patch(`${updateCreditCardAPI(data.cardID)}`, {
    is_default: data.isDefault,
  })
    .then((response) => {
      dispatch(addNotification(null, 'make-credit-card-default-success'));
      dispatch(updateCreditCardSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'make-credit-card-default-error'));
      throw (error);
    })
);

// Get Tax Information

export const updateTaxInformation = (data) => ({
  type: BILLING_ACTIONS.UPDATE_TAX_INFORMATION,
  payload: data,
});

export const getTaxInformationSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_TAX_INFORMATION,
  payload: data,
});

export const getTaxInformation = (data) => (dispatch) => (
  axios.post(getTaxInformationAPI(), {
    amount: data.amount,
    ...(
      data.creditCard
        ? {
          credit_card: {
            holder_name: data.creditCard.holderName,
            card_number: data.creditCard.cardNumber,
            expire_month: data.creditCard.expireMonth,
            expire_year: data.creditCard.expireYear,
            cvv: data.creditCard.cvv,
            store: data.creditCard.storeOption,
          },
        } : {
          credit_card_id: data.creditCardID,
        }
    ),
  }, {

  })
    .then((response) => dispatch(getTaxInformationSuccess(getResponseData(response))))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'get-tax-information-error'));
      throw (error);
    })
);

// Get Balance Information

export const getBalanceInformationSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_BALANCE_INFORMATION,
  payload: data,
});

export const getBalanceInformation = () => (dispatch) => (
  axios.get(getBalanceInformationAPI(), {

  })
    .then((response) => dispatch(getBalanceInformationSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Get Billing Settings

export const getBillingSettingsSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_BILLING_SETTINGS,
  payload: data,
});

export const getBillingSettings = () => (dispatch) => (
  axios.get(getBillingSettingsAPI(), {

  })
    .then((response) => dispatch(getBillingSettingsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Update Billing Settings

const updateBillingSettingsSuccess = (data) => ({
  type: BILLING_ACTIONS.UPDATE_BILLING_SETTINGS,
  payload: data,
});

export const updateBillingSettings = (data) => (dispatch) => (
  axios.patch(updateBillingSettingsAPI(), {
    billing_alert_quota: data.billingAlertQuota,
    tax_number: data.tax_number,
    ...(
      data.billingAddress ? {
        address: {
          city: data.billingAddress.city,
          country_id: data.billingAddress.country_id,
          full_name: data.billingAddress.full_name,
          line_1: data.billingAddress.line_1,
          line_2: data.billingAddress.line_2,
          state: data.billingAddress.state,
          zip: data.billingAddress.zip,
        },
      } : null
    ),
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-billing-settings-success'));
      dispatch(updateBillingSettingsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-billing-settings-error');
      throw (error);
    })
);

// Get Automatic Payment Settings

export const getAutoPaySettingsSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_AUTO_PAY_SETTINGS,
  payload: data,
});

export const getAutoPaySettings = () => (dispatch) => (
  axios.get(getAutoPaySettingsAPI(), {

  })
    .then((response) => dispatch(getAutoPaySettingsSuccess(getResponseData(response))))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'get-auto-payment-error'));
      throw (error);
    })
);

// Update Automatic Payment Settings

const updateAutoPaySettingsSuccess = (data) => ({
  type: BILLING_ACTIONS.UPDATE_AUTO_PAY_SETTINGS,
  payload: data,
});

export const updateAutoPaySettings = (data) => (dispatch) => (
  axios.patch(updateAutoPaySettingsAPI(), {
    min_balance_threshold: data.balanceValue,
    desired_balance: data.amount,
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-auto-payment-success'));
      dispatch(updateAutoPaySettingsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-auto-payment-error'));
      throw (error);
    })
);

export const enableAutoPaymentSuccess = (data) => ({
  type: BILLING_ACTIONS.ENABLE_AUTO_PAY,
  payload: data,
});

export const enableAutoPayment = () => (dispatch) => (
  axios.post(enableAutoPaymentAPI(), {})
    .then((response) => {
      dispatch(addNotification(null, 'enable-auto-payment-success'));
      dispatch(enableAutoPaymentSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'enable-auto-payment-error'));
      throw (error);
    })
);

export const disableAutoPayment = () => (dispatch) => (
  axios.post(disableAutoPaymentAPI(), {})
    .then(() => dispatch(addNotification(null, 'disable-auto-payment-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'disable-auto-payment-error'));
      throw (error);
    })
);

// Plans

const getBillingServicesSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_BILLING_SERVICES,
  payload: data,
});

export const getBillingServices = () => (dispatch) => (
  axios.get(getBillingServicesAPI())
    .then((response) => dispatch(getBillingServicesSuccess(getResponseData(response))))
    .catch((error) => {
      addNotification(getErrorDetails(error), 'get-billing-services-error');
      throw error;
    })
);

const getBillingPlansSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_BILLING_PLANS,
  payload: data,
});

export const getBillingPlans = (serviceID) => (dispatch) => (
  axios.get(getBillingPlansAPI(serviceID))
    .then((response) => dispatch(getBillingPlansSuccess(getResponseData(response))))
    .catch((error) => {
      addNotification(getErrorDetails(error), 'get-billing-plans-error');
      throw error;
    })
);

const purchasePlanSuccess = (data) => ({
  type: BILLING_ACTIONS.PURCHASE_PLAN,
  payload: data,
});

export const purchasePlan = (data) => (dispatch) => (
  axios.patch(purchasePlanAPI(data.action, data.serviceID, data.planID), {})
    .then((response) => {
      dispatch(addNotification(null, 'purchase-plan-success'));
      dispatch(purchasePlanSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'purchase-plan-error'));
      throw (error);
    })
);

const getCostExplorerSuccess = (data) => ({
  type: BILLING_ACTIONS.GET_COST_EXPLORER,
  payload: data,
});

export const getCostExplorer = () => (dispatch) => (
  axios.get(`${getCostExplorerAPI()}`, {

  })
    .then((response) => dispatch(getCostExplorerSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getSubscribedPlansSuccess = (payload) => ({
  type: BILLING_ACTIONS.GET_SUBSCRIBED_PLANS,
  payload,
});

export const getSubscribedPlans = () => (dispatch) => (
  axios.get(getSubscribedPlansAPI(), {

  })
    .then((response) => dispatch(getSubscribedPlansSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const activateLicenseSuccess = (payload) => ({
  type: BILLING_ACTIONS.LICENSE_ACTIVATE,
  payload,
});

export const activateLicense = (data) => (dispatch) => (
  axios.patch(activateLicenseAPI(data.serviceID, data.planID), {}, {

  })
    .then((response) => {
      dispatch(addNotification(null, 'plan-license-activate-success'));
      dispatch(activateLicenseSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'plan-license-activate-error'));
      throw (error);
    })
);

const revokeLicenseSuccess = (payload) => ({
  type: BILLING_ACTIONS.LICENSE_REVOKE,
  payload,
});

export const revokeLicense = (data) => (dispatch) => (
  axios.patch(revokeLicenseAPI(data.serviceID, data.planID), {}, {

  })
    .then((response) => {
      dispatch(addNotification(null, 'plan-license-revoke-success'));
      dispatch(revokeLicenseSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'plan-license-revoke-error'));
      throw (error);
    })
);

export const clearLicenseKey = () => ({
  type: BILLING_ACTIONS.LICENSE_CLEAR,
});

export const subscribeCartDetailSuccess = (data) => ({
  type: BILLING_ACTIONS.SUBSCRIBE_CART_DETAIL,
  payload: data,
});

export const subscribeCartDetail = (serviceID, planID, type, payload) => (dispatch) => (
  axios.post(`/client/billing/services/${serviceID}/plans/${planID}/${type}/cart`, payload)
    .then((response) => {
      dispatch(subscribeCartDetailSuccess(getResponseData(response)));
    })
    .catch((error) => {
      const message = 'Oops! Something went wrong with your cart. Please try again.';
      dispatch(addNotification({ message }, 'subscribe-cart-detail-error'));
      throw (error);
    })
);

export const subscribePlanSuccess = (data) => ({
  type: BILLING_ACTIONS.SUBSCRIBE_PLAN,
  payload: data,
});

export const subscribePlan = (serviceID, planID, type, payload) => (dispatch) => (
  axios.post(`/client/billing/services/${serviceID}/plans/${planID}/${type}`, payload)
    .then((response) => {
      dispatch(subscribePlanSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'subscribe-cart-plan-error'));
      throw (error);
    })
);

export const subscriptionPastDuePaymentCart = (subscriptionID, payload) => (dispatch) => (
  axios.post(`/client/billing/subscriptions/${subscriptionID}/pay-past-due/cart`, payload)
    .then((response) => {
      dispatch(subscribeCartDetailSuccess(getResponseData(response)));
    })
    .catch((error) => {
      const message = 'Oops! Something went wrong with your cart. Please try again.';
      dispatch(addNotification({ message }, 'subscribe-cart-detail-error'));
      throw (error);
    })
);

export const subscriptionPastDuePayment = (subscriptionID, payload) => (dispatch) => (
  axios.post(`/client/billing/subscriptions/${subscriptionID}/pay-past-due`, payload)
    .then((response) => {
      dispatch(subscribePlanSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'subscribe-cart-plan-error'));
      throw (error);
    })
);

export const setSelectedPlan = (plan) => (dispatch) => (
  dispatch({
    type: BILLING_ACTIONS.SET_SELECTED_PLAN,
    payload: plan,
  })
);

export const createFutureActionsSuccess = (data) => ({
  type: BILLING_ACTIONS.CREATE_FUTURE_ACTIONS,
  payload: data,
});

export const createFutureAction = (payload) => (dispatch) => (
  axios.post('/client/billing/orders', payload)
    .then((response) => {
      dispatch(addNotification(null, `${SUBSCRIPTION_ACTIONS[0] === payload.type ? 'plan-downgraded-success' : 'plan-canceled-success'}`));
      dispatch(createFutureActionsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'future-action-create-error'));
      throw (error);
    })
);

export const deleteFutureAction = (id) => (dispatch) => (
  axios.delete(`/client/billing/orders/${id}`).then(() => {
    dispatch(addNotification(null, 'future-action-delete-success'));
  })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'future-action-delete-error'));
      throw (error);
    })
);

export const fetchFutureActionSuccess = (data) => ({
  type: BILLING_ACTIONS.FUTURE_ACTION_LIST,
  payload: data,
});

export const fetchFutureActions = (serviceID) => (dispatch) => {
  const url = serviceID
    ? `/client/billing/orders?service_id=${serviceID}`
    : '/client/billing/orders';

  return axios.get(url)
    .then((response) => {
      dispatch(fetchFutureActionSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'future-action-fetch-error'));
      throw error;
    });
};

export const subscriptionsSuccess = (data) => ({
  type: BILLING_ACTIONS.SUBSCRIPTION_LIST,
  payload: data,
});

export const fetchSubscriptions = () => (dispatch) => (
  axios.get('/client/billing/subscriptions')
    .then((response) => {
      dispatch(subscriptionsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const fetchInvoicesSuccess = (data) => ({
  type: BILLING_ACTIONS.FETCH_INVOICE_LIST,
  payload: data,
});

export const fetchInvoices = (data) => (dispatch) => (
  axios.post('/client/billing/invoices/filter', data)
    .then((response) => {
      dispatch(fetchInvoicesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);
