import { LOGS_ACTIONS } from './Logs.actions';

export default (state = null, action) => {
  switch (action.type) {
    case LOGS_ACTIONS.GET_LOG_LIST:
      return {
        ...state,
        logList: action.payload.data,
        totalPageNumberForLogList: action.payload.meta && action.payload.meta.last_page,
      };
    case LOGS_ACTIONS.GET_RAW_LOG_LIST:
      return {
        ...state,
        rawLogs: action.payload.data,
        totalPageNumberForRawLogs: action.payload.meta && action.payload.meta.last_page,
      };
    case LOGS_ACTIONS.GET_RAW_LOG_DETAILS:
      return {
        ...state,
        rawLogsDetails: action.payload.data,
        totalPageNumberForRawLogsDetails: action.payload.meta && action.payload.meta.last_page,
      };
    default:
      return state;
  }
};
