import { KEYS_ACTIONS } from './Credentials.actions';

export default (state = null, action) => {
  switch (action.type) {
    case KEYS_ACTIONS.GET_STORAGE_STATUS:
      return {
        ...state,
        isActive: action.payload.data.is_active,
      };
    case KEYS_ACTIONS.ACTIVATE_STORAGE:
      return {
        ...state,
        isActive: action.payload.data.is_active,
      };
    case KEYS_ACTIONS.GET_KEY_LIST:
      return {
        ...state,
        keyList: action.payload.data,
        totalPageNumberForKeyList: action.payload.meta && action.payload.meta.last_page,
      };
    default:
      return state;
  }
};
