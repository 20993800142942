import axios from 'axios';
import { getResponseData } from 'state/actions/apiHelpers';
import {
  getNetworkSummaryAPI,
  getNetworkByDateAPI,
} from 'state/actions/apiFunctions';

export const BUCKET_DASHBOARD_ACTIONS = {
  GET_NETWORK_SUMMARY_DATA: 'GET_NETWORK_SUMMARY_DATA',
  GET_NETWORK_BYDATE_DATA: 'GET_NETWORK_BYDATE_DATA',
};

export const getNetworkSummaryDataSuccess = (data) => ({
  type: BUCKET_DASHBOARD_ACTIONS.GET_NETWORK_SUMMARY_DATA,
  payload: data,
});

export const getNetworkSummary = (data) => (dispatch) => (
  axios.get(getNetworkSummaryAPI(), {

    params: {
      bucket: data && data.bucket === 'all' ? null : data.bucket,
      date: data && data.interval === 'default' ? null : data.date,
      region: data && data.region === 'all' ? null : data.region,
    },
  })
    .then((response) => {
      dispatch(getNetworkSummaryDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const getNetworkByDateDataSuccess = (data) => ({
  type: BUCKET_DASHBOARD_ACTIONS.GET_NETWORK_BYDATE_DATA,
  payload: data,
});

export const getNetworkByDate = (data) => (dispatch) => (
  axios.get(getNetworkByDateAPI(), {

    params: {
      bucket: data && data.bucket === 'all' ? null : data.bucket,
      date: data && data.interval === 'default' ? null : data.date,
      region: data && data.region === 'all' ? null : data.region,
    },
  })
    .then((response) => {
      dispatch(getNetworkByDateDataSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);
