import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  removeNotification,
} from 'common/redux/common.actions';
import SuccessNotification from './SuccessNotification.component';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
    removeNotification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessNotification);
