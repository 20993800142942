/* eslint-disable react/prop-types */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import { Button } from 'crispy-ui';
import Notification from 'components/notifications/base/Notification';
import NotificationContent from 'components/notifications/base/NotificationContent';
import NotificationHead from 'components/notifications/base/NotificationHead';
import NotificationIcon from 'components/notifications/base/NotificationIcon';
import NotificationButton from 'components/notifications/base/NotificationButton';

class VerifyMailSuccess extends Component {
  state = {
    isDialogOpen: true,
  }

  getVerifiedEmail = () => {
    const queryParams = new URLSearchParams(this.props.location?.search);
    return queryParams?.get('email');
  }

  getRedirectText() {
    const { credentials } = this.props;
    const verifiedEmail = this.getVerifiedEmail();

    if (!credentials || credentials.email !== verifiedEmail) {
      return 'sign in';
    }

    return 'dashboard';
  }

  isNotCurrentLoggedUser = () => this.props.credentials
  && this.props.credentials?.email !== this.getVerifiedEmail();

  redirectToLoginPage = () => {
    this.props.removeNotification(this.props.id);

    if (this.isNotCurrentLoggedUser()) {
      this.props.history.replace('/logout');
      return;
    }

    this.props.history.push('/');
  }

  render() {
    return (
      <Dialog
        className="m-reset-password-success-dialog"
        isOpen={this.state.isDialogOpen}
      >
        <Notification>
          <NotificationIcon icon="check_circle" intent="success" />
          <NotificationHead>Email is verified!</NotificationHead>
          <NotificationContent>Your email is successfully verified! Now you can sign in to your account.</NotificationContent>
          <NotificationButton>
            <Button className="btn--success m-notification-button" onClick={this.redirectToLoginPage} text={`Return to ${this.getRedirectText()}`} />
          </NotificationButton>
        </Notification>
      </Dialog>
    );
  }
}

VerifyMailSuccess.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.number.isRequired,
  credentials: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
};

VerifyMailSuccess.defaultProps = {
  credentials: null,

};

export default withRouter(VerifyMailSuccess);
