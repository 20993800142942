/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-properties */
/* eslint-disable camelcase */
import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';

export const appendClasses = (baseClass, classesToAppend) => {
  if (classesToAppend) {
    return [baseClass, classesToAppend].join(' ');
  }
  return baseClass;
};

/**
 *
 * @param {*} errors
 * @param {*} _key
 * @returns
 */
export const getValidationErrorMessage = (errors, _key) => {
  if (errors) {
    const error = Object.entries(errors).find(([key]) => key === _key);
    if (error) {
      return error[1];
    }
  }
  return null;
};

export const appendIntent = (baseClass, intentToAppend) => {
  if (intentToAppend) {
    return [baseClass, intentToAppend].join(' ');
  }
  return baseClass;
};

/**
 * Returns the formatted version of a date (e.g. 25 Jun 2019 ... -> 25.06.2020).
 * @param {*} date Date object to be formatted
 */
export const formatDate = (dateAsString, isUTC) => {
  let date = new Date(dateAsString);
  if (isUTC) {
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  return (
    `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${`${date.getFullYear()}`}`
  );
};

/**
 * Returns the formatted version of a date (e.g. 25 Jun 2019 ... -> 25.06.2020 08:20 PM).
 * @param {*} date Date object to be formatted
 */
export const formatDate2 = (dateAsString, isUTC) => {
  let date = new Date(dateAsString);
  if (isUTC) {
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  return (
    `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(-2)}.${date.getFullYear()} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}`
  );
};

export const formatDate3 = (dateAsDateInstance) => {
  if (dateAsDateInstance) {
    return (
      `${`0${dateAsDateInstance.getDate()}`.slice(-2)}.${`0${dateAsDateInstance.getMonth() + 1}`.slice(-2)}.${`${dateAsDateInstance.getFullYear()}`}`
    );
  }
  return '-';
};

/**
 * Returns the formatted version of a date (e.g. 25 Jun 2019 ... -> 2020-06-25).
 * @param {*} dateAsString Date object to be formatted
 * @param {boolean} isUTC Boolean to decide if the given data is UTC
 */
export const formatDate4 = (dateAsString, isUTC) => {
  let date = new Date(dateAsString);
  if (isUTC) {
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
};

export const getTickConfiguration = (interval, rangeStart, rangeEnd) => {
  if (interval && rangeStart && rangeEnd) {
    const startDate = new Date(rangeStart);
    const endDate = new Date(rangeEnd);
    let format = null;
    let formatDateFunction = null;
    let tickIncrement = null;
    switch (interval) {
      case '12h':
        format = 'HH:mm';
        formatDateFunction = formatDate2;
        tickIncrement = 1000 * 60 * 60;
        break;
      case 'today':
      case 'yesterday':
      case '24h':
        format = 'HH:mm';
        formatDateFunction = formatDate2;
        tickIncrement = 1000 * 60 * 60 * 2;
        break;
      case '7d':
      case 'thisWeek':
      case 'lastWeek':
        format = 'MMM dd';
        formatDateFunction = formatDate;
        tickIncrement = 1000 * 60 * 60 * 24;
        break;
      case '30d':
      case 'thisMonth':
      case 'lastMonth':
        format = 'MMM dd';
        formatDateFunction = formatDate;
        tickIncrement = 1000 * 60 * 60 * 24 * 3;
        break;
      default:
        if (endDate - startDate <= (1000 * 60 * 60 * 24)) {
          format = 'HH:mm';
          formatDateFunction = formatDate2;
          tickIncrement = 1000 * 60 * 60 * 2;
        } else if (endDate - startDate <= (1000 * 60 * 60 * 24 * 30)) {
          format = 'MMM dd';
          formatDateFunction = formatDate;
          tickIncrement = 1000 * 60 * 60 * 24 * 3;
        } else {
          format = 'MMM dd';
          formatDateFunction = formatDate;
          tickIncrement = 1000 * 60 * 60 * 24 * 14;
        }
        break;
    }
    const ticks = [];
    for (let i = startDate.getTime(); i < endDate.getTime(); i += tickIncrement) {
      let date = new Date(i);
      date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      ticks.push(date);
    }
    ticks.push(new Date(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth(),
      endDate.getUTCDate(),
      endDate.getUTCHours(),
      endDate.getUTCMinutes(),
      endDate.getUTCSeconds(),
    ));
    return {
      format,
      formatDateFunction,
      ticks,
    };
  }
  return null;
};

export const paginateArray = (array, pageNumber, pageSize) => {
  const arrayCopy = [...array];
  return arrayCopy.splice((pageNumber - 1) * pageSize, pageSize);
};

/**
 * getSecondsFromNowToTimestamp(): calculates the second between now and given
 * timestamp. It is mostly used due to react-intl package's desired date format
 * @param {*} timestamp: timestamp to calculate difference
 */
export const getSecondsFromNowToTimeStamp = (timestamp) => {
  const now = new Date(new Date(Date.now()).toISOString());
  const date = new Date(timestamp);
  return (date.getTime() - now.getTime()) / 1000;
};

export const chunkArray = (array, size) => {
  const result = [];
  const arrayCopy = [...array];
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, size));
  }
  return result;
};

/**
 * The 2 following functions check the value against null and undefined.
 * They return false if the value is not valid (e.g: either null or undefined) or true if it's valid.
 * The second function is to help for checks on multiple values, most commonly used checking for multiple depths
 *
 * @param {*} value Value to be checked for validity
 * @param {Array} checkForExtraValues Array of values other than null and undefined to be tested
 *
 * OR
 *
 * @param {Array} values Value array to be checked for validity. These values are checked in order.
 * @param {Array} checkForExtraValues Array of values other than null and undefined to be tested
 */
export const checkIfValueIsValid = (value, checkForExtraValues = []) => {
  if (checkForExtraValues !== [] && value !== null && value !== undefined) {
    for (let index = 0; index < checkForExtraValues.length; index += 1) {
      if (_.isEqual(value, checkForExtraValues[index])) return false;
    }
    return true;
  }
  return value !== null && value !== undefined;
};

export const checkIfValuesAreValid = (values, checkForExtraValues = []) => {
  for (let index = 0; index < values.length; index += 1) {
    if (!checkIfValueIsValid(values[index], checkForExtraValues)) return false;
  }
  return true;
};

export const parseCommaSeparatedNumber = (numberString) => parseFloat(numberString.split(',').join(''));

/**
 * This function copies the given text to the clipboard, and ignores if it fails.
 * It uses the newer Clipboard API, so it has potential to fail in older browsers.
 * So while using an input or textarea, older execCommand should be used.
 * This one should be used if we want to copy a string directly, and it's not a big deal if it fails.
 *
 * @param {string} text Text to be copied
 */
export const copyTextToClipboard = (text) => navigator.clipboard.writeText(text);

export const checkSpaceParams = ({ orgID, wsID }) => {
  const isValidNumber = (value) => _.isFinite(_.toNumber(value)) && value;
  return isValidNumber(orgID) && isValidNumber(wsID);
};
/**
 * Checks the URL parameters for organization and workspace IDs to see if it's an address in a workspace context
 * If it is, adds the organization and workspace IDs to the beginning of the address
 *
 * @param {object} params URL Parameters. Retrieved from either props.match.params or useParams
 * @param {string} address string for the default address
 * @returns {string} A new address string built from the address
 */
export const constructWorkspaceLink = (params, address) => (checkSpaceParams(params) ? `/${params.orgID}/${params.wsID}${address}` : address);

export const constructOrganizationLink = (params, address) => (`/${params.orgID}${address}`);

export const getSpaceParamsFromPathname = (pathname) => {
  const spaceParams = pathname.split('/');

  return { orgID: spaceParams[1], wsID: spaceParams[2] };
};

/**
 * Removed orgID and wsID from the location, and returns the whole link (pathname+hash+search)
 * This assumes the location has already been checked, and the first two paths are orgID and wsID
 * Because of this assumption, it can be used to remove any other first two paths from the location address
 *
 * @param {object} location Location object retrieved from either props.location or useLocation
 * @returns {string} A new address string built from the location
 */
export const stripWorkspaceFromLocation = (location) => {
  const array = location.pathname.split('/');
  array.splice(1, 2);
  array.splice(2, array.length);
  return `${array.join('/')}${location.hash}${location.search}`;
};

export const bytesToSize = (bytes) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 B';
  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
};

export const humanReadableTransferRate = (size) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1000));
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${['B/s', 'KB/s', 'MB/s', 'GB/s', 'TB/s'][i]}`;
};

export const humanReadableEstimatedTimeRemaining = (estimatedTime) => {
  if (_.isNaN(estimatedTime) || estimatedTime < 0 || estimatedTime === 0 || !_.isFinite(estimatedTime)) {
    return 'calculating...';
  }

  const years = Math.floor(estimatedTime / 31536000);
  const days = Math.floor(estimatedTime / (3600 * 24));
  const hours = Math.floor((estimatedTime % (3600 * 24)) / 3600);
  const minutes = Math.floor((estimatedTime % 3600) / 60);

  let result = '';
  if (years > 0) {
    result += `${days} year${days === 1 ? '' : 's'} `;
  }
  if (days > 0) {
    result += `${days} day${days === 1 ? '' : 's'} `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours === 1 ? '' : 's'} `;
  }
  if (minutes > 0) {
    result += `${minutes === 1 ? 'a minute' : `${minutes} minutes`} `;
  }

  return result.trim() || 'a few seconds';
};

/**
 * Converts and formats the given file size to it's most suitable unit to be shown.
 *
 * @param {Number} size File size in number format
 * @param {String} unit The unit of the given file size. Accepts in bytes or bits up until yotta
 * @param {Boolean} short Whether the final unit should be returned in short format
 * @returns {String} The formatted string
 */
export const formatFileSize = (size, unit, short = true) => {
  const unitLowercase = _.lowerCase(unit);
  const unitList = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte', 'exabyte', 'zettabyte', 'yottabyte'];
  const unitListShort = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
  const sizeShortener = (s, u) => (s < 1024 ? [s, u] : sizeShortener(s / 1024, u + 1));
  let finalValue;
  if (unitLowercase.endsWith('bit')) {
    const prefix = unitLowercase.slice(0, -3);
    if (prefix.length === 1) {
      finalValue = sizeShortener(size / 8, unitListShort.findIndex((ele) => ele.startsWith(prefix)));
    }
    finalValue = sizeShortener(size / 8, unitList.findIndex((ele) => ele.startsWith(prefix)));
  }
  if (unitLowercase.endsWith('byte')) {
    const prefix = unitLowercase.slice(0, -4);
    if (prefix.length === 1) {
      finalValue = sizeShortener(size, unitListShort.findIndex((ele) => ele.startsWith(prefix)));
    }
    finalValue = sizeShortener(size, unitList.findIndex((ele) => ele.startsWith(prefix)));
  }
  if (!finalValue) {
    return null;
  }
  if (short) {
    return `${finalValue[0]} ${_.upperCase(unitListShort[finalValue[1]])}`;
  }
  return `${finalValue[0]} ${_.capitalize(unitList[finalValue[1]])}`;
};

/**
 * This function decides if there should be a redirection to Distributions module
 * It checks for the distribution list length.
 *
 * @param {Array} distList distributionList retrieved from Redux
 */
export const shouldRedirectToDistributions = _.isEmpty;

export const changeQueryParams = (location, newParams) => ({
  pathname: location && location.pathname,
  hash: location && location.hash,
  search: qs.stringify({
    ...(location.search ? qs.parse(location.search.slice(1)) : null),
    ...newParams,
  }),
});

export const getQueryParamsAsObject = (location) => {
  const queryParams = new URLSearchParams(location.search);

  const obj = {};
  queryParams.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

/**
 * Checks localStorage sort parameter. If it's stored, and the column name is valid and sortable, it is returned.
 * If either of the checks fail, the default value is returned.
 *
 * @param {String} storageName The name the sort parameter is stored in localStorage with.
 * @param {Array} columns Table's column list
 * @param {String} defaultValue Default sortBy column name
 * @returns {String} sortBy column name
 */
export const getInitialSortBy = (storageName, columns, defaultValue) => {
  const stored = localStorage.getItem(storageName);
  if (stored && columns.find((ele) => ele.name === stored)?.isSortable) {
    return stored;
  }
  return defaultValue;
};

/**
 * Generates a pseudo-random integer. This is only a wrapper around random from lodash, that only accepts a length argument.
 *
 * @param {Number} length Length of the random integer
 * @returns Number
 */
export const generateRandomInteger = (length = 16) => _.random(10 ** (length - 1), (10 ** length) - 1);

/**
 * Generates a pseudo-random string using base64 of a random number.
 *
 * @param {Number} length Length of the random string. Prefer multiples of 4 to avoid = paddings.
 * @returns String
 */
export const generateRandomString = (length = 16) => btoa(generateRandomInteger(length * (3 / 4)));

const getUrlAfterDotCom = (url) => {
  if (!url) return url;

  const parts = url.split('.com');
  return parts.length > 1 ? parts[1] : url;
};

const getDomainFromUrl = (url) => {
  try {
    const domain = new URL(url).hostname;
    const domainParts = domain.split('.com');

    if (domainParts.length === 0) return '';

    return `${domainParts[0]}.com`;
  } catch (e) {
    return '';
  }
};

const getPathBeforeQuery = (paths) => paths.map((path) => {
  const regex = /^[^?]+/;
  const match = path?.match(regex);
  return match ? match[0] : path;
});

export const isPathIncluded = (path, restrictedPaths = []) => {
  const paths = getPathBeforeQuery(getUrlAfterDotCom(path)?.split('/')?.filter(Boolean));
  const checkPositions = [0, 1, 2];

  if (checkPositions.some((position) => restrictedPaths.includes(paths[position]))) return true;

  const domain = getDomainFromUrl(path);
  return restrictedPaths.some((rp) => Array.isArray(rp) && domain.includes(rp));
};

/**
 *
 * @param {*} type
 * @returns
 */
export const isSupportUser = (type) => type === 'support';

/**
 *
 * @param {*} type
 * @returns
 */
export const isAdminUser = (type) => type === 'admin';

/**
 *
 * @param {*} type
 * @returns
 */
export const getUserOwnOrganization = (credentials) => credentials.organizations.find((orgItem) => orgItem.is_own);

/**
 *
 * @param {*} dateTime
 * @returns
 */
export const toDateString = (dateTime) => (dateTime)?.toISOString().split('T')[0];

/**
 *
 * @param {*} pathname
 * @returns
 */
export const getPathnameWitoutSpace = (pathname) => pathname?.replace(/^.*?\/.*?\/.*?\//, '');

/**
 *
 * @param {*} pathname
 * @returns
 */
export const generateModuleName = (pathname) => {
  const splitPath = getPathnameWitoutSpace(pathname).split('/');

  return splitPath.length === 1
    ? pathname.split('/')[1]
    : getPathnameWitoutSpace(pathname)
      .split('/')
      .slice(0, 2)
      .toString()
      .replace(',', '_') || 'home';
};

/**
 *
 * @param {*} obj
 * @param {*} path
 * @param {*} value
 * @returns
 */
export const setProperty = (obj, path, value) => {
  const [head, ...rest] = path.split('.');

  return {
    ...obj,
    [head]: rest.length
      ? setProperty(obj[head], rest.join('.'), value)
      : value,
  };
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const makeDate = (day, unit) => moment().utc().subtract(day, unit).format();

export const makeShortcutDate = (shortcut) => {
  switch (shortcut) {
    case 'today':
      return [
        moment().utc().startOf('day').toDate(),
        moment([]).utc().format(),
      ];
    case 'yesterday':
      return [
        makeDate(1, 'days'),
        moment([]).utc().format(),
      ];
    case '12h':
      return [
        makeDate(12, 'hours'),
        moment([]).utc().format(),
      ];
    case '24h':
      return [
        makeDate(24, 'hours'),
        moment([]).utc().format(),
      ];
    case '7d':
      return [
        makeDate(7, 'days'),
        moment([]).utc().format(),
      ];
    case 'thisWeek':
      return [
        moment().utc().startOf('isoWeek').format(),
        moment([]).utc().format(),
      ];
    case 'lastWeek':
      return [
        moment().utc().subtract(1, 'weeks').startOf('isoWeek')
          .format(),
        moment().utc().subtract(1, 'weeks').endOf('isoWeek')
          .format(),
      ];
    case '30d':
      return [
        makeDate(30, 'days'),
        moment([]).utc().format(),
      ];
    case 'thisMonth':
      return [
        moment().utc().startOf('month').format(),
        moment([]).utc().format(),
      ];
    case 'lastMonth':
      return [
        moment().utc().subtract(1, 'month').startOf('month')
          .format(),
        moment().utc().subtract(1, 'month').endOf('month')
          .format(),
      ];
    default:
      return [
        makeDate(24, 'hours'),
        moment([]).utc().format(),
      ];
  }
};

export const getUrlParams = (location) => new URLSearchParams(location.search);

export const getSortDataFromUrl = (location) => {
  const data = getUrlParams(location);
  return data.get('sort')?.split(',')?.reduce((current, item) => {
    if (item?.length === 0) {
      return current;
    }
    const isDesc = item.charAt(0) === '-';
    const newItems = { id: isDesc ? item.slice(1) : item, desc: isDesc };
    return [...current, newItems];
  }, []) ?? [];
};

export const prepareSortDataForUrl = (sort) => sort.reduce((prev, current) => `${prev.length === 0 ? '' : `${prev},`}${current.desc ? '-' : ''}${current.id}`, '');

export const encodeQueryAsJson = (jsonData) => Buffer.from(JSON.stringify(jsonData)).toString('base64');

export const decodedQueryAsJson = (query) => JSON.parse(Buffer.from(decodeURIComponent(query), 'base64').toString('utf8'));

export const getSpaceParams = (path) => {
  // eslint-disable-next-line no-unused-vars
  const [skip, orgID, wsID] = path?.split('/') || [];

  return {
    orgID: orgID || null,
    wsID: wsID || null,
  };
};

export const truncateStringToLength = (string, maxLength) => {
  const truncatedString = string.length <= maxLength
    ? string
    : `${string.slice(0, maxLength - 3)}...`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(string);
  };

  return (
    <a onClick={handleCopyClick}>
      {truncatedString}
    </a>
  );
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val);
    return JSON.stringify(res, null, 2);
  } catch {
    return val;
  }
};

export const resolveObjectPath = (path, obj, separator = '.') => (Array.isArray(path) ? path : path.split(separator))
  .reduce((prev, curr) => prev?.[curr], obj);

export const isValidHttpUrl = (url) => /^https?:\/\//.test(url);

export const isNumericOrNumericString = (value) => _.isNumber(value) || (_.isString(value) && /^\d+(\.\d+)?$/.test(value));
