import axios from 'axios';
import {
  getPasswordResetLinkAPI, getVerifyMailAPI,
  getPasswordResetAPI, getRegisterAPI,
  getUserDataAPI, loginAPI,
  logoutAPI, getResendAPI,
  getUpdateMeAPI, getUpdateEmailAPI, getUpdatePhoneNoAPI, getVerifyPhoneNoAPI,
  getUpdatePasswordAPI, updateUserAPIAPI,
  getSupportOrganizationAPI,
  getSupportWorkspaceAPI,
  enableTOTPAPI,
  disableTOTPAPI,
  activateTOTPAPI,
  verifyCodeAPI,
  getNotificationSettingsAPI,
  getUpdateNotificationSettingsAPI,
  getVerifyMethodsAPI,
  getPersonalAccessTokenAPI,
} from 'state/actions/apiFunctions';
import { addNotification } from 'common/redux/common.actions';
import { getErrorDetails, getResponseData } from 'state/actions/apiHelpers';
import { errorHandler } from 'common/utils/response.handler';

export const AUTH_ACTIONS = {
  GET_USER_DATA: 'AUTH_GET_USER_DATA',
  GET_PERMISSIONS: 'AUTH_GET_PERMISSIONS',
  CLEAR_PERMISSIONS: 'CLEAR_PERMISSIONS',
  CLEAR_AUTH: 'CLEAR_AUTH',
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'MERLIN_CLEAR_USER',
  UPDATE_EMAIL_SUCCESS: 'AUTH_UPDATE_EMAIL_SUCCESS',
  UPDATE_PHONE_NO_SUCCESS: 'UPDATE_PHONE_NO_SUCCESS',
  UPDATE_ME_SUCCESS: 'AUTH_UPDATE_ME_SUCCESS',
  UPDATE_PASSWORD_SUCCESS: 'AUTH_UPDATE_PASSWORD_SUCCESS',
  VERIFY_PHONE_NO_SUCCESS: 'VERIFY_PHONE_NO_SUCCESS',
  ENABLE_TOTP: 'AUTH_ENABLE_TOTP',
  DISABLE_TOTP: 'AUTH_DISABLE_TOTP',
  ACTIVATE_TOTP: 'AUTH_ACTIVATE_TOTP',
  VERIFY_CODE: 'AUTH_VERIFY_CODE',
  GET_NOTIFICATION_SETTINGS: 'GET_NOTIFICATION_SETTINGS',
  UPDATE_NOTIFICATION_SETTINGS: 'UPDATE_NOTIFICATION_SETTINGS',
  SET_SUPPORT_ORGANIZATION: 'SET_SUPPORT_ORGANIZATION',
  SET_SUPPORT_WORKSPACE: 'SET_SUPPORT_WORKSPACE',
  UPDATE_ACTIVE_SPACE: 'UPDATE_ACTIVE_SPACE',
  UPDATE_ACTIVE_SPACE_WITH_RECENT: 'UPDATE_ACTIVE_SPACE_WITH_RECENT',
  GET_TOTP_METHODS: 'GET_TOTP_METHODS',
  GET_PERSONAL_ACCESS_TOKENS: 'GET_PERSONAL_ACCESS_TOKENS',
  CREATE_PERSONAL_ACCESS_TOKENS: 'CREATE_PERSONAL_ACCESS_TOKENS',
  DELETE_PERSONAL_ACCESS_TOKENS: 'DELETE_PERSONAL_ACCESS_TOKENS',
};

export const updateActiveSpacePayload = (data) => ({
  type: AUTH_ACTIONS.UPDATE_ACTIVE_SPACE,
  payload: data,
});

export const updateActiveSpace = (data, callback) => (dispatch) => {
  dispatch(updateActiveSpacePayload(data));

  if (callback) {
    callback();
  }
};

export const updateActiveSpaceWithRecent = (callback) => (dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.UPDATE_ACTIVE_SPACE_WITH_RECENT,
  });

  if (callback) {
    callback();
  }
};

const getUserDataSuccess = (data) => ({
  type: AUTH_ACTIONS.GET_USER_DATA,
  payload: data,
});

export const getUserData = () => (dispatch) => (
  axios.get(getUserDataAPI())
    .then((response) => dispatch(getUserDataSuccess(getResponseData(response))))
);

export const clearPermissions = () => ({
  type: AUTH_ACTIONS.CLEAR_PERMISSIONS,
});

export const clearAuth = () => (dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.CLEAR_AUTH,
    payload: null,
  });
};

const loginSuccess = (data) => ({
  type: AUTH_ACTIONS.LOGIN,
  payload: data,
});

export const login = (data) => (dispatch) => (
  axios.post(loginAPI(), {
    email: data.email,
    password: data.password,
    recaptcha_token: data.recaptcha_token,
  })
    .then((response) => dispatch(loginSuccess(getResponseData(response))))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'login-error'));
      throw (error);
    })
);

export const logoutSuccess = (data) => ({
  type: AUTH_ACTIONS.LOGOUT,
  payload: data,
});

export const logout = () => (dispatch) => (
  axios.post(logoutAPI(), null)
    .then((response) => {
      dispatch(logoutSuccess(getResponseData(response)));
    })
);

export const resend = () => (dispatch) => (
  axios.post(getResendAPI(), null)
    .then(() => {
      dispatch(addNotification(null, 'resend-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'resend-error'));
      throw (error);
    })
);
export const updateMeSuccess = (data) => ({
  type: AUTH_ACTIONS.UPDATE_ME_SUCCESS,
  payload: data,
});

export const updateMe = (data) => (dispatch) => (
  axios.patch(getUpdateMeAPI(), {
    address: data.address,
    name: data.name,
    surname: data.surname,
    number: data.number,
    country_code: data.country_code,
    ...(
      data.notifications ? {
        notification_settings: {
          information_emails: data.notifications.notificationEmail,
          promotional_emails: data.notifications.promotionEmail,
        },
      } : null
    ),
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-me-success'));
      dispatch(updateMeSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-me-error'));
      throw (error);
    })
);

export const updateEmailSuccess = (data) => ({
  type: AUTH_ACTIONS.UPDATE_EMAIL_SUCCESS,
  payload: data,
});

export const updateEmail = (data) => (dispatch) => (
  axios.patch(getUpdateEmailAPI(), { ...data })
    .then((response) => {
      dispatch(addNotification(null, 'update-email-success'));
      dispatch(updateEmailSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-email-error');
      throw (error);
    })
);

export const updatePhoneNoSuccess = (data) => ({
  type: AUTH_ACTIONS.UPDATE_PHONE_NO_SUCCESS,
  payload: data,
});

export const updatePhoneNo = (data) => (dispatch) => (
  axios.put(getUpdatePhoneNoAPI(), {
    verify_via: data.method,
    country_code: data.country_code,
    number: data.number,
    password: data.password,
  })
    .then((response) => {
      dispatch(updatePhoneNoSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-phone-no-error');
      throw (error);
    })
);

export const verifyMethodsSuccess = (data) => ({
  type: AUTH_ACTIONS.GET_TOTP_METHODS,
  payload: data,
});

export const verifyMethods = () => (dispatch) => (
  axios.get(getVerifyMethodsAPI())
    .then((response) => {
      dispatch(verifyMethodsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'verify-phone-no-error');
      throw (error);
    })
);

export const verifyPhoneNoSuccess = (data) => ({
  type: AUTH_ACTIONS.VERIFY_PHONE_NO_SUCCESS,
  payload: data,
});

export const verifyPhoneNo = (data) => (dispatch) => (
  axios.patch(getVerifyPhoneNoAPI(), {
    otp: data.otp,
    verify_via: data.verify_via,
  })
    .then((response) => {
      dispatch(addNotification(null, 'verify-phone-no-success'));
      dispatch(verifyPhoneNoSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'verify-phone-no-error');
      throw (error);
    })
);

export const updatePassword = (data) => (dispatch) => (
  axios.patch(getUpdatePasswordAPI(), { ...data })
    .then(() => {
      dispatch(addNotification(null, 'update-password-success'));
      // dispatch(updatePasswordSuccess(getResponseData(response)));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-password-error');
      throw (error);
    })
);

export const updateUserAPI = (actionType) => (dispatch) => {
  let data = {};
  switch (actionType) {
    case 'enable':
      data = {
        is_api_enabled: true,
        refresh_key: false,
      };
      break;
    case 'disable':
      data = {
        is_api_enabled: false,
        refresh_key: false,
      };
      break;
    case 'refresh':
      data = {
        is_api_enabled: true,
        refresh_key: true,
      };
      break;
    default:
      break;
  }

  return (
    axios.patch(updateUserAPIAPI(), data)
      .then(() => {
        dispatch(addNotification(null, `${actionType}-user-api-success`));
      })
      .catch((error) => {
        dispatch(addNotification(getErrorDetails(error), `${actionType}-user-api-error`));
        throw (error);
      })
  );
};

const enableTOTPSuccess = (data) => ({
  type: AUTH_ACTIONS.ENABLE_TOTP,
  payload: data,
});

export const enableTOTP = () => (dispatch) => (
  axios.post(enableTOTPAPI(), {})
    .then((response) => {
      dispatch(enableTOTPSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'enable-totp-error'));
      throw (error);
    })
);

export const disableTOTP = (data) => (dispatch) => (
  axios.post(disableTOTPAPI(), {
    code: data.secret,
  })
    .then(() => {
      dispatch(addNotification(null, 'disable-totp-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'disable-totp-error'));
      throw (error);
    })
);

export const activateTOTP = (data) => (dispatch) => (
  axios.post(activateTOTPAPI(), {
    secret: data.secret,
    code: data.verificationCode,
  })
    .then(() => {
      dispatch(addNotification(null, 'activate-totp-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'activate-totp-error'));
      throw (error);
    })
);

const verifyCodeSuccess = (data) => ({
  type: AUTH_ACTIONS.VERIFY_CODE,
  payload: data,
});

export const verifyCode = (data) => (dispatch) => (
  axios.post(verifyCodeAPI(), {
    secret: data.secret,
    code: data.code,
    recaptcha_token: data.recaptcha_token,
  })
    .then((response) => {
      dispatch(verifyCodeSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'verify-totp-error'));
      throw (error);
    })
);

export const sendPasswordResetLink = (data) => (dispatch) => (
  axios.post(getPasswordResetLinkAPI(), null, {
    params: {
      email: data.email,
      recaptcha_token: data.recaptcha_token,
    },
  })
    .then(() => dispatch(addNotification(null, 'forgot-password-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'forgot-password-error'));
      throw (error);
    })
);

export const verifyMail = (data) => (dispatch) => (
  axios.get(getVerifyMailAPI(), {
    params: {
      email: data.email,
      expires: data.expires,
      id: data.id,
      signature: data.signature,
    },
  })
    .then(() => dispatch(addNotification(null, 'verify-mail-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'verify-mail-error'));
      throw (error);
    })
);

export const changePassword = (data) => (dispatch) => (
  axios.post(getPasswordResetAPI(), {
    email: data.email,
    password: data.password,
    password_confirmation: data.confirmPassword,
    token: data.token,
  })
    .then(() => dispatch(addNotification(null, 'reset-password-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'reset-password-error'));
      throw (error);
    })
);

export const register = (data) => (dispatch) => axios.post(getRegisterAPI(), {
  name: data.name,
  surname: data.surname,
  email: data.email,
  recaptcha_token: data.recaptcha_token,
  referral_code: data.referral_code,
  password: data.password,
  password_confirmation: data.confirmPassword,
  gclid: data.gclid,
  product: data.product,
  ref_url: data.ref_url,
}, {
  params: {
    email: data.email,
    invitation_id: data.invitation_id,
    signature: data.signature,
  },
})
  .then(() => dispatch(addNotification(null, 'register-success')))
  .catch((error) => {
    dispatch(addNotification(getErrorDetails(error), 'register-error'));
    throw (error);
  });

const setSupportOrganizationResponse = (data) => ({
  type: AUTH_ACTIONS.SET_SUPPORT_ORGANIZATION,
  payload: data,
});

export const getSupportOrganizations = (query) => (dispatch) => (
  axios.get(getSupportOrganizationAPI(query)).then((response) => dispatch(setSupportOrganizationResponse(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const setSupportWorkspaceResponse = (data) => ({
  type: AUTH_ACTIONS.SET_SUPPORT_WORKSPACE,
  payload: data,
});

export const getSupportWorkspaceByOrgId = (orgID, query) => (dispatch) => (
  axios.get(getSupportWorkspaceAPI(orgID, query)).then((response) => dispatch(setSupportWorkspaceResponse(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getNotificationSettingsSuccess = (data) => ({
  type: AUTH_ACTIONS.GET_NOTIFICATION_SETTINGS,
  payload: data,
});

export const getNotificationSettings = () => (dispatch) => (
  axios.get(getNotificationSettingsAPI()).then((response) => dispatch(getNotificationSettingsSuccess(getResponseData(response))))
);

const updateNotificationSettingsSuccess = (data) => ({
  type: AUTH_ACTIONS.UPDATE_NOTIFICATION_SETTINGS,
  payload: data,
});

export const updateNotificationSettings = (data) => (dispatch) => (
  axios.patch(getUpdateNotificationSettingsAPI(),
    data)
    .then((response) => {
      dispatch(addNotification(null, 'update-me-success'));
      dispatch(updateNotificationSettingsSuccess(getResponseData(response)));
    }).catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-me-error'));
      throw (error);
    })
);

export const verifyTotp = (data) => (dispatch) => (
  axios.post('/client/user/2fa/otp/verify', data).catch((error) => {
    dispatch(addNotification(getErrorDetails(error), 'totp-error'));
    throw (error);
  })
);

export const resendTotpCode = (data, type = false) => (dispatch) => {
  const enpointInit = '/client/user/2fa/configuration/init';
  const enpointReSend = '/client/user/2fa/otp/new';
  const finalEndpoint = type ? enpointReSend : enpointInit;

  return axios.post(finalEndpoint, data).then(() => {
    dispatch(addNotification(null, 'resend-totp-success'));
  }).catch((error) => {
    dispatch(addNotification(getErrorDetails(error), 'totp-resend-error'));
  });
};

const fetchTotpMethodsSuccess = (data) => ({
  type: AUTH_ACTIONS.GET_TOTP_METHODS,
  payload: data,
});

export const fetchTotpMethods = () => (dispatch) => (
  axios.get('/client/user/2fa/methods').then((response) => {
    dispatch(fetchTotpMethodsSuccess(getResponseData(response)));
  }).catch((error) => {
    throw (error);
  })
);

export const totpConfigurationInit = (payload) => (dispatch) => (
  axios.post('/client/user/2fa/configuration/init', payload)
    .then((response) => getResponseData(response)?.data)
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'totp-init-error'));
      throw (error);
    })
);

export const totpConfigurationComplete = (payload) => (dispatch) => (
  axios.post('/client/user/2fa/configuration/complete', payload)
    .then(() => dispatch(addNotification(null, 'totp-setup-complete')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'totp-complete-error'));
      throw (error);
    })
);

export const totpDisableComplete = (payload) => (dispatch) => (
  axios.post('/client/user/2fa/configuration/disable', payload)
    .then(() => dispatch(addNotification(null, 'totp-disable-complete')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'totp-disable-error'));
      throw (error);
    })
);

const fetchPersonalAccessTokensSuccess = (data) => ({
  type: AUTH_ACTIONS.GET_PERSONAL_ACCESS_TOKENS,
  payload: data,
});

export const fetchPersonalAccessTokens = () => (dispatch) => (
  axios.get(getPersonalAccessTokenAPI()).then((response) => {
    dispatch(fetchPersonalAccessTokensSuccess(getResponseData(response)));
  }).catch((error) => {
    throw (error);
  })
);

const createPersonalAccessTokenSuccess = (data) => ({
  type: AUTH_ACTIONS.CREATE_PERSONAL_ACCESS_TOKENS,
  payload: data,
});

export const createPersonalAccessToken = (data) => (dispatch) => (
  axios.post(getPersonalAccessTokenAPI(), {
    name: data.name,
  })
    .then((response) => {
      const responseData = getResponseData(response);
      dispatch(addNotification(null, 'create-key-success'));
      dispatch(createPersonalAccessTokenSuccess(responseData));
      return response && response.data;
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-key-error'));
      throw (error);
    })
);

const deletePersonalAccessTokenSuccess = (data) => ({
  type: AUTH_ACTIONS.DELETE_PERSONAL_ACCESS_TOKENS,
  payload: data,
});

export const deletePersonalAccessToken = (id) => (dispatch) => (
  axios.delete(getPersonalAccessTokenAPI(id))
    .then((response) => {
      const responseData = getResponseData(response);
      dispatch(addNotification(null, 'delete-key-success'));
      dispatch(deletePersonalAccessTokenSuccess(responseData));
      return response && response.data;
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-key-error'));
      throw (error);
    })
);
