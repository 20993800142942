import axios from 'axios';
import {
  createCacheInvalidationAPI,
  getCachePrefetchingListAPI,
  createCachePrefetchingAPI,
  getPrefetchHeadersListAPI,
  getCacheInvalidationListAPI,
  getDistributionListAPI,
  createDistributionAPI,
  getTypeListAPI,
  getPricingZoneListAPI,
  deleteDistributionAPI,
  getDistributionDetailsAPI,
  getTypeDetailsAPI,
  getOriginListAPI,
  addOriginAPI,
  deleteOriginAPI,
  updateOriginAPI,
  getPageRuleListAPI,
  addPageRuleAPI,
  deletePageRuleAPI,
  updatePageRuleAPI,
  getPageRuleDetailsAPI,
  getConfigurationAPI,
  updateConfigurationAPI,
  getGeneralSettingsAPI,
  updateGeneralSettingsAPI,
  getCNAMEListAPI,
  addCNAMEAPI,
  deleteCNAMEAPI,
  updateCNAMEAPI,
  getRestrictionsAPI,
  updateRestrictionsAPI,
  getCountryListAPI,
  getOriginDetailsAPI,
  getErrorCodeListAPI,
  getAvailableErrorCodeListAPI,
  addErrorCodeAPI,
  deleteErrorCodeAPI,
  updateErrorCodeAPI,
  disableDistributionAPI,
  enableDistributionAPI,
  getOriginServersAPI,
  addOriginServerAPI,
  deleteOriginServerAPI,
  updateOriginServerAPI,
  moveDistributionAPI,
  enableDistributionRawLogsAPI,
  disableDistributionRawLogsAPI,
  reorderPageRuleAPI,
  getWafPolicyAPI,
  updateWafPolicyAPI,
  getPageRuleOperatorsAPI,
  getRedirectionIndexAPI,
  getRedirectionDetailAPI,
  getRedirectionFlagAPI,
  addRedirectionAPI,
  updateRedirectionAPI,
  deleteRedirectionAPI,
  reorderReorderAPI,
} from 'state/actions/apiFunctions';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';
import { errorHandler } from 'common/utils/response.handler';

export const DISTRIBUTIONS_ACTIONS = {
  ADD_CNAME: 'D_ADD_CNAME',
  ADD_ERROR_CODE: 'D_ADD_ERROR_CODE',
  ADD_ORIGIN: 'D_ADD_ORIGIN',
  ADD_PAGE_RULE: 'D_ADD_PAGE_RULE',
  CLEAR_PAGE_RULE_DETAILS: 'D_CLEAR_PAGE_RULE_DETAILS',
  CLEAR_REDUX: 'D_CLEAR_REDUX',
  CREATE_DISTRIBUTION: 'D_CREATE_DISTRIBUTION',
  DELETE_CNAME: 'D_DELETE_CNAME',
  DELETE_DISTRIBUTION: 'D_DELETE_DISTRIBUTION',
  DELETE_ORIGIN: 'D_DELETE_ORIGIN',
  DELETE_PAGE_RULE: 'D_DELETE_PAGE_RULE',
  GET_AVAILABLE_ERROR_CODE_LIST: 'D_AVAILABLE_GET_ERROR_CODE_LIST',
  GET_CACHE_INVALIDATION_LIST: 'D_GET_CACHE_INVALIDATION_LIST',
  GET_CACHE_PREFETCHING_LIST: 'D_GET_CACHE_PREFETCHING_LIST',
  GET_PREFETCH_HEADERS_LIST: 'D_GET_PREFETCH_HEADERS_LIST',
  GET_CNAME_LIST: 'D_GET_CNAME_LIST',
  GET_CONFIGURATION: 'D_GET_CONFIGURATION',
  CLEAR_CONFIGURATION: 'D_CLEAR_CONFIGURATION',
  GET_COUNTRY_LIST: 'D_GET_COUNTRY_LIST',
  GET_DISTRIBUTION_DETAILS: 'D_GET_DISTRIBUTION_DETAILS',
  GET_DISTRIBUTION_LIST: 'D_GET_DISTRIBUTION_LIST',
  GET_DISTRIBUTION_TYPE_DETAILS: 'D_GET_DISTRIBUTION_TYPE_DETAILS',
  GET_DISTRIBUTION_TYPES: 'D_GET_DISTRIBUTION_TYPES',
  GET_ERROR_CODE_LIST: 'D_GET_ERROR_CODE_LIST',
  GET_GENERAL_SETTINGS: 'D_GET_GENERAL_SETTINGS',
  GET_ORIGIN_DETAILS: 'D_GET_ORIGIN_DETAILS',
  GET_ORIGIN_LIST: 'D_GET_ORIGIN_LIST',
  GET_ORIGIN_SERVERS: 'D_GET_ORIGIN_SERVERS',
  GET_PAGE_RULE_DETAILS: 'D_GET_PAGE_RULE_DETAILS',
  GET_PAGE_RULE_OPERATORS: 'D_GET_PAGE_RULE_OPERATORS',
  GET_PAGE_RULE_LIST: 'D_GET_PAGE_RULE_LIST',
  GET_PRICING_ZONES: 'D_GET_PRICING_ZONES',
  GET_RESTRICTIONS: 'D_GET_RESTRICTIONS',
  UPDATE_CNAME: 'D_UPDATE_CNAME',
  UPDATE_CONFIGURATION: 'D_UPDATE_CONFIGURATION',
  UPDATE_DISTRIBUTION_STATUS: 'D_UPDATE_DISTRIBUTION_STATUS',
  UPDATE_GENERAL_SETTINGS: 'D_UPDATE_GENERAL_SETTINGS',
  UPDATE_ORIGIN: 'D_UPDATE_ORIGIN',
  UPDATE_PAGE_RULE: 'D_UPDATE_PAGE_RULE',
  UPDATE_RESTRICTIONS: 'D_UPDATE_RESTRICTIONS',
  GET_WAF_POLICY: 'D_GET_WAF_POLICY',
  UPDATE_WAF_POLICY: 'D_UPDATE_WAF_POLICY',
  GET_REDIRECTION_LIST: 'D_GET_REDIRECTION_LIST',
  GET_REDIRECTION_FLAG_LIST: 'D_GET_REDIRECTION_FLAG_LIST',
  GET_REDIRECTION_DETAILS: 'D_GET_REDIRECTION_DETAILS',
  UPDATE_REDIRECTION: 'D_UPDATE_REDIRECTION',
};

export const clearDistributionData = () => ({
  type: DISTRIBUTIONS_ACTIONS.CLEAR_REDUX,
});

/**
 * getDistributionList(): returns the list of distributions
 */

export const getDistributionListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_LIST,
  payload: data,
});

export const getDistributionList = (page, zone, ws) => (dispatch) => (
  axios.get(getDistributionListAPI(), {

    params: {
      organization_id: ws && ws.orgID,
      workspace_id: ws && ws.wsID,
      zone_id: zone !== 'all' ? zone : null,
      ...(page && page.currentPageNumber ? { page: page.currentPageNumber } : {}),
      ...(page && page.pageSize ? { per_page: page.pageSize } : {}),
      ...(page && page.query ? { term: page.query } : {}),
      ...(page && page.sortBy ? { 'sort_by[]': page.sortBy } : { 'sort_by[]': 'updated_at' }),
      ...(page && page.sortOrder ? { 'sort_desc[]': page && page.sortOrder !== 2 } : {}),
    },
  })
    .then((response) => {
      dispatch(getDistributionListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* getDistributionDetails(id): returns the details of certain distribution, it takes the id of that distribution as an argument */

const getDistributionDetailsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_DETAILS,
  payload: data,
});

export const getDistributionDetails = (data) => (dispatch) => (
  axios.get(`${getDistributionDetailsAPI()}/${data.id}`, {

  })
    .then((response) => {
      dispatch(getDistributionDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* deleteDistribution(data): delete the distribution with given data.id */

const deleteDistributionSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.DELETE_DISTRIBUTION,
  payload: data,
});

export const deleteDistribution = (values) => (dispatch) => (
  axios.delete(`${deleteDistributionAPI()}/${values.id}`, {
    data: { code: values.code },
  })
    .then((response) => {
      dispatch(addNotification(null, 'delete-distribution-success'));
      dispatch(deleteDistributionSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-distribution-error'));
      throw (error);
    })
);

/* createDistribution(data): create a distribution with given data */

const createDistributionSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.CREATE_DISTRIBUTION,
  payload: data,
});

export const createDistribution = (data) => (dispatch) => (
  axios.post(createDistributionAPI(), {
    name: data.name,
    origin_url: data.origin_url,
    type_id: data.type_id,
    zones: data.zones,
  })
    .then((response) => {
      const responseData = getResponseData(response);
      dispatch(addNotification(null, 'create-distribution-success'));
      dispatch(createDistributionSuccess(responseData));
      return responseData && responseData.data && responseData.data.id;
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'create-distribution-error');
      throw (error);
    })
);

/* getDistributionTypes(): returns the list of types to use while creating distributions */

const getDistributionTypesSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_TYPES,
  payload: data,
});

export const getDistributionTypes = () => (dispatch) => (
  axios.get(getTypeListAPI(), {

  })
    .then((response) => {
      dispatch(getDistributionTypesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* getDistributionTypeDetails(id): returns the details of certain distribution type with the given id */

const getDistributionTypeDetailsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_DISTRIBUTION_TYPE_DETAILS,
  payload: data,
});

export const getDistributionTypeDetails = (data) => (dispatch) => (
  axios.get(`${getTypeDetailsAPI()}/${data.id}`, {

  })
    .then((response) => {
      dispatch(getDistributionTypeDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* getPricingZones(): returns the list of pricing zones to use while creating distributions */

const getPricingZonesSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_PRICING_ZONES,
  payload: data,
});

export const getPricingZones = () => (dispatch) => (
  axios.get(getPricingZoneListAPI(), {

  })
    .then((response) => {
      dispatch(getPricingZonesSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Origin Details */

const getOriginDetailsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_ORIGIN_DETAILS,
  payload: data,
});

export const getOriginDetails = (data) => (dispatch) => (
  axios.get(getOriginDetailsAPI(data.distID, data.originID), {

  })
    .then((response) => {
      dispatch(getOriginDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* getOriginList(): returns the list of origins */

const getOriginListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_ORIGIN_LIST,
  payload: data,
});

export const getOriginList = (data) => (dispatch) => (
  axios.get(getOriginListAPI(data.distID), {

    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      term: data.page && data.page.query,
      'sort_by[]': (data.page && data.page.sortBy) || 'updated_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getOriginListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* deleteOrigin(): deletes the origin with given id */

const deleteOriginSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.DELETE_ORIGIN,
  payload: data,
});

export const deleteOrigin = (data) => (dispatch) => (
  axios.delete(`${deleteOriginAPI(data.distID, data.originID)}`, {
  })
    .then((response) => {
      dispatch(addNotification(null, 'delete-origin-success'));
      dispatch(deleteOriginSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-origin-error'));
      throw (error);
    })
);

/* addOrigin(): adds a new origin with provided arguments */

const addOriginSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.ADD_ORIGIN,
  payload: data,
});

export const addOrigin = (data) => (dispatch) => (
  axios.post(addOriginAPI(data.distID, data.originID), {
    distribution_id: data.distID,
    name: data.name,
    hostname: data.hostname,
    ip: data.ip,
    load_balancer_type: data.loadBalancer,
    path: data.path,
    protocol: data.protocol,
    http_port: data.httpPort,
    https_port: data.httpsPort,
    connect_timeout: data.connectionTimeout,
    custom_headers: data.customHeaders,
    servers: data.servers,
    health_check: data.healthCheck,
  })
    .then((response) => {
      dispatch(addNotification(null, 'add-origin-success'));
      dispatch(addOriginSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'add-origin-error'));
      throw (error);
    })
);

/* updateOrigin(): updates the origin with given id */

const updateOriginSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.UPDATE_ORIGIN,
  payload: data,
});

export const updateOrigin = (data) => (dispatch) => (
  axios.patch(`${updateOriginAPI(data.distID, data.originID)}`, {
    distribution_id: data.distID,
    name: data.name,
    hostname: data.hostname,
    ip: data.ip,
    load_balancer_type: data.loadBalancer,
    path: data.path,
    protocol: data.protocol,
    http_port: data.httpPort,
    https_port: data.httpsPort,
    connect_timeout: data.connectionTimeout,
    custom_headers: data.customHeaders,
    health_check: data.healthCheck,
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-origin-success'));
      dispatch(updateOriginSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-origin-error'));
      throw (error);
    })
);

// Get Origin Servers

const getOriginServersSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_ORIGIN_SERVERS,
  payload: data,
});

export const getOriginServers = (data) => (dispatch) => (
  axios.get(getOriginServersAPI(data.distID, data.originID), {

  })
    .then((response) => dispatch(getOriginServersSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Add Origin Server

export const addOriginServer = (data) => (dispatch) => (
  axios.post(addOriginServerAPI(data.distID, data.originID), {
    domain_or_ip: data.domain_or_ip,
    type: data.type,
    weight: data.weight,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'add-origin-server-success')))
    .catch((error) => dispatch(addNotification(getErrorDetails(error), 'add-origin-server-error')))
);

// Delete Origin Server

export const deleteOriginServer = (data) => (dispatch) => (
  axios.delete(deleteOriginServerAPI(data.distID, data.originID, data.serverID), {

  })
    .then(() => dispatch(addNotification(null, 'delete-origin-server-success')))
    .catch((error) => dispatch(addNotification(getErrorDetails(error), 'delete-origin-server-error')))
);

// Update Origin Server

export const updateOriginServer = (data) => (dispatch) => (
  axios.patch(updateOriginServerAPI(data.distID, data.originID, data.serverID), {
    domain_or_ip: data.domain_or_ip,
    type: data.type,
    weight: data.weight,
  }, {

  })
    .then(() => dispatch(addNotification(null, 'update-origin-server-success')))
    .catch((error) => dispatch(addNotification(getErrorDetails(error), 'update-origin-server-error')))
);

/* getPageRuleDetails() */

const getPageRuleDetailsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_DETAILS,
  payload: data,
});

export const getPageRuleDetails = (data) => (dispatch) => (
  axios.get(getPageRuleDetailsAPI(data.distID, data.pageRuleID), {

  })
    .then((response) => {
      dispatch(getPageRuleDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* getPageRuleList() */

const getPageRuleListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_LIST,
  payload: data,
});

export const getPageRuleList = (data) => (dispatch) => (
  axios.get(getPageRuleListAPI(data.distID), {

    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      term: data.page && data.page.query,
      'sort_by[]': (data.page && data.page.sortBy) || 'updated_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getPageRuleListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* clearPageRuleDetails() */

export const clearPageRuleDetails = () => ({
  type: DISTRIBUTIONS_ACTIONS.CLEAR_PAGE_RULE_DETAILS,
});

/* addPageRule() */

export const addPageRule = (data) => (dispatch) => (
  axios.post(addPageRuleAPI(data.distID), {
    origin_id: data.originID,
    pattern: {
      operator_id: data.pattern?.operator?.id,
      value: data.pattern?.value,
    },
    policies: data.policies,
  })
    .then(() => {
      dispatch(addNotification(null, 'add-page-rule-success'));
    })
    .catch((err) => {
      errorHandler(err, dispatch, 'add-page-rule-error');
      throw (err);
    })
);

/* deletePageRule() */

export const deletePageRule = (data) => (dispatch) => (
  axios.delete(`${deletePageRuleAPI(data.distID, data.pageRuleID)}`, {
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-page-rule-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-page-rule-error'));
      throw (error);
    })
);

/* updatePageRule() */

export const updatePageRule = (data) => (dispatch) => (
  axios.patch(`${updatePageRuleAPI(data.distID, data.pageRuleID)}`, {
    origin_id: data.originID,
    pattern: {
      operator_id: data.pattern?.operator?.id,
      value: data.pattern?.value,
    },
    policies: data.policies,
  })
    .then((response) => getPageRuleDetailsSuccess(getResponseData(response)))
    .then(() => dispatch(addNotification(null, 'update-page-rule-success')))
    .catch((error) => {
      errorHandler(error, dispatch, 'update-page-rule-error');
      throw (error);
    })
);

// reorder Page Rule

export const reorderPageRule = (data) => () => (
  axios.post(`${reorderPageRuleAPI(data.distID, data.pageRuleID)}`, {
    action: data.action,
    index: data.index,
  })
    .catch((error) => {
      throw (error);
    })
);

export const dragPageRule = () => () => { };

export const reorderPageRuleActions = {
  up: 'move_up',
  top: 'move_top',
  down: 'move_down',
  bottom: 'move_bottom',
};

/* clearConfiguration() */

export const clearConfiguration = () => ({
  type: DISTRIBUTIONS_ACTIONS.CLEAR_CONFIGURATION,
});

/* Update Configuration */

export const updateConfiguration = (data) => (dispatch) => (
  axios.patch(`${updateConfigurationAPI(data.distID)}`, {
    policies: data.policies,
  })
    .then(() => {
      dispatch(addNotification(null, 'update-configuration-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-configuration-error'));
      throw (error);
    })
);

const getConfigurationSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_CONFIGURATION,
  payload: data,
});

export const getConfiguration = (data) => (dispatch) => (
  axios.get(getConfigurationAPI(data.distID), {
    params: null,
  })
    .then((response) => dispatch(getConfigurationSuccess(getResponseData(response))))
    .catch((error) => {
      throw error;
    })
);

/* Get General Settings */

export const getGeneralSettingsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_GENERAL_SETTINGS,
  payload: data,
});

export const getGeneralSettings = (data) => (dispatch) => (
  axios.get(getGeneralSettingsAPI(data.distID), {
  })
    .then((response) => dispatch(getGeneralSettingsSuccess(getResponseData(response))))
    .catch(() => null)
);

/* Update Configuration */

export const updateGeneralSettings = (data) => (dispatch) => (
  axios.patch(`${updateGeneralSettingsAPI(data.distID)}`, {
    name: data.name,
    zones: data.pricingZones,
  })
    .then(() => {
      dispatch(addNotification(null, 'update-general-settings-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-general-settings-error'));
      throw (error);
    })
);

/* Get CNAME List */

const getCNAMEListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_CNAME_LIST,
  payload: data,
});

export const getCNAMEList = (data) => (dispatch) => (
  axios.get(getCNAMEListAPI(data.distID), {

    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      term: data.page && data.page.query,
      'sort_by[]': (data.page && data.page.sortBy) || 'updated_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getCNAMEListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Add CNAME */

export const addCNAME = (data) => (dispatch) => (
  axios.post(addCNAMEAPI(data.distID), {
    distribution_id: data.distID,
    certificate_id: data.certificateID,
    domain: data.domain,
    is_default: data.default,
  })
    .then(() => {
      dispatch(addNotification(null, 'add-cname-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'add-cname-error');
      throw (error);
    })
);

/* Delete CNAME */

export const deleteCNAME = (data) => (dispatch) => (
  axios.delete(`${deleteCNAMEAPI(data.distID, data.cNAMEID)}`, {
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-cname-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-cname-error'));
      throw (error);
    })
);

/* Update CNAME */

export const updateCNAME = (data) => (dispatch) => (
  axios.patch(`${updateCNAMEAPI(data.distID, data.cNAMEID)}`, {
    distribution_id: data.distID,
    certificate_id: data.certificateID ? data.certificateID : null,
    domain: data.domain,
  })
    .then(() => {
      dispatch(addNotification(null, 'update-cname-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-cname-error');
      throw (error);
    })
);

// Make CNAME Default

export const makeCNAMEDefault = (data) => (dispatch) => (
  axios.patch(`${updateCNAMEAPI(data.distID, data.cNAMEID)}`, {
    is_default: true,
  })
    .then(() => dispatch(addNotification(null, 'make-cname-default-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'make-cname-default-error'));
      throw (error);
    })
);

/* Get Restrictions */

export const getRestrictionsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_RESTRICTIONS,
  payload: data,
});

export const getRestrictions = (data) => (dispatch) => (
  axios.get(getRestrictionsAPI(data.distID), {
  })
    .then((response) => dispatch(getRestrictionsSuccess(getResponseData(response))))
    .catch((error) => {
      throw error;
    })
);

/* Update Restrictions */

export const updateRestrictions = (data) => (dispatch) => (
  axios.patch(`${updateRestrictionsAPI(data.distID)}`, {
    ...data.body,
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-restrictions-success'));
      dispatch(getRestrictionsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-restrictions-error'));
      throw (error);
    })
);

/* Get Country List */

const getCountryListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_COUNTRY_LIST,
  payload: data,
});

export const getCountryList = () => (dispatch) => (
  axios.get(getCountryListAPI(), {

    params: {
      per_page: 1000,
    },
  })
    .then((response) => {
      dispatch(getCountryListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Error Code List */

const getErrorCodeListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_ERROR_CODE_LIST,
  payload: data,
});

export const getErrorCodeList = (data) => (dispatch) => (
  axios.get(getErrorCodeListAPI(data.distID), {

    params: {
      per_page: 1000,
    },
  })
    .then((response) => {
      dispatch(getErrorCodeListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Available Error Code List */

const getAvailableErrorCodeListSuccess = (data, distID) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_AVAILABLE_ERROR_CODE_LIST,
  payload: {
    data: data.data,
    distID,
  },
});

export const getAvailableErrorCodeList = (data) => (dispatch) => (
  axios.get(getAvailableErrorCodeListAPI(data.distID), {

    params: {
      per_page: 1000,
    },
  })
    .then((response) => {
      dispatch(getAvailableErrorCodeListSuccess(getResponseData(response), data.distID));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Add Error Code */

export const addErrorCode = (data) => (dispatch) => (
  axios.post(addErrorCodeAPI(data.distID), {
    error_code_id: data.httpErrorCode,
    ttl: data.ttl && parseInt(data.ttl, 10),
  })
    .then(() => {
      dispatch(addNotification(null, 'add-error-code-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'add-error-code-error');
      throw (error);
    })
);

/* Update Error Code */

export const updateErrorCode = (data) => (dispatch) => (
  axios.patch(updateErrorCodeAPI(data.distID, data.errorCodeID), {
    ttl: data.ttl && parseInt(data.ttl, 10),
  })
    .then(() => {
      dispatch(addNotification(null, 'update-error-code-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-error-code-error');
      throw (error);
    })
);

/* Delete Error Code */

export const deleteErrorCode = (data) => (dispatch) => (
  axios.delete(`${deleteErrorCodeAPI(data.distID, data.errorCodeID)}`, {
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-error-code-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-error-code-error'));
      throw (error);
    })
);

/* Create Cache Invalidation */

export const createCacheInvalidation = (data) => (dispatch) => (
  axios.post(createCacheInvalidationAPI(data.distID), {
    distribution_id: data.distID,
    headers: data.headers,
    paths: data.paths,
    type: data.type,
  })
    .then(() => dispatch(addNotification(null, 'create-cache-invalidation-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-cache-invalidation-error'));
      throw (error);
    })
);

/* Get Cache Invalidation List */

const getCacheInvalidationListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_CACHE_INVALIDATION_LIST,
  payload: data,
});

export const getCacheInvalidationList = (distID, data) => (dispatch) => (
  axios.post(getCacheInvalidationListAPI(distID), data)
    .then((response) => {
      dispatch(getCacheInvalidationListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Create Cache Prefetching */

export const createCachePrefetching = (data) => (dispatch) => (
  axios.post(createCachePrefetchingAPI(data.distID), {
    path: data.path,
    headers: data.headers,
  })
    .then(() => {
      dispatch(addNotification(null, 'create-cache-prefetching-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-cache-prefetching-error'));
      throw (error);
    })
);

/* Get Cache Prefetching List */

const getCachePrefetchingListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_CACHE_PREFETCHING_LIST,
  payload: data,
});

export const getCachePrefetchingList = (distID, data) => (dispatch) => (
  axios.post(getCachePrefetchingListAPI(distID), data)
    .then((response) => {
      dispatch(getCachePrefetchingListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Get Headers List for Prefetching */

const getPrefetchHeadersListSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_PREFETCH_HEADERS_LIST,
  payload: data,
});

export const getPrefetchHeadersList = (data) => (dispatch) => (
  axios.get(getPrefetchHeadersListAPI(data.distID), {

  })
    .then((response) => {
      dispatch(getPrefetchHeadersListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Update Distribution Status */

export const updateDistributionStatus = (data) => (dispatch) => (
  axios.post(data.is_active ? enableDistributionAPI(data.id) : disableDistributionAPI(data.id), null, {

  })
    .then(() => {
      dispatch(addNotification(null, 'update-distribution-status-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-distribution-status-error'));
      throw (error);
    })
);

/* Update Distribution Status */

export const updateDistributionRawLogs = (data) => (dispatch) => (
  axios.post(data.enable ? enableDistributionRawLogsAPI(data.id) : disableDistributionRawLogsAPI(data.id), null, {

  })
    .then(() => (data.enable
      ? dispatch(addNotification(null, 'enable-distribution-raw-logs-success'))
      : dispatch(addNotification(null, 'disable-distribution-raw-logs-success'))
    ))
    .catch((error) => {
      if (data.enable) {
        dispatch(addNotification(getErrorDetails(error), 'enable-distribution-raw-logs-error'));
      } else {
        dispatch(addNotification(getErrorDetails(error), 'disable-distribution-raw-logs-error'));
      }
      throw (error);
    })
);

// Move Distribution

export const moveDistribution = (data) => (dispatch, getState) => {
  const state = getState();
  const ws = state.workspaces.ownedWorkspaces && state.workspaces.ownedWorkspaces.find((ele) => ele.id === data.targetID);
  return (
    axios.post(moveDistributionAPI(data.id), {
      target_workspace_id: data.targetID,
    })
      .then(() => dispatch(addNotification({ orgID: data.orgID, wsID: data.targetID, name: ws.name }, 'move-distribution-success')))
      .catch((error) => {
        dispatch(addNotification(getErrorDetails(error), 'move-distribution-error'));
        throw (error);
      })
  );
};

/* Get WAF Policy */

const getWafPolicySuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_WAF_POLICY,
  payload: data,
});

export const getWafPolicy = (data) => (dispatch) => (
  axios.get(getWafPolicyAPI(data.distID), {

  })
    .then((response) => {
      dispatch(getWafPolicySuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

/* Update WAF Policy */

const updateWafPolicySuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.UPDATE_WAF_POLICY,
  payload: data,
});

export const updateWafPolicy = (data) => (dispatch) => (
  axios.patch(updateWafPolicyAPI(data.distID), {
    policy_id: data.policyID,
    detection_only_mode: data.detectionOnlyMode,
  }, {

  })
    .then((response) => {
      dispatch(updateWafPolicySuccess(getResponseData(response)));
      dispatch(addNotification(null, 'update-distribution-waf-policy-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-distribution-waf-policy-error'));
      throw (error);
    })
);

// Get Page Rule Operators

const getPageRuleOperatorsSuccess = (payload) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_PAGE_RULE_OPERATORS,
  payload,
});

export const getPageRuleOperators = (data) => (dispatch) => axios.get(getPageRuleOperatorsAPI(data.distID))
  .then((response) => dispatch(getPageRuleOperatorsSuccess(getResponseData(response))))
  .catch((error) => {
    throw (error);
  });

const getRedirectionSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_LIST,
  payload: data,
});

export const getRedirectionList = (data) => (dispatch) => (
  axios.get(getRedirectionIndexAPI(data.distID), {

    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      term: data.page && data.page.query,
      'sort_by[]': (data.page && data.page.sortBy) || 'updated_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getRedirectionSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getRedirectionFlagSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_FLAG_LIST,
  payload: data,
});

export const getRedirectionFlagList = (data) => (dispatch) => (
  axios.get(getRedirectionFlagAPI(data.distID), {

  })
    .then((response) => {
      dispatch(getRedirectionFlagSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const updateRedirection = (data, formData) => (dispatch) => (
  axios.patch(updateRedirectionAPI(data.distID, data.rewriteID), { ...formData }, {

  })
    .then(() => {
      dispatch(addNotification(null, 'update-distribution-redirection-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'update-distribution-redirection-error');

      throw (error);
    })
);

export const addRedirection = (distID, formData) => (dispatch) => (
  axios.post(addRedirectionAPI(distID), { ...formData }, {

  })
    .then(() => {
      dispatch(addNotification(null, 'add-distribution-redirection-success'));
    })
    .catch((error) => {
      errorHandler(error, dispatch, 'add-distribution-redirection-error');
      throw (error);
    })
);

const getRedirectionDetailsSuccess = (data) => ({
  type: DISTRIBUTIONS_ACTIONS.GET_REDIRECTION_DETAILS,
  payload: data,
});

export const getRedirectionDetails = (data) => (dispatch) => (
  axios.get(`${getRedirectionDetailAPI(data.distID, data.rewriteID)}`, {

  })
    .then((response) => {
      dispatch(getRedirectionDetailsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

export const deleteRedirection = (data) => (dispatch) => (
  axios.delete(`${deleteRedirectionAPI(data.distID, data.reWriteID)}`)
    .then(() => {
      dispatch(addNotification(null, 'delete-distribution-redirection-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-distribution-redirection-error'));
      throw (error);
    })
);

export const reorderRedirection = (data) => () => (
  axios.post(`${reorderReorderAPI(data.distID, data.rewriteID)}`, {
    action: data.action,
    index: data.index,
  })
    .catch((error) => {
      throw (error);
    })
);
