import { Component } from 'react';
import PropTypes from 'prop-types';
import { Toaster } from '@blueprintjs/core';
import ForgotPasswordSuccessContainer from 'components/notifications/forgotpassword/success/ForgotPasswordSuccess.container';
import ForgotPasswordErrorContainer from 'components/notifications/forgotpassword/error/ForgotPasswordError.container';
import RegisterSuccessContainer from 'components/notifications/register/success/RegisterSuccess.container';
import RegisterErrorContainer from 'components/notifications/register/error/RegisterError.container';
import LoginErrorContainer from 'components/notifications/login/error/LoginError.container';
import ResetPasswordErrorContainer from 'components/notifications/resetpassword/error/ResetPasswordError.container';
import ResetPasswordSuccessContainer from 'components/notifications/resetpassword/success/ResetPasswordSuccess.container';
import VerifyMailSuccessContainer from 'components/notifications/verifymail/success/VerifyMailSuccess.container';
import UpdatePasswordSuccessContainer from 'components/notifications/updatepassword/success/UpdatePasswordSuccess.container';
import SuccessNotificationContainer from 'components/notifications/successNotification/SuccessNotification.container';
import ErrorNotificationContainer from 'components/notifications/errorNotification/ErrorNotification.container';
import { getSimpleSuccesses, simpleErrors } from './Notifications.config';

class Notifications extends Component {
  getNotificationsList = () => {
    let dialogCount = 0;
    let dialogType = null;
    const notificationList = this.props.notifications.reduce((list, notification) => {
      const successNotification = getSimpleSuccesses(notification.data || {}).find((ele) => ele.type === notification.type);
      if (successNotification) {
        return [
          ...list,
          {
            ...notification,
            ...successNotification,
            class: 0,
            text: successNotification.message,
          },
        ];
      }
      if (simpleErrors.includes(notification.type)) {
        return [
          ...list,
          {
            ...notification,
            class: 1,
          },
        ];
      }
      if (dialogCount === 0) {
        dialogCount += 1;
        dialogType = notification.type;
        return [
          ...list,
          {
            ...notification,
            class: 2,
          },
        ];
      }
      if (dialogType === notification.type) {
        this.props.removeNotification(notification.id);
      }
      return list;
    }, []);

    return notificationList;
  }

  renderNotification = (notification) => {
    switch (notification.class) {
      case 0:
        return <SuccessNotificationContainer notification={notification} key={`${notification.id}-success-toast`} />;
      case 1:
        return <ErrorNotificationContainer notification={notification} key={`${notification.id}-error-toast`} />;
      default:
        break;
    }
    switch (notification.type) {
      case 'forgot-password-error':
        return <ForgotPasswordErrorContainer notification={notification} key={`${notification.id}-error-dialog-forgot-password`} />;
      case 'forgot-password-success':
        return <ForgotPasswordSuccessContainer id={notification.id} key={`${notification.id}-success-dialog-forgot-password`} />;
      case 'login-error':
        return <LoginErrorContainer notification={notification} key={`${notification.id}-error-dialog-login`} />;
      case 'register-error':
        return <RegisterErrorContainer notification={notification} key={`${notification.id}-error-dialog-register`} />;
      case 'register-success':
        return <RegisterSuccessContainer id={notification.id} key={`${notification.id}-success-dialog-register`} />;
      case 'reset-password-error':
        return <ResetPasswordErrorContainer notification={notification} key={`${notification.id}-error-dialog-reset-password`} />;
      case 'reset-password-success':
        return <ResetPasswordSuccessContainer id={notification.id} key={`${notification.id}-success-dialog-reset-password`} />;
      case 'throttle-error':
        return <ErrorNotificationContainer notification={notification} forceDialog key={`${notification.id}-throttle-error`} />;
      case 'update-password-error':
        return <ErrorNotificationContainer notification={notification} forceDialog key={`${notification.id}-error-dialog-update-password`} />;
      case 'update-password-success':
        return <UpdatePasswordSuccessContainer id={notification.id} key={`${notification.id}-success-dialog-update-password`} />;
      case 'verify-mail-error':
        return <ErrorNotificationContainer notification={notification} forceDialog key={`${notification.id}-error-dialog-verify-email`} />;
      case 'verify-mail-success':
        return <VerifyMailSuccessContainer id={notification.id} key={`${notification.id}-success-dialog-verify-email`} />;
      default:
        this.props.removeNotification(notification.id);
        return null;
    }
  }

  render() {
    const notificationsList = this.getNotificationsList();

    return (
      <>
        {this.props.children}
        <Toaster maxToasts={3} position="top">
          {notificationsList.map((notification) => this.renderNotification(notification))}
        </Toaster>
      </>
    );
  }
}

Notifications.propTypes = {
  /* Functions */
  removeNotification: PropTypes.func.isRequired,
  /* Objects */
  children: PropTypes.node,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
};

Notifications.defaultProps = {
  children: null,
  notifications: null,
};

export default Notifications;
