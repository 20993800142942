import PropTypes from 'prop-types';
import { appendClasses } from 'common/helpers';

const Notification = (props) => (
  <div className={appendClasses('m-notification-component', props.classes)}>
    {props.children}
  </div>
);

Notification.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

Notification.defaultProps = {
  children: null,
  classes: null,
};

export default Notification;
