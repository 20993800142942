import axios from 'axios';
import { getLogListAPI, getRawLogListAPI, getRawLogDetailsAPI } from 'state/actions/apiFunctions';
import { getResponseData } from 'state/actions/apiHelpers';
import { formatDate4 } from 'common/helpers';

export const LOGS_ACTIONS = {
  GET_LOG_LIST: 'LOGS_GET_LOG_LIST',
  GET_RAW_LOG_LIST: 'LOGS_GET_RAW_LOG_LIST',
  GET_RAW_LOG_DETAILS: 'LOGS_GET_RAW_LOG_DETAILS',
};

export const getLogListSuccess = (data) => ({
  type: LOGS_ACTIONS.GET_LOG_LIST,
  payload: data,
});

export const getLogList = (page, filters) => (dispatch) => (
  axios.get(getLogListAPI(), {

    params: {
      distribution_id: filters.distID && filters.distID === 'all' ? null : filters.distID,
      zone_id: filters.zoneID && filters.zoneID === 'all' ? null : filters.zoneID,
      interval: filters.interval && filters.interval === 'default' ? null : filters.interval,
      http_status: filters.httpStatuses,
      page: page.currentPageNumber,
      per_page: page.pageSize,
      sort_by: page.sortBy || 'date',
      desc: page.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getLogListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getRawLogListSuccess = (data) => ({
  type: LOGS_ACTIONS.GET_RAW_LOG_LIST,
  payload: data,
});

export const getRawLogList = (data) => (dispatch) => (
  axios.get(getRawLogListAPI(), {
    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      term: data.page && data.page.query,
      'sort_by[]': (data.page && data.page.sortBy) || 'created_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getRawLogListSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

const getRawLogDetailsSuccess = (data) => ({
  type: LOGS_ACTIONS.GET_RAW_LOG_DETAILS,
  payload: data,
});

export const getRawLogDetails = (data) => (dispatch) => (
  axios.get(getRawLogDetailsAPI(data.id), {

    params: {
      date: {
        interval: data && (data.interval === 'custom' ? null : data.interval),
        start: data && (data.interval === 'custom' ? formatDate4(data.customInterval[0]) : null),
        end: data && (data.interval === 'custom' ? formatDate4(data.customInterval[1]) : null),
      },
      page: data.page && data.page.currentPageNumber,
      per_page: data.page && data.page.pageSize,
      'sort_by[]': (data.page && data.page.sortBy) || 'created_at',
      'sort_desc[]': data.page && data.page.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getRawLogDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);
