import axios from 'axios';
import {
  getCertificateListAPI, createCertificateAPI,
  updateCertificateAPI, getFreeSSLCertificateAPI,
  getCertificateDetailsAPI,
  getCertificateCNAMEsAPI,
  copyCertificateAPI,
} from 'state/actions/apiFunctions';
import { getResponseData, getErrorDetails } from 'state/actions/apiHelpers';
import { addNotification } from 'common/redux/common.actions';

export const CERTIFICATES_ACTIONS = {
  CLEAR_CERTIFICATE_CNAMES: 'C_CLEAR_CERTIFICATE_CNAMES',
  CLEAR_CERTIFICATE_DETAILS: 'C_CLEAR_CERTIFICATE_DETAILS',
  CREATE_CERTIFICATE_SUCCESS: 'C_CREATE_CERTIFICATE_SUCCESS',
  GET_CERTIFICATE_LIST: 'C_GET_CERTIFICATE_LIST',
  GET_CERTIFICATE_CNAMES: 'C_GET_CERTIFICATE_CNAMES',
  GET_CERTIFICATE_DETAILS: 'C_GET_CERTIFICATE_DETAILS',
  UPDATE_CERTIFICATE_SUCCESS: 'C_UPDATE_CERTIFICATE_SUCCESS',
};

export const clearCertificateData = () => ({
  type: CERTIFICATES_ACTIONS.CLEAR_CERTIFICATE_DETAILS,
});

export const clearCertificateCNAMEs = () => ({
  type: CERTIFICATES_ACTIONS.CLEAR_CERTIFICATE_CNAMES,
});

/* Certificate List */

const getCertificateListSuccess = (data) => ({
  type: CERTIFICATES_ACTIONS.GET_CERTIFICATE_LIST,
  payload: data,
});

export const getCertificateList = (page) => (dispatch) => (
  axios.get(getCertificateListAPI(), {
    params: {
      ...(page && page.currentPageNumber ? { page: page.currentPageNumber } : {}),
      ...(page && page.pageSize ? { per_page: page.pageSize } : {}),
      ...(page && page.query ? { term: page.query } : {}),
      ...(page && page.sortBy ? { 'sort_by[]': page.sortBy } : { 'sort_by[]': 'updated_at' }),
      ...(page && page.sortOrder ? { 'sort_desc[]': page && page.sortOrder !== 2 } : {}),
    },
  })
    .then((response) => {
      dispatch(getCertificateListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

// Get Certificate

const getCertificateDetailsSuccess = (payload) => ({
  type: CERTIFICATES_ACTIONS.GET_CERTIFICATE_DETAILS,
  payload,
});

export const getCertificateDetails = (data) => (dispatch) => (
  axios.get(getCertificateDetailsAPI(data.id))
    .then((response) => dispatch(getCertificateDetailsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

// Get Certificate CNAMEs

const getCertificateCNAMEsSuccess = (payload) => ({
  type: CERTIFICATES_ACTIONS.GET_CERTIFICATE_CNAMES,
  payload,
});

export const getCertificateCNAMEs = (data) => (dispatch) => (
  axios.get(getCertificateCNAMEsAPI(data.id), {
    params: {
      page: data.currentPageNumber,
      per_page: data.pageSize,
      term: data.query,
      'sort_by[]': data.sortBy,
      'sort_desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => dispatch(getCertificateCNAMEsSuccess(getResponseData(response))))
    .catch((error) => {
      throw (error);
    })
);

/* Create Certificate */

const createCertificateSuccess = (data) => ({
  type: CERTIFICATES_ACTIONS.CREATE_CERTIFICATE_SUCCESS,
  payload: data,
});

export const createCertificate = (values) => (dispatch) => (
  axios.post(createCertificateAPI(), {
    cert_file: values.certFile,
    key_file: values.keyFile,
  })
    .then((response) => {
      dispatch(addNotification(null, 'create-certificate-success'));
      dispatch(createCertificateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-certificate-error'));
      throw (error);
    })
);

export const getFreeSSLCertificate = (data) => (dispatch) => (
  axios.post(getFreeSSLCertificateAPI(data.distID, data.cNAMEID), {})
    .then(() => {
      dispatch(addNotification(null, 'get-free-ssl-certificate-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'get-free-ssl-certificate-error'));
      throw (error);
    })
);

/* Update Certificate */

const updateCertificateSuccess = (data) => ({
  type: CERTIFICATES_ACTIONS.UPDATE_CERTIFICATE_SUCCESS,
  payload: data,
});

export const updateCertificate = (values) => (dispatch) => (
  axios.patch(`${updateCertificateAPI()}/${values.id}`, {
    cert_file: values.certFile,
    key_file: values.keyFile,
  })
    .then((response) => {
      dispatch(addNotification(null, 'update-certificate-success'));
      dispatch(updateCertificateSuccess(getResponseData(response)));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'update-certificate-error'));
      throw (error);
    })
);

/* Delete Certificate */

export const deleteCertificate = (values) => (dispatch) => (
  axios.delete(`${updateCertificateAPI()}/${values.id}`)
    .then(() => {
      dispatch(addNotification(null, 'delete-certificate-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-certificate-error'));
      throw (error);
    })
);

// Copy Certificate

export const copyCertificate = (data) => (dispatch) => (
  axios.post(copyCertificateAPI(data.certID), {
    workspace_id: data.wsID,
  })
    .then(() => dispatch(addNotification(null, 'copy-certificate-success')))
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'copy-certificate-error'));
      throw (error);
    })
);
