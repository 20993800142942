import { HOME_ACTIONS } from './Home.actions';

export default (state = null, action) => {
  switch (action.type) {
    case HOME_ACTIONS.GET_BANDWIDTH_DATA:
      return {
        ...state,
        bandwidthData: action.payload.data,
      };
    case HOME_ACTIONS.GET_HIT_MISS_DATA:
      return {
        ...state,
        hitMissData: action.payload.data,
      };
    case HOME_ACTIONS.GET_HTTP_REQUEST_SUMMARY:
      return {
        ...state,
        httpRequestSummary: action.payload.data,
      };
    case HOME_ACTIONS.GET_HTTP_RESPONSE_DATA:
      return {
        ...state,
        httpResponseData: action.payload.data,
      };
    case HOME_ACTIONS.GET_LINE_CHART_DATA:
      return {
        ...state,
        lineChartData: action.payload.data,
      };
    case HOME_ACTIONS.GET_REQUEST_DATA:
      return {
        ...state,
        requestData: action.payload.data,
      };
    case HOME_ACTIONS.GET_STATS:
      return {
        ...state,
        stats: action.payload.data,
      };
    default:
      return state;
  }
};
