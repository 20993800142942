import { WORKSPACE_DASHBOARD_ACTIONS } from './WsDashboard.actions';

export default (state = null, action) => {
  switch (action.type) {
    case WORKSPACE_DASHBOARD_ACTIONS.DIST_SUMMARY_REQUEST:
      return {
        ...state,
        distSummaryData: action.payload.data,
      };
    case WORKSPACE_DASHBOARD_ACTIONS.CLOUD_STORAGE_SUMMARY_REQUEST:
      return {
        ...state,
        cloudStorageSummaryData: action.payload.data,
      };
    case WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_REPORT_REQUEST:
      return {
        ...state,
        recentReportData: action.payload.data,
      };
    case WORKSPACE_DASHBOARD_ACTIONS.CLONER_WIDGET_REQUEST:
      return {
        ...state,
        clonerWidgetData: action.payload.data,
      };
    case WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_ACTIVITY_REQUEST:
      return {
        ...state,
        recentActivityData: state.recentActivityData ? [...state.recentActivityData, action.payload.data] : [action.payload.data],
        recentActivityNext: action.payload.meta.next,
      };
    case WORKSPACE_DASHBOARD_ACTIONS.WORKSPACE_RECENT_ACTIVITY_CLEAR: {
      return {
        ...state,
        recentActivityData: [],
        recentActivityNext: null,
      };
    }
    default:
      return state;
  }
};
