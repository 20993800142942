import {
  xmlToJson, getObjectName, hashContent, generateUniqueID,
} from './Buckets.helpers';
import { BUCKET_ACTIONS } from './Buckets.actions';

export default (state = null, action) => {
  switch (action.type) {
    case BUCKET_ACTIONS.GET_STORAGE_STATUS:
      return {
        ...state,
        isActive: action.payload.data.is_active,
      };
    case BUCKET_ACTIONS.GET_BUCKET_INFO:
      return {
        ...state,
        selectedBucketInfo: action.payload.data,
      };
    case BUCKET_ACTIONS.SET_BUCKET_VISIBILITY: {
      const data = { ...state.selectedBucketInfo, ...{ visibility: action.payload } };
      return {
        selectedBucketInfo: data,
      };
    }
    case BUCKET_ACTIONS.GET_VERIONING_INFO:
      return {
        ...state,
        versioningInfo: action.payload?.VersioningConfiguration?.Status,
      };
    case BUCKET_ACTIONS.GET_BUCKET_TAGGING_INFO:
      return {
        ...state,
        bucketTagging: action.payload?.Tagging?.TagSet,
      };
    case BUCKET_ACTIONS.GET_BUCKET_WEBSITE:
      return {
        ...state,
        bucketWebsite: action.payload?.WebsiteConfiguration,
      };
    case BUCKET_ACTIONS.GET_OBJECT_ACL:
      return {
        ...state,
        objectAcl: action.payload?.AccessControlPolicy.AccessControlList[0].Grant[0]?.Permission[0] === 'READ',
      };
    case BUCKET_ACTIONS.GET_BUCKET_TRAFFIC_INFO:
    {
      let items = action.payload.data;

      if (items.in.records.length === 0) {
        const emptyObject = { records: [{ date: new Date(), value: '0' }], symbol: 'MB' };
        items = { in: emptyObject, out: emptyObject };
      }

      return {
        ...state,
        trafficUsage: items,
      };
    }
    case BUCKET_ACTIONS.GET_BUCKET_STORAGE_INFO:
    {
      let items = action.payload.data;

      if (items.objects.records.length === 0) {
        const emptyObject = { records: [{ date: new Date(), value: '0' }], symbol: 'MB' };
        items = { objects: emptyObject, storage: emptyObject };
      }

      return {
        ...state,
        storageUsage: items,
      };
    }
    case BUCKET_ACTIONS.ACTIVATE_STORAGE:
      return {
        ...state,
        isActive: action.payload.data.is_active,
      };
    case BUCKET_ACTIONS.GET_BUCKET_LIST:
      return {
        ...state,
        bucketList: action.payload,
      };
    case BUCKET_ACTIONS.GET_USAGE_METRICS:
      return {
        ...state,
        usageMetrics: action.payload.data,
      };
    case BUCKET_ACTIONS.GET_REGIONS:
      return {
        ...state,
        regions: action.payload.data,
      };
    case BUCKET_ACTIONS.GET_BUCKET:
      return {
        ...state,
        bucket: xmlToJson(action.payload),
      };
    case BUCKET_ACTIONS.UPLOAD_OBJECT:
    {
      const progressfiles = state.progressfiles ? { ...state.progressfiles, ...action.payload } : { ...action.payload };

      return {
        ...state,
        progressfiles,
      };
    }
    case BUCKET_ACTIONS.CLEAR_UPLOAD_OBJECT_PROGRESS:
      return {
        ...state,
        progressfiles: {},
      };
    case BUCKET_ACTIONS.SET_BUCKET_CONTENT_LIST:
      return {
        ...state,
        detail: {
          contentLists: action.payload,
        },
      };
    case BUCKET_ACTIONS.GET_BUCKET_POLICY:
      return {
        ...state,
        policies: action.payload,
      };
    case BUCKET_ACTIONS.CLEAR_BUCKET_STATE:
      return {
        ...state,
        bucketTagging: null,
        bucketWebsite: null,
        versioningInfo: null,
        objectAcl: null,
        selectedBucketInfo: null,
        progressfiles: {},
        detail: {
          contentLists: [],
        },
      };
    case BUCKET_ACTIONS.CANCELABLE_TOKEN:
    {
      const cancelableTokens = state.cancelableTokens ? { ...state.cancelableTokens, ...action.payload } : { ...action.payload };

      return {
        ...state,
        cancelableTokens,
      };
    }
    case BUCKET_ACTIONS.GET_BUCKET_OBJECT_LIST: {
      const result = xmlToJson(action.payload).ListBucketResult;
      let items = [];

      const directories = result.CommonPrefixes?.map((object) => ({
        id: generateUniqueID() + hashContent(object.Prefix[0]),
        key: object.Prefix[0],
        name: getObjectName(object.Prefix[0], true),
        type: 'DIRECTORY',
      }));

      const files = result.Contents?.map((object) => {
        if (!object.Key[0].endsWith('/')) {
          const item = {
            id: generateUniqueID() + hashContent(getObjectName(object.Key[0]) + object.LastModified),
            etag: object.ETag[0],
            key: object.Key[0],
            name: getObjectName(object.Key[0]),
            lastModified: object.LastModified,
            size: object.Size[0],
            storageClass: object.StorageClass[0],
            type: 'FILE',
          };
          return item;
        }
        return null;
      });

      if (directories) {
        items = items.concat(directories);
      }

      if (files) {
        items = items.concat(files);
      }

      const data = {
        name: result.Name[0],
        currentPrefix: result.Prefix[0],
        previouskKey: result?.ContinuationToken ? result.ContinuationToken[0] : '',
        nextKey: result?.NextContinuationToken ? result.NextContinuationToken[0] : '',
        contentLists: items ? items.filter((i) => i) : [],
        isTruncated: result.IsTruncated[0] === 'true',
      };

      return {
        ...state,
        detail: data,
      };
    }
    case BUCKET_ACTIONS.GET_DELETE_DIRECTORY_OBJECT: {
      const result = xmlToJson(action.payload).ListBucketResult;
      let files = [];

      files = result.Contents?.map((object) => {
        const item = {
          key: object.Key[0],
        };
        return item;
      });

      let deleteDirectoryFiles = state.deleteDirectoryFiles ?? [];
      deleteDirectoryFiles = deleteDirectoryFiles.concat(files);

      return {
        ...state,
        deleteDirectoryFiles,
      };
    }
    case BUCKET_ACTIONS.CLEAR_MULTIPLE_DELETE_STATE:
      return {
        ...state,
        deleteDirectoryFiles: [],
      };
    default:
      return state;
  }
};
