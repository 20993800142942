export const getBillingMainTabs = (prefix) => [
  {
    id: 210,
    label: 'Dashboard',
    url: `${prefix}`,
  }, {
    id: 214,
    label: 'Plans',
    url: `${prefix}/plans`,
  }, {
    id: 211,
    label: 'Payment Methods',
    url: `${prefix}/payment`,
  }, {
    id: 212,
    label: 'Invoice Settings',
    url: `${prefix}/settings`,
  },
/** {
  id: 213,
  label: 'Notification Settings',
  url: `${prefix}/notification-settings`,
}* */];

export const PLAN_BUTTON_TYPES = {
  SUBSCRIBE: 'success',
  UPGRADE: 'success',
  DOWNGRADE: 'warning',
  CANCEL: 'danger',
  RESERVED: 'primary',
};

export const TRANSACTION_TYPES = {
  SUBSCRIBE: 'subscribe',
  UPGRADE: 'upgrade',
  PAST_DUE: 'past_due',
};

export const SUBSCRIPTION_ACTIONS = [
  'DOWNGRADE',
  'CANCEL',
];

export const PENDING_PLAN_STATUSES = [
  'DOWNGRADING',
  'CANCELING',
];

export const ACTIVE_PLAN_STATUSES = [
  'CANCEL',
  'CANCELING',
];
