import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class RouteController extends Component {
  componentDidMount = () => {
  }

  componentDidUpdate() {
  }

  render() {
    return this.props.children;
  }
}

RouteController.propTypes = {
  /* Functions */
  /* Objects */
  children: PropTypes.node,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

RouteController.defaultProps = {
  children: null,
};

export default withRouter(RouteController);
