import { WORKSPACE_ACTIONS } from './Workspaces.actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case WORKSPACE_ACTIONS.CREATE_WORKSPACE_ROLE:
      return { ...state, currentCustomRole: action.payload.data };
    case WORKSPACE_ACTIONS.GET_ORGANIZATION_SETTINGS:
      return { ...state, organizationSettings: action.payload.data };
    case WORKSPACE_ACTIONS.GET_OWNED_WORKSPACES:
      return { ...state, ownedWorkspaces: action.payload.data, ownedWorkspacesTotalPages: action.payload.meta.last_page };
    case WORKSPACE_ACTIONS.GET_ASSIGNED_WORKSPACES:
      return { ...state, assignedWorkspaces: action.payload.data, assignedWorkspacesTotalPages: action.payload.meta.last_page };
    case WORKSPACE_ACTIONS.GET_ASSIGNED_WORKSPACE_DETAILS:
      return { ...state, invitationDetails: action.payload.data };
    case WORKSPACE_ACTIONS.GET_PREDEFINED_ROLES:
      return { ...state, predefinedRoles: action.payload.data };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_ROLES:
      return { ...state, customRoles: action.payload.data };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_USERS:
      return { ...state, users: action.payload.data, userTotalPages: action.payload.meta.last_page };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_USER_ACTIVITY:
      return {
        ...state,
        userActivity: state.userActivity ? [...state.userActivity, action.payload.data] : [action.payload.data],
        nextActivity: action.payload.meta.next,
      };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_DETAIL:
      return { ...state, worksapceDetail: action.payload.data };
    case WORKSPACE_ACTIONS.RESET_WORKSPACE_USER_ACTIVITY:
      return { ...state, userActivity: [], nextActivity: undefined };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_USER_DETAILS:
      return { ...state, userDetails: action.payload.data };
    case WORKSPACE_ACTIONS.GET_PERMISSIONS_LIST:
      return { ...state, permissions: action.payload.data };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_ROLE_DETAILS:
      return { ...state, roleDetails: action.payload.data };
    case WORKSPACE_ACTIONS.RESET_ASSIGNED_WORKSPACE_DETAILS:
      return { ...state, invitationDetails: undefined };
    case WORKSPACE_ACTIONS.RESET_WORKSPACE_USER_DETAILS:
      return { ...state, userDetails: undefined };
    case WORKSPACE_ACTIONS.RESET_WORKSPACE_ROLE_DETAILS:
      return { ...state, roleDetails: undefined };
    case WORKSPACE_ACTIONS.RESET_CURRENT_WORKSPACE_ROLE:
      return { ...state, currentCustomRole: undefined };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_ALERT_NOTIFICATIONS_LIST:
      return {
        ...state,
        alertNotificationsList: action.payload.data,
        alertNotificationsListTotalPages: action.payload.meta.last_page,
      };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_NOTIFIABLE_EVENTS:
      return {
        ...state,
        notifiableEventsList: action.payload.data,
      };
    case WORKSPACE_ACTIONS.GET_ACTIVE_WORKSPACE_USERS:
      return {
        ...state,
        activeUsers: action.payload.data,
      };
    case WORKSPACE_ACTIONS.GET_WORKSPACE_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotificationSettings: action.payload.data,
      };
    case WORKSPACE_ACTIONS.CLEAR_WORKSPACE_ALERT_NOTIFICATION:
      return {
        ...state,
        alertNotificationSettings: null,
      };
    default:
      return state;
  }
};
