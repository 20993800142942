import PropTypes from 'prop-types';
import { appendClasses } from 'common/helpers';

const NotificationContent = (props) => (
  <div className={appendClasses('m-notification-content-component', props.classes)}>
    {props.children}
  </div>
);

NotificationContent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

NotificationContent.defaultProps = {
  children: null,
  classes: null,
};

export default NotificationContent;
