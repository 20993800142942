import { STATS_ACTIONS } from './Stats.actions';

export default (
  state = {
    requests: {},
    traffic: {},
    bandwidth: {},
    hitMiss: {},
    browser: {},
    country: {},
    device: {},
    os: {},
  }, action,
) => {
  switch (action.type) {
    case STATS_ACTIONS.GET_BANDWIDTH_SUMMARY:
      return {
        ...state,
        bandwidth: {
          ...state.bandwidth,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_BANDWIDTH_TOTAL:
      return {
        ...state,
        bandwidth: {
          ...state.bandwidth,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_HIT_MISS_SUMMARY:
      return {
        ...state,
        hitMiss: {
          ...state.hitMiss,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_HIT_MISS_TOTAL:
      return {
        ...state,
        hitMiss: {
          ...state.hitMiss,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_REQUEST_SUMMARY:
      return {
        ...state,
        requests: {
          ...state.requests,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_REQUEST_TOTAL:
      return {
        ...state,
        requests: {
          ...state.requests,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_TRAFFIC_SUMMARY:
      return {
        ...state,
        traffic: {
          ...state.traffic,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_TRAFFIC_TOTAL:
      return {
        ...state,
        traffic: {
          ...state.traffic,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_BROWSER_SUMMARY:
      return {
        ...state,
        browser: {
          ...state.browser,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_BROWSER_TOTAL:
      return {
        ...state,
        browser: {
          ...state.browser,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_COUNTRY_SUMMARY:
      return {
        ...state,
        country: {
          ...state.country,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_COUNTRY_TOTAL:
      return {
        ...state,
        country: {
          ...state.country,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_DEVICE_SUMMARY:
      return {
        ...state,
        device: {
          ...state.device,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_DEVICE_TOTAL:
      return {
        ...state,
        device: {
          ...state.device,
          total: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_OS_SUMMARY:
      return {
        ...state,
        os: {
          ...state.os,
          summary: action.payload.data,
        },
      };
    case STATS_ACTIONS.GET_OS_TOTAL:
    default:
      return state;
  }
};
