import { BILLING_ACTIONS } from './Billing.actions';

export default (state = null, action) => {
  switch (action.type) {
    case BILLING_ACTIONS.GET_BALANCE_INFORMATION:
      return {
        ...state,
        balanceInformation: action.payload.data,
      };
    case BILLING_ACTIONS.GET_BILLING_HISTORY:
      return {
        ...state,
        billingHistory: action.payload.data,
        totalPageNumberForBillingHistory: action.payload.meta && action.payload.meta.last_page,
      };
    case BILLING_ACTIONS.GET_BILLING_SETTINGS:
      return {
        ...state,
        billingSettings: action.payload.data,
      };
    case BILLING_ACTIONS.UPDATE_BILLING_SETTINGS:
      return {
        ...state,
        billingSettings: action.payload.data,
      };
    case BILLING_ACTIONS.GET_CREDIT_CARD_DETAILS:
      return {
        ...state,
        creditCardDetails: action.payload.data,
      };
    case BILLING_ACTIONS.GET_CREDIT_CARD_LIST:
      return {
        ...state,
        creditCardList: action.payload.data,
      };
    case BILLING_ACTIONS.GET_TAX_INFORMATION:
      return {
        ...state,
        preparedPayment: action.payload.data,
      };
    case BILLING_ACTIONS.GET_TRANSACTION_DETAILS: {
      let progress = null;
      state.billingHistory.forEach((item) => {
        if (item.id === action.payload.data.id
          && item.payment && action.payload.data.payment
          && item.payment.invoice && action.payload.data.payment.invoice) {
          progress = item.payment.invoice.job_progress === action.payload.data.payment.invoice.job_progress;
        }
        progress = true;
      });
      if (progress) {
        return {
          ...state,
          billingHistory: state.billingHistory.map((item) => {
            if (item.id === action.payload.data.id) {
              return action.payload.data;
            }
            return item;
          }),
        };
      }
      return state;
    }
    case BILLING_ACTIONS.UPDATE_TAX_INFORMATION:
      return {
        ...state,
        preparedPayment: action.payload,
      };
    case BILLING_ACTIONS.ENABLE_AUTO_PAY:
      return {
        ...state,
        autoPayEnabled: action.payload.data,
      };
    case BILLING_ACTIONS.GET_AUTO_PAY_SETTINGS:
      return {
        ...state,
        autoPaymentSettings: action.payload.data,
      };
    case BILLING_ACTIONS.GET_BILLING_SERVICES:
      return {
        ...state,
        services: action.payload.data,
      };
    case BILLING_ACTIONS.GET_BILLING_PLANS:
      return {
        ...state,
        plans: action.payload.data,
      };
    case BILLING_ACTIONS.GET_COST_EXPLORER:
      return {
        ...state,
        costExplorer: action.payload.data,
      };
    case BILLING_ACTIONS.GET_SUBSCRIBED_PLANS:
      return {
        ...state,
        subscribedPlans: action.payload.data,
      };
    case BILLING_ACTIONS.LICENSE_ACTIVATE:
    case BILLING_ACTIONS.LICENSE_REVOKE:
      return {
        ...state,
        recentlyAcquiredLicenseKey: action.payload.data.licence,
      };
    case BILLING_ACTIONS.LICENSE_CLEAR:
      return {
        ...state,
        recentlyAcquiredLicenseKey: undefined,
      };
    case BILLING_ACTIONS.SUBSCRIBE_CART_DETAIL:
      return {
        ...state,
        subscribeCartDetail: action.payload,
      };
    case BILLING_ACTIONS.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case BILLING_ACTIONS.CREATE_FUTURE_ACTIONS:
      return {
        ...state,
        futureAction: action.payload,
      };
    case BILLING_ACTIONS.FUTURE_ACTION_LIST:
      return {
        ...state,
        futureActions: action.payload.data,
      };
    case BILLING_ACTIONS.SUBSCRIPTION_LIST:
      return {
        ...state,
        subscriptions: action.payload.data,
      };
    case BILLING_ACTIONS.FETCH_INVOICE_LIST:
      return {
        ...state,
        invoices: action.payload,
      };
    default:
      return state;
  }
};
