import { AUTH_ACTIONS } from './Auth.actions';

export default (state = null, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.GET_USER_DATA:
    case AUTH_ACTIONS.LOGIN: {
      const { workspace } = action.payload.data?.recent;

      return {
        ...state,
        credentials: action.payload.data,
        credentialMeta: action.payload?.meta,
        activeSpace: {
          organization: workspace?.organization,
          workspace,
        },
      };
    }
    case AUTH_ACTIONS.CLEAR_PERMISSIONS:
      return {
        ...state,
        permissions: null,
      };
    case AUTH_ACTIONS.CLEAR_AUTH: {
      return {
        ...state,
        credentials: null,
      };
    }
    case AUTH_ACTIONS.VERIFY_CODE:
      localStorage.setItem('token', action.payload.meta.accessToken);
      localStorage.setItem('expire_date', action.payload.meta.expiresIn);
      return {
        ...state,
        credentials: action.payload.data,
      };
    case AUTH_ACTIONS.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        credentials: action.payload.data,
      };
    case AUTH_ACTIONS.UPDATE_PHONE_NO_SUCCESS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          phoneVerificationToken: action.payload.data.token,
        },
      };
    case AUTH_ACTIONS.UPDATE_ME_SUCCESS:
      return {
        ...state,
        credentials: action.payload.data,
      };
    case AUTH_ACTIONS.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        credentials: action.payload.data,
      };
    case AUTH_ACTIONS.ENABLE_TOTP:
      return { ...state, qrCode: action.payload.data.qr_code_image, secret: action.payload.data.totp_secret };
    default:
      return state;
    case AUTH_ACTIONS.SET_SUPPORT_WORKSPACE:
      return {
        ...state,
        supportWorkspaces: action.payload?.data ?? [],
      };
    case AUTH_ACTIONS.GET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          notification_settings: action.payload.data,
        },
      };
    case AUTH_ACTIONS.UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          notification_settings: action.payload.data,
        },
      };
    case AUTH_ACTIONS.UPDATE_ACTIVE_SPACE: {
      const { organization, workspace } = action.payload;

      const updatedSpace = { ...state.activeSpace } || {};

      if (organization !== undefined) {
        updatedSpace.organization = organization;
      }

      if (workspace !== undefined) {
        updatedSpace.workspace = workspace;
      }

      return {
        ...state,
        activeSpace: updatedSpace,
      };
    }
    case AUTH_ACTIONS.UPDATE_ACTIVE_SPACE_WITH_RECENT: {
      const { workspace } = state?.credentials?.recent;

      return {
        ...state,
        activeSpace: { organization: workspace.organization, workspace },
      };
    }
    case AUTH_ACTIONS.GET_TOTP_METHODS: {
      return {
        ...state,
        totpMethods: action.payload?.data,
      };
    }
    case AUTH_ACTIONS.GET_PERSONAL_ACCESS_TOKENS: {
      return {
        ...state,
        personalAccessTokens: action.payload?.data,
      };
    }
  }
};
