import { BUCKET_DASHBOARD_ACTIONS } from './BucketDashboard.actions';

export default (state = null, action) => {
  switch (action.type) {
    case BUCKET_DASHBOARD_ACTIONS.GET_NETWORK_SUMMARY_DATA:
      return {
        ...state,
        networkSummaryData: action.payload.data,
      };
    case BUCKET_DASHBOARD_ACTIONS.GET_NETWORK_BYDATE_DATA: {
      const data = action.payload.data.downloads.records.length === 0 ? {
        downloads: {
          symbol: 'B',
          records: [
            {
              date: new Date(),
              value: '0',
            },
          ],
        },
        uploads: {
          symbol: 'B',
          records: [
            {
              date: new Date(),
              value: '0',
            },
          ],
        },
      } : action.payload.data;

      return {
        ...state,
        networkByDateData: data,
      };
    }
    default:
      return state;
  }
};
