import axios from 'axios';
import {
  getStorageStatusAPI, activateStorageAPI,
  getBucketListAPI, getUsageMetricsAPI,
  getRegionsAPI, signRequestAPI, getBucketInfoAPI,
  getBucketStorageInfoAPI, getBucketTrafficInfoAPI, presignRequestAPI,
} from 'state/actions/apiFunctions';
import { addNotification } from 'common/redux/common.actions';
import { getErrorDetails, getResponseData, getErrorData } from 'state/actions/apiHelpers';
import { xmlToJson } from './Buckets.helpers';

export const BUCKET_ACTIONS = {
  GET_STORAGE_STATUS: 'GET_STORAGE_STATUS',
  ACTIVATE_STORAGE: 'ACTIVATE_STORAGE',
  GET_BUCKET_LIST: 'GET_BUCKET_LIST',
  GET_USAGE_METRICS: 'GET_USAGE_METRICS',
  GET_REGIONS: 'GET_REGIONS',
  CREATE_BUCKET: 'CREATE_BUCKET',
  SIGN_REQUEST: 'SIGN_REQUEST',
  GET_BUCKET: 'GET_BUCKET',
  CREATE_OBJECT: 'CREATE_OBJECT',
  UPLOAD_OBJECT: 'UPLOAD_OBJECT',
  GET_BUCKET_OBJECT_LIST: 'GET_BUCKET_OBJECT_LIST',
  CREATE_FOLDER: 'CREATE_FOLDER',
  GET_BUCKET_INFO: 'GET_BUCKET_INFO',
  GET_VERIONING_INFO: 'GET_VERIONING_INFO',
  GET_BUCKET_TAGGING_INFO: 'GET_BUCKET_TAGGING_INFO',
  CLEAR_UPLOAD_OBJECT_PROGRESS: 'CLEAR_UPLOAD_OBJECT_PROGRESS',
  CANCELABLE_TOKEN: 'CANCELABLE_TOKEN',
  SET_BUCKET_CONTENT_LIST: 'SET_BUCKET_CONTENT_LIST',
  GET_BUCKET_METRIC: 'GET_BUCKET_METRIC',
  GET_OBJECT_ACL: 'GET_OBJECT_ACL',
  SET_BUCKET_VISIBILITY: 'SET_BUCKET_VISIBILITY',
  GET_BUCKET_TRAFFIC_INFO: 'GET_BUCKET_TRAFFIC_INFO',
  GET_BUCKET_STORAGE_INFO: 'GET_BUCKET_STORAGE_INFO',
  GET_BUCKET_WEBSITE: 'GET_BUCKET_WEBSITE',
  CLEAR_BUCKET_STATE: 'CLEAR_BUCKET_STATE',
  GET_BUCKET_POLICY: 'GET_BUCKET_POLICY',
  GET_DELETE_DIRECTORY_OBJECT: 'GET_DELETE_DIRECTORY_OBJECT',
  CLEAR_MULTIPLE_DELETE_STATE: 'CLEAR_MULTIPLE_DELETE_STATE',
};

const signRequestSuccess = (data) => ({
  type: BUCKET_ACTIONS.SIGN_REQUEST,
  payload: data,
});

export const signRequest = (data) => (dispatch) => {
  let headers;
  if (data.headers) {
    headers = { ...data.headers };
  } else {
    headers = null;
  }
  return (
    axios.post(signRequestAPI(), {
      method: data.method,
      url: data.url,
      headers,
      payload_hash: data.payload_hash,
    }, {

    })
      .then((response) => {
        dispatch(signRequestSuccess(getResponseData(response)));
        return response.data.data;
      })
      .catch((error) => {
        throw (error);
      })
  );
};

export const presignRequest = (data) => () => (
  axios.post(presignRequestAPI(), {
    url: data.url,
    expiration: data.expiration ?? 608339879,
  }, {

  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw (error);
    })
);

const getStorageStatusSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_STORAGE_STATUS,
  payload: data,
});

export const getStorageStatus = () => (dispatch) => (
  axios.get(getStorageStatusAPI(), {

  })
    .then((response) => {
      dispatch(getStorageStatusSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getBucketTrafficInfoSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_TRAFFIC_INFO,
  payload: data,
});

export const getBucketTrafficInfo = (bucketInfo, params) => (dispatch) => (
  axios.get(getBucketTrafficInfoAPI(bucketInfo.name), {
    params: {
      gte: params.customInterval && params.customInterval[0],
      lte: params.customInterval && params.customInterval[1],
    },

  })
    .then((response) => {
      dispatch(getBucketTrafficInfoSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getBucketStorageInfoSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_STORAGE_INFO,
  payload: data,
});

export const getBucketStorageInfo = (bucketInfo, params) => (dispatch) => (
  axios.get(getBucketStorageInfoAPI(bucketInfo.name), {
    params: {
      gte: params.customInterval && params.customInterval[0],
      lte: params.customInterval && params.customInterval[1],
      date_interval: params.interval,
    },

  })
    .then((response) => {
      dispatch(getBucketStorageInfoSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getBucketInfoSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_INFO,
  payload: data,
});

export const getBucketInfo = (bucketName) => (dispatch) => (
  axios.get(getBucketInfoAPI(bucketName), {

  })
    .then((resp) => {
      dispatch(getBucketInfoSuccess(getResponseData(resp)));
    })
    .catch((error) => {
      throw (error);
    })
);

const activateStorageSuccess = (data) => ({
  type: BUCKET_ACTIONS.ACTIVATE_STORAGE,
  payload: data,
});

export const activateStorage = () => (dispatch) => (
  axios.post(activateStorageAPI(), {})
    .then((response) => {
      dispatch(activateStorageSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'activate-storage-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'activate-storage-error'));
      throw (error);
    })
);

const getBucketListSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_LIST,
  payload: data,
});

export const getBucketList = (data) => (dispatch) => (
  axios.get(getBucketListAPI(), {

    params: {
      page: data.page && data.page.currentPageNumber,
      per_page: data.page_size ?? 10,
      q: data.page && data.page.query,
      'sort_by[]': data.sortBy || 'updated_at',
      'sort_desc[]': data.sortOrder !== 2,
    },
  })
    .then((response) => {
      dispatch(getBucketListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getBucketSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET,
  payload: data,
});

export const getBucket = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': '',
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(getBucketSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getUsageMetricsSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_USAGE_METRICS,
  payload: data,
});

export const getUsageMetrics = () => (dispatch) => (
  axios.get(getUsageMetricsAPI(), {

  })
    .then((response) => {
      dispatch(getUsageMetricsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getRegionsSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_REGIONS,
  payload: data,
});

export const getRegions = () => (dispatch) => (
  axios.get(getRegionsAPI(), {

  })
    .then((response) => {
      dispatch(getRegionsSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getVersioningSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_VERIONING_INFO,
  payload: data,
});

export const getVersioning = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(getVersioningSuccess(xmlToJson(getResponseData(response))));
    })
    .catch((error) => {
      throw (error);
    })
);

const getBucketTaggingSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_TAGGING_INFO,
  payload: data,
});

export const getBucketTagging = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-acl': data.headers['x-amz-acl'],
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(getBucketTaggingSuccess(xmlToJson(getResponseData(response))));
    })
    .catch((error) => {
      throw (error);
    })
);

const getObjectAclSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_OBJECT_ACL,
  payload: data,
});

export const getObjectAcl = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-acl': data.headers['x-amz-acl'],
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      const acl = xmlToJson(getResponseData(response));
      dispatch(getObjectAclSuccess(acl));

      return acl;
    })
    .catch((error) => {
      throw (error);
    })
);

const createBucketSuccess = (data) => ({
  type: BUCKET_ACTIONS.CREATE_BUCKET,
  payload: data,
});

export const createBucket = (data) => (dispatch) => (
  axios.put(data.url, null, {
    headers: {
      'x-amz-acl': data.headers['x-amz-acl'],
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      authorization: data.headers.Authorization,
    },
  })
    .then((resp) => {
      dispatch(createBucketSuccess(getResponseData(resp)));
      dispatch(addNotification(null, 'create-bucket-success'));
    })
    .catch((eResp) => {
      const respData = xmlToJson(getErrorData(eResp).data);

      if (respData?.Error) {
        const errCode = respData.Error.Code[0];
        if (errCode === 'BucketAlreadyExists') {
          dispatch(addNotification({
            message: 'The bucket name already exists.',
          }, 'create-bucket-error'));
        }
      }
      throw (eResp);
    })
);

export const putBucketAcl = (data) => (dispatch) => (
  axios.put(data.url, null, {
    headers: {
      'x-amz-acl': data.headers['x-amz-acl'],
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'update-bucket-acl-success'));
    })
    .catch((eResp) => {
      throw (eResp);
    })
);

export const putObjectAcl = (data) => (dispatch) => (
  axios.put(data.url, null, {
    headers: {
      'x-amz-acl': data.headers['x-amz-acl'],
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'update-object-acl-success'));
    })
    .catch((eResp) => {
      throw (eResp);
    })
);

const createObjectSuccess = (data) => ({
  type: BUCKET_ACTIONS.CREATE_OBJECT,
  payload: data,
});

export const createObject = (data, formData) => (dispatch) => (
  axios.post(data.url, formData, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(createObjectSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'create-object-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-object-error'));
      throw (error);
    })
);

export const deleteObject = (data) => (dispatch) => (
  axios.delete(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-object-success'));
    })
    .catch((error) => {
      dispatch(addNotification('Could not delete the object.', 'delete-object-error'));
      throw (error);
    })
);

export const deleteMultipleObject = (data, content) => (dispatch) => (
  axios.post(data.url, content, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-multiple-object-success'));
    })
    .catch((error) => {
      dispatch(addNotification('The Objects could not be deleted.', 'delete-multiple-object-error'));
      throw (error);
    })
);

export const renameObjectAction = (data) => (dispatch) => axios.put(data.url, null, {
  headers: {
    'x-amz-date': data.headers['x-amz-date'],
    'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
    'x-amz-copy-source': data.headers['x-amz-copy-source'],
    Authorization: data.headers.Authorization,
  },
})
  .then(() => {
  })
  .catch((error) => {
    dispatch(addNotification(getErrorDetails(error), 'rename-object-error'));
    throw (error);
  });

export const deleteOldObject = (data) => (dispatch) => (
  axios.delete(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'rename-object-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'rename-object-error'));
      throw (error);
    })
);

const createFolderSuccess = (data) => ({
  type: BUCKET_ACTIONS.CREATE_FOLDER,
  payload: data,
});

export const createFolder = (data, formData) => (dispatch) => (
  axios.put(data.url, formData, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(createFolderSuccess(getResponseData(response)));
      dispatch(addNotification(null, 'create-folder-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'create-folder-error'));
      throw (error);
    })
);

const getBucketObjectListSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_OBJECT_LIST,
  payload: data,
});

export const getBucketObjectList = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(getBucketObjectListSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const getDeleteDirectoryObjectSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_DELETE_DIRECTORY_OBJECT,
  payload: data,
});

export const getDeleteDirectoryObjects = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => {
      dispatch(getDeleteDirectoryObjectSuccess(getResponseData(response)));
    })
    .catch((error) => {
      throw (error);
    })
);

const setBucketContentListPayload = (data) => ({
  type: BUCKET_ACTIONS.SET_BUCKET_CONTENT_LIST,
  payload: data,
});

export const setBucketContentList = (data) => (dispatch) => { dispatch(setBucketContentListPayload(data)); };

const clearMultipleDeleteStatePayload = (data) => ({
  type: BUCKET_ACTIONS.CLEAR_MULTIPLE_DELETE_STATE,
  payload: data,
});

export const clearMultipleDeleteState = (data) => (dispatch) => { dispatch(clearMultipleDeleteStatePayload(data)); };

const clearBucketStatePayload = () => ({
  type: BUCKET_ACTIONS.CLEAR_BUCKET_STATE,
});

export const clearBucketState = () => (dispatch) => { dispatch(clearBucketStatePayload()); };

const clearUploadObjectProgress = () => ({
  type: BUCKET_ACTIONS.CLEAR_UPLOAD_OBJECT_PROGRESS,
  payload: null,
});

export const clearProgressStatus = () => (dispatch) => { dispatch(clearUploadObjectProgress()); };

const setCancelableToken = (data) => ({
  type: BUCKET_ACTIONS.CANCELABLE_TOKEN,
  payload: data,
});

const uploadObjectSuccess = (data) => ({
  type: BUCKET_ACTIONS.UPLOAD_OBJECT,
  payload: data,
});

export const uploadObject = (data, file, index) => (dispatch) => {
  const source = axios.CancelToken.source();
  dispatch(setCancelableToken({ [index]: source }));

  return (axios.put(data.url, file, {
    onUploadProgress: (progressEvent) => {
      const event = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      dispatch(uploadObjectSuccess({ [index]: event }));
    },
    cancelToken: source.token,
    headers: {
      Authorization: data.headers.Authorization,
      'x-amz-date': data.headers['x-amz-date'],
      'content-type': file.type,
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
    },
  })
    .then(() => {
      // dispatch(uploadObjectSuccess(getResponseData(response)));
    })
    .catch((error) => error)
  );
};

export const createMultiPartUpload = (data) => () => (
  axios.post(data.url, null, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => xmlToJson(response.data))
    .catch((error) => {
      throw (error);
    })
);

export const uploaMultipartBinary = (data, formData) => () => (
  axios.put(data.url, formData, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => response)
    .catch((error) => {
      throw (error);
    })
);

export const completeMultiPartUpload = (data, formData) => () => (
  axios.post(data.url, formData, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((response) => response)
    .catch((error) => {
      throw (error);
    })
);

export const deleteBucket = (data) => (dispatch) => (
  axios.delete(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-object-success'));
    })
    .catch((eResp) => {
      const respData = xmlToJson(getErrorData(eResp).data);

      if (respData?.Error) {
        const errCode = respData.Error.Code[0];
        if (errCode === 'BucketNotEmpty') {
          dispatch(addNotification({
            message: 'The bucket must be empty.',
          }, 'delete-bucket-not-empty-error'));
          return;
        }
      }
      dispatch(addNotification(getErrorDetails(eResp), 'delete-object-error'));
      throw (eResp);
    })
);

export const setVersioning = (signData, body) => (dispatch) => (
  axios.put(signData.url, body, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'set-object-versioning-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'set-object-versioning-error'));
      throw (error);
    })
);

export const setBucketTagging = (signData, body) => (dispatch) => (
  axios.put(signData.url, body, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'set-bucket-tagging-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'set-bucket-tagging-error'));
      throw (error);
    })
);

export const deleteBucketTagging = (signData) => (dispatch) => (
  axios.delete(signData.url, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-bucket-tagging-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-bucket-tagging-error'));
      throw (error);
    })
);

const setBucketVisibilityPayload = (data) => ({
  type: BUCKET_ACTIONS.SET_BUCKET_VISIBILITY,
  payload: data,
});

export const setBucketVisibility = (data) => (dispatch) => { dispatch(setBucketVisibilityPayload(data)); };

export const putBucketWebSite = (signData, body) => (dispatch) => (
  axios.put(signData.url, body, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'set-bucket-website-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'set-bucket-website-error'));
      throw (error);
    })
);

export const deleteBucketWebSite = (signData) => (dispatch) => (
  axios.delete(signData.url, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      dispatch(addNotification(null, 'delete-bucket-website-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'delete-bucket-website-error'));
      throw (error);
    })
);

const getBucketWebsiteSuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_WEBSITE,
  payload: data,
});

export const getBucketWebsite = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((resp) => {
      dispatch(getBucketWebsiteSuccess(xmlToJson(getResponseData(resp))));
    })
    .catch(() => {
      dispatch(getBucketWebsiteSuccess([]));
    })
);

const getBucketPolicySuccess = (data) => ({
  type: BUCKET_ACTIONS.GET_BUCKET_POLICY,
  payload: data,
});

export const getBucketPolicy = (data) => (dispatch) => (
  axios.get(data.url, {
    headers: {
      'x-amz-date': data.headers['x-amz-date'],
      'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
      Authorization: data.headers.Authorization,
    },
  })
    .then((resp) => {
      dispatch(getBucketPolicySuccess(getResponseData(resp)));
    })
    .catch(() => {
      dispatch(getBucketPolicySuccess(null));
    })
);

export const deleteBucketPolicy = (signData) => () => (
  axios.delete(signData.url, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      // dispatch(addNotification(null, 'delete-bucket-tagging-success'));
    })
    .catch((error) => {
      // dispatch(addNotification(getErrorDetails(error), 'delete-bucket-tagging-error'));
      throw (error);
    })
);

export const putBucketPolicy = (signData, body) => (dispatch) => (
  axios.put(signData.url, body, {
    headers: {
      'x-amz-date': signData.headers['x-amz-date'],
      'x-amz-content-sha256': signData.headers['x-amz-content-sha256'],
      Authorization: signData.headers.Authorization,
    },
  })
    .then(() => {
      // dispatch(addNotification(null, 'set-bucket-website-success'));
    })
    .catch((error) => {
      dispatch(addNotification(getErrorDetails(error), 'set-policy-error'));
      throw (error);
    })
);
