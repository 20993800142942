import axios from 'axios';
import Qs from 'qs';
import _ from 'lodash';
import {
  addNotification,
  setClientVersion,
  addRequest,
  clearAllNotification,
  removeRequest,
} from 'common/redux/common.actions';
import { isPathIncluded, isValidHttpUrl, checkSpaceParams } from 'common/helpers';
import { NON_AUTHENTICATED_PAGES, nonSpacePages, restrictedBillingPages } from 'modules/app/AppConstants';

axios.defaults.withCredentials = true;
const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api.staging.merlincdn.com';

export default {
  setupInterceptors: (store, history) => {
    axios.interceptors.request.use((config) => {
      const updatedConfig = { ...config };
      const currentAddress = history.location.pathname.split('/');
      const controller = new AbortController();
      updatedConfig.signal = controller.signal;
      updatedConfig.controller = controller;

      const [, orgID, wsID] = currentAddress;

      if (!isValidHttpUrl(config.url)) {
        updatedConfig.url = `${API_BASE_URL}${config.url}`;
      }

      const updatedParams = { ...config.params };

      if (!isPathIncluded(config.url, nonSpacePages) && checkSpaceParams({
        orgID, wsID,
      })) {
        updatedParams.organization_id = orgID;
        updatedParams.workspace_id = wsID;
      }

      if (isPathIncluded(config.url.toString().replace(API_BASE_URL, ''), restrictedBillingPages)) {
        updatedConfig.headers['merlin-organization-id'] = orgID;
      }

      store.dispatch(addRequest({
        ...updatedConfig,
        pathname: window.location.pathname,
      }));

      return {
        ...updatedConfig,
        params: updatedParams,
        paramsSerializer: (params) => {
          const validParams = Object.keys(params).reduce((acc, key) => {
            if (params[key] !== null) acc[key] = params[key];
            return acc;
          }, {});

          return Qs.stringify(validParams, {
            arrayFormat: 'brackets',
            encode: false,
          });
        },
      };
    });
    axios.interceptors.response.use(
      (response) => {
        const { common: { clientVersion } } = store.getState();
        const headerClientVersion = response.headers?.['x-client-version'];

        if (_.isString(headerClientVersion)) {
          if (!clientVersion) {
            store.dispatch(setClientVersion(headerClientVersion));
          } else if (clientVersion !== headerClientVersion) {
            window.location.reload(true);
          }
        }

        store.dispatch(removeRequest(response.config));
        return response;
      },
      (error) => {
        if (error && error.response && error.response.status) {
          switch (error.response.status) {
            case 403: {
              const pathSegment = window.location.pathname.split('/')[3];

              if (pathSegment && pathSegment.length > 0) {
                const { notifications } = store.getState().common;

                if (notifications.length > 0) {
                  setTimeout(() => store.dispatch(clearAllNotification()), 2000);
                } else {
                  const errorMessage = error.response.data?.errors?.message;
                  if (errorMessage) {
                    store.dispatch(addNotification({ message: errorMessage }, 'generic-error'));
                  }
                }
                return Promise.reject();
              }
              break;
            }
            case 401:
              if (!isPathIncluded(window.location.href, NON_AUTHENTICATED_PAGES)
              && !isPathIncluded(window.location.href, ['ws-switcher'])
              ) {
                history.replace('/login');
              }
              break;
            case 406:
              history.replace('/');
              break;
            case 429:
              store.dispatch(addNotification({
                message: "You've created too many requests. Please wait for a while!",
              }, 'throttle-error'));
              break;
            default:
              break;
          }
        }
        store.dispatch(removeRequest(error.config));
        return Promise.reject(error);
      },
    );
  },
};
