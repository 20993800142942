import { CERTIFICATES_ACTIONS } from './Certificates.actions';

export default (state = {}, action) => {
  switch (action.type) {
    case CERTIFICATES_ACTIONS.CLEAR_CERTIFICATE_CNAMES:
      return {
        ...state,
        certificateCNAMEs: undefined,
        totalPageNumberForCertificateCNAMEs: undefined,
      };
    case CERTIFICATES_ACTIONS.GET_CERTIFICATE_CNAMES:
      return {
        ...state,
        certificateCNAMEs: action.payload.data,
        totalPageNumberForCertificateCNAMEs: action.payload.meta && action.payload.meta.last_page,
      };
    case CERTIFICATES_ACTIONS.CLEAR_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetails: undefined,
      };
    case CERTIFICATES_ACTIONS.GET_CERTIFICATE_DETAILS:
      return {
        ...state,
        certificateDetails: action.payload.data,
      };
    case CERTIFICATES_ACTIONS.GET_CERTIFICATE_LIST:
      return {
        ...state,
        certificateList: action.payload.data,
        totalPageNumberForCertificateList: action.payload
          && action.payload.meta
          && action.payload.meta.last_page,
      };
    default:
      return state;
  }
};
