const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://api-staging.merlincdn.com';

export const getResponseData = (response) => response.data;

export const getErrorData = (error) => error.response;

export const getErrorDetails = (error) => error.response && error.response.data && error.response.data.errors;

export class RequestCancelledException extends Error {
  constructor(msg, ...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    this.name = 'RequestCancelledException';
    this.message = msg;
    this.data = new Date();
  }
}

/**
 * This is the first version of makeCancelable.
 * It only returns a rejected promise on cancel.
 * It will wait until the original promise is finished to fulfill its promise.
 *
 * @param {Promise} promise Promise to have the ability to cancel
 */
export const makeCancelable = (promise) => {
  let hasCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        if (hasCanceled) {
          return reject();
        }
        return resolve(val);
      },
      () => {
        if (hasCanceled) {
          return reject();
        }
        return resolve();
      },
    );
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

/**
 * This is the second version of makeCancelable wrapper.
 * It fixes the issue where failed request would resolve.
 * This function also returns a special Exception on cancel to detect.
 * It will wait until the original promise is finished to fulfill its promise.
 *
 * @param {Promise} promise Promise to have the ability to cancel
 */

export const makeCancelable2 = (promise) => {
  let hasCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => {
        if (hasCanceled) {
          return reject(new RequestCancelledException('This request has been manually cancelled!'));
        }
        return resolve(val);
      },
      (err) => {
        if (hasCanceled) {
          return reject(new RequestCancelledException('This request has been manually cancelled!'));
        }
        return reject(err);
      },
    );
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

export const cancelCancelablePromise = (promise) => {
  if (promise && promise.cancel) {
    promise.cancel();
  }
};

export const cancelCancelablePromiseArray = (promiseArray) => {
  if (promiseArray && Array.isArray(promiseArray)) {
    promiseArray.forEach((promise) => cancelCancelablePromise(promise));
  }
};

export const prepareRequestUrl = (path) => `${API_BASE_URL}/${path}`;
